/* eslint-disable */

import { store } from '../../../store';
import { chromecast, mediaPlayStatus, chromecastAvailable, getMediaTime, chromecastConnected, videoCastPlaying, reloadVideo, mediaDuration, castedVideoEnded } from '../../../actions/index';


let END_VIDEO_DISPATCHED = false;
/**
 * Cast initialization timer delay
 * */
const CAST_API_INITIALIZATION_DELAY = 1000;
/**
 * Progress bar update timer delay
 * */
const PROGRESS_BAR_UPDATE_DELAY = 1000;
/**
 * Session idle time out in milliseconds
 * */
const SESSION_IDLE_TIMEOUT = 300000;
/**
 * Media source root URL
 * */
const MEDIA_SOURCE_ROOT =
    'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/';

// Cast icon thumbnail active
const CAST_ICON_THUMB_ACTIVE = 'images/cast_icon_active.png';
// Cast icon thumbnail idle
const CAST_ICON_THUMB_IDLE = 'images/cast_icon_idle.png';
// Cast icon thumbnail warning
const CAST_ICON_THUMB_WARNING = 'images/cast_icon_warning.png';

/**
 * global variables
 */
let selectedVideo;
let currentMediaSession = null;
let currentVolume = 0.5;
let progressFlag = 1;
let mediaCurrentTime = 0;
let session = null;
let storedSession = null;
const mediaURLs = [
  'http://commondatastorage.googleapis.com/gtv-videos-bucket/big_buck_bunny_1080p.mp4',
  'http://commondatastorage.googleapis.com/gtv-videos-bucket/ED_1280.mp4',
  'http://commondatastorage.googleapis.com/gtv-videos-bucket/tears_of_steel_1080p.mov',
  'http://commondatastorage.googleapis.com/gtv-videos-bucket/reel_2012_1280x720.mp4',
  'http://commondatastorage.googleapis.com/gtv-videos-bucket/Google%20IO%202011%2045%20Min%20Walk%20Out.mp3'];
const mediaTitles = [
  'Big Buck Bunny',
  'Elephant Dream',
  'Tears of Steel',
  'Reel 2012',
  'Google I/O 2011 Audio'];

const mediaThumbs = [
  'images/BigBuckBunny.jpg',
  'images/ElephantsDream.jpg',
  'images/TearsOfSteel.jpg',
  'images/reel.jpg',
  'images/google-io-2011.jpg'];
let currentMediaURL = mediaURLs[0];
let currentMediaTitle = mediaTitles[0];
let currentMediaThumb = mediaThumbs[0];

let timer = null;

/**
 * Call initialization
 */
window.__onGCastApiAvailable = function (isAvailable) {
  if (isAvailable) {
    initializeCastApi();
  }
};

/**
 * initialization
 */
function initializeCastApi() {
  // console.log('initializeCastApi');
  appendMessage('at initializeCastApi');
  // default app ID to the default media receiver app
  // optional: you may change it to your own app ID/receiver
  const applicationIDs = [
    chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID,
  ];


  // auto join policy can be one of the following three
  // 1) no auto join
  // 2) same appID, same URL, same tab
  // 3) same appID and same origin URL
  const autoJoinPolicyArray = [
    chrome.cast.AutoJoinPolicy.PAGE_SCOPED,
    chrome.cast.AutoJoinPolicy.TAB_AND_ORIGIN_SCOPED,
    chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
  ];

  // request session
  const sessionRequest = new chrome.cast.SessionRequest(applicationIDs[0]);
  const apiConfig = new chrome.cast.ApiConfig(
    sessionRequest,
    sessionListener,
    receiverListener,
    autoJoinPolicyArray[1],
  );

  chrome.cast.initialize(apiConfig, onInitSuccess, onError);
}

/**
 * initialization success callback
 */
function onInitSuccess() {
  // console.log('at onInitSuccess');
  appendMessage('at onInitSuccess');
  appendMessage('init success');

  // check if a session ID is saved into localStorage
  storedSession = JSON.parse(localStorage.getItem('storedSession'));
  if (storedSession) {
    const dateString = storedSession.timestamp;
    const now = new Date().getTime();

    if (now - dateString < SESSION_IDLE_TIMEOUT) {
      // document.getElementById('joinsessionbyid').style.display = 'block';
    }
  }
}

/**
 * generic error callback
 * @param {Object} e A chrome.cast.Error object.
 */
function onError(e) {
  appendMessage('at onError', e);
  appendMessage(`Error${e}`);
  appendMessage(`Error${e}`);
}

/**
 * generic success callback
 * @param {string} message from callback
 */
function onSuccess(message) {
  appendMessage('at onError', e);
  appendMessage(message);
}

/**
 * callback on success for stopping app
 */
function onStopAppSuccess() {
  appendMessage('at onStopAppSuccess');
  appendMessage('Session stopped');
  appendMessage('Session stopped');
  // document.getElementById('casticon').src = CAST_ICON_THUMB_IDLE;
}

/**
 * session listener during initialization
 * @param {Object} e session object
 * @this sessionListener
 */
function sessionListener(e) {
  // console.log('at sesssions lisenter', e);
  appendMessage('at sessionListener', e);
  appendMessage(`New session ID: ${e.sessionId}`);
  appendMessage(`New session ID:${e.sessionId}`);
  session = e;
  // document.getElementById('casticon').src = CAST_ICON_THUMB_ACTIVE;
  if (session.media.length != 0) {
    appendMessage(`Found ${session.media.length} existing media sessions.`);
    onMediaDiscovered('sessionListener', session.media[0]);
  }
  appendMessage('little further');
  session.addMediaListener(onMediaDiscovered.bind(this, 'addMediaListener'));
  session.addUpdateListener(sessionUpdateListener.bind(this));
  // disable join by session id when auto join already
  if (storedSession) {
    // document.getElementById('joinsessionbyid').style.display = 'none';
  }
}

/**
 * session update listener
 * @param {boolean} isAlive status from callback
 * @this sessionUpdateListener
 */
function sessionUpdateListener(isAlive) {
  // console.log('at sessionUpdateListener', isAlive);
  if (!isAlive) {
    session = null;
    store.dispatch(chromecastConnected(false));
    // document.getElementById('casticon').src = CAST_ICON_THUMB_IDLE;
    // var playpauseresume = document.getElementById('playpauseresume');
    // playpauseresume.innerHTML = 'Play';
    if (timer) {
      // console.log('timer does exist');
      clearInterval(timer);
    } else {
      // console.log('timer does NOT exist');
      timer = setInterval(
        updateCurrentTime.bind(this),
        PROGRESS_BAR_UPDATE_DELAY,
      );
      // playpauseresume.innerHTML = 'Pause';
    }
    // console.log('timer', timer);
  }
  store.dispatch(chromecast(isAlive));
  store.dispatch(reloadVideo(true));
}

/**
 * receiver listener during initialization
 * @param {string} e ie string from callback
 */
function receiverListener(e) {
  // console.log('at receiverListener', e);
  if (e === 'available') {
    store.dispatch(chromecastAvailable(true));
    appendMessage('receiver found');
  } else {
    store.dispatch(chromecastAvailable(false));
    appendMessage('receiver list empty');
  }
}

/**
 * select a media URL
 * @param {string} m An index for media URL
 */
function selectMedia(m) {
  // console.log('at selectMedia', m);
  appendMessage(`media selected${m}`);
  appendMessage(`media selected${m}`);
  currentMediaURL = mediaURLs[m];
  currentMediaTitle = mediaTitles[m];
  currentMediaThumb = mediaThumbs[m];
  const playpauseresume = document.getElementById('playpauseresume');
  // document.getElementById('thumb').src = MEDIA_SOURCE_ROOT + mediaThumbs[m];
}

/**
 * launch app and request session
 */
export function launchApp(video) {
  // console.log('chrome.cast', chrome.cast.mediaSession);
  // console.log('at launchApp', video);
  selectedVideo = video;
  appendMessage('video', video);
  appendMessage('at launchApp');
  appendMessage('launching app...');
  appendMessage('launching app...');
  chrome.cast.requestSession(onRequestSessionSuccess, onLaunchError);
  if (timer) {
    clearInterval(timer);
  }
}

/**
 * callback on success for requestSession call
 * @param {Object} e A non-null new session.
 * @this onRequestSesionSuccess
 */
function onRequestSessionSuccess(e) {
  // console.log('at onrequestsessions success!!!!!!!!!!!!!!!!!!!!!!!', selectedVideo);
  store.dispatch(chromecastConnected(true));
  appendMessage('at onRequestSessionSuccess', e);
  appendMessage(`session success: ${e.sessionId}`);
  appendMessage(`session success: ${e.sessionId}`);
  saveSessionID(e.sessionId);
  session = e;
  // document.getElementById('casticon').src = CAST_ICON_THUMB_ACTIVE;
  session.addUpdateListener(sessionUpdateListener.bind(this));
  if (session.media.length != 0) {
    onMediaDiscovered('onRequestSession', session.media[0]);
  }
  session.addMediaListener(onMediaDiscovered.bind(this, 'addMediaListener'));
  if (selectedVideo) {
    loadMedia(selectedVideo);
  }
}

/**
 * callback on launch error
 */
function onLaunchError(error) {
  // console.log('at onLaunchError', error);
  appendMessage('at onLaunchError', error);
  appendMessage('launch error', error);
  appendMessage('launch error');
}

/**
 * save session ID into localStorage for sharing
 * @param {string} sessionId A string for session ID
 */
function saveSessionID(sessionId) {
  appendMessage('at saveSessionID', sessionId);
  // Check browser support of localStorage
  if (typeof (Storage) !== 'undefined') {
    // Store sessionId and timestamp into an object
    const object = { id: sessionId, timestamp: new Date().getTime() };
    localStorage.setItem('storedSession', JSON.stringify(object));
  }
}

/**
 * join session by a given session ID
 */
function joinSessionBySessionId() {
  appendMessage('at joinSessionBySessionId');
  if (storedSession) {
    appendMessage(`Found stored session id: ${storedSession.id}`);
    chrome.cast.requestSessionById(storedSession.id);
  }
}

/**
 * stop app/session
 */
function stopApp() {
  // console.log('at stopApp');
  session.stop(onStopAppSuccess, onError);
  if (timer) {
    clearInterval(timer);
  }
}

/**
 * load media specified by custom URL
 */
export function loadCustomMedia(customMediaURL) {
  // var customMediaURL = document.getElementById('customMediaURL').value;
  if (Object.keys(customMediaURL).length > 0) {
    END_VIDEO_DISPATCHED = true;
    loadMedia(customMediaURL);
  }
}

/**
 * load media
 * @param {string} mediaURL media URL string
 * @this loadMedia
 */
export function loadMedia(mediaURL) {
  // console.log('STATUS', status);
  appendMessage('at loadMedia', mediaURL);
  if (!session) {
    appendMessage('no session');
    return;
  }

  if (mediaURL) {
    const mediaURI = mediaURL.hd ? mediaURL.hd : mediaURL.uri;
    var mediaInfo = new chrome.cast.media.MediaInfo(mediaURI);
    currentMediaTitle = mediaURL.name;
    currentMediaThumb = mediaURL.smallImage;
    appendMessage('media!');
    // document.getElementById('thumb').src = MEDIA_SOURCE_ROOT +
    //     currentMediaThumb;
  } else {
    appendMessage(`loading...${currentMediaURL}`);
    var mediaInfo = new chrome.cast.media.MediaInfo(currentMediaURL);
  }
  mediaInfo.metadata = new chrome.cast.media.GenericMediaMetadata();
  mediaInfo.metadata.metadataType = chrome.cast.media.MetadataType.GENERIC;
  mediaInfo.contentType = 'video/mp4';

  mediaInfo.metadata.title = currentMediaTitle;
  mediaInfo.metadata.images = [{ url: MEDIA_SOURCE_ROOT + currentMediaThumb }];

  const request = new chrome.cast.media.LoadRequest(mediaInfo);
  request.autoplay = true;
  request.currentTime = mediaURL.time;

  session.loadMedia(
    request,
    onMediaDiscovered.bind(this, 'loadMedia'),
    onMediaError,
  );
}

/**
 * callback on success for loading media
 * @param {string} how info string from callback
 * @param {Object} mediaSession media session object
 * @param {Object} mediaURL lol my thing
 * @this onMediaDiscovered
 */
function onMediaDiscovered(how, mediaSession) {
  appendMessage('at onMediaDiscoveredDDDDDDD', how);
  appendMessage('at MEDIADSICVOERED TWO', mediaSession);
  // appendMessage('new media session ID:' + mediaSession.mediaSessionId);
  // appendMessage('new media session ID:' + mediaSession.mediaSessionId +
  //     ' (' + how + ')');
  currentMediaSession = mediaSession;
  currentMediaSession.addUpdateListener(onMediaStatusUpdate);
  mediaCurrentTime = currentMediaSession.currentTime;
  store.dispatch(chromecast(true));
  store.dispatch(reloadVideo(true));
  // playpauseresume.innerHTML = 'Play';
  // document.getElementById('casticon').src = CAST_ICON_THUMB_ACTIVE;
  // document.getElementById('playerstate').innerHTML =
  //     currentMediaSession.playerState;
  if (!timer) {
    appendMessage('is timer false?');
    timer = setInterval(
      updateCurrentTime.bind(this),
      PROGRESS_BAR_UPDATE_DELAY,
    );
    // playpauseresume.innerHTML = 'Pause';
  }
}

/**
 * callback on media loading error
 * @param {Object} e A non-null media object
 */
function onMediaError(e) {
  appendMessage('at onMediaError', e);
  // appendMessage('media error');
  // appendMessage('media error');
  // document.getElementById('casticon').src = CAST_ICON_THUMB_WARNING;
}

/**
 * get media status initiated by sender when necessary
 * currentMediaSession gets updated
 * @this getMediaStatus
 */
function getMediaStatus() {
  appendMessage('at getMediaStatus');
  if (!session || !currentMediaSession) {
    return;
  }

  currentMediaSession.getStatus(
    null,
    mediaCommandSuccessCallback.bind(this, 'got media status'),
    onError,
  );
}

/**
 * callback for media status event
 * @param {boolean} isAlive status from callback
 */
function onMediaStatusUpdate(isAlive) {
  appendMessage('at onMediaStatusUpdate', isAlive);
  store.dispatch(videoCastPlaying(isAlive));
  if (!isAlive) {
    let currentMediaTime;
  } else if (currentMediaSession.playerState == 'PLAYING') {
    if (END_VIDEO_DISPATCHED) {
      END_VIDEO_DISPATCHED = false;
    }
    if (progressFlag) {
      parseInt(100 *
            currentMediaSession.currentTime /
            currentMediaSession.media.duration);
      // document.getElementById('progress_tick').innerHTML =
      //     currentMediaSession.currentTime;
      // document.getElementById('duration').innerHTML =
      //     currentMediaSession.media.duration;
      // progressFlag = 0;
    }
    store.dispatch(mediaPlayStatus('Pause'));
    // document.getElementById('playpauseresume').innerHTML = 'Pause';
  } else if (currentMediaSession.playerState == 'PAUSED') {
    store.dispatch(mediaPlayStatus('Resume'));
  }
  // document.getElementById('playerstate').innerHTML =
  //     currentMediaSession.playerState;
}

/**
 * Updates the progress bar shown for each media item.
 */
function updateCurrentTime(that, mediaURL) {
  // console.log('updatingcurrentTime');
  appendMessage('at updateCurrentTime', that);
  appendMessage('at mediaURL in updatecurrenttime', mediaURL);
  if (!session || !currentMediaSession) {
    return;
  }

  if (currentMediaSession.media && currentMediaSession.media.duration != null) {
    const cTime = currentMediaSession.getEstimatedTime();
    const status = currentMediaSession.playerState;
    const duration = currentMediaSession.media.duration;
    if (status === 'IDLE' && END_VIDEO_DISPATCHED === false) {
      store.dispatch(castedVideoEnded(true));
      END_VIDEO_DISPATCHED = true;
    }
    store.dispatch(mediaDuration(duration));
    store.dispatch(getMediaTime(cTime));
    // document.getElementById('progress').value = parseInt(100 * cTime /
    //     currentMediaSession.media.duration);
    // document.getElementById('progress_tick').innerHTML = cTime;
  } else {
    document.getElementById('progress').value = 0;
    document.getElementById('progress_tick').innerHTML = 0;
    if (timer) {
      clearInterval(timer);
    }
  }
}

/**
 * play media
 * @this playMedia
 */
export function playMedia(status) {
  appendMessage('at playMedia', status);
  if (!currentMediaSession) {
    return;
  }

  if (timer) {
    clearInterval(timer);
  }

  if (status === 'Play') {
    currentMediaSession.play(
      null,
      mediaCommandSuccessCallback.bind(this, `playing started for ${
        currentMediaSession.sessionId}`),
      onError,
    );
    store.dispatch(mediaPlayStatus('Pause'));
    appendMessage('play started');
    timer = setInterval(
      updateCurrentTime.bind(this),
      PROGRESS_BAR_UPDATE_DELAY,
    );
  } else if (status === 'Pause') {
    currentMediaSession.pause(
      null,
      mediaCommandSuccessCallback.bind(this, `paused ${
        currentMediaSession.sessionId}`),
      onError,
    );
    store.dispatch(mediaPlayStatus('Resume'));
    appendMessage('paused');
  } else if (status === 'Resume') {
    currentMediaSession.play(
      null,
      mediaCommandSuccessCallback.bind(this, `resumed ${
        currentMediaSession.sessionId}`),
      onError,
    );
    store.dispatch(mediaPlayStatus('Pause'));
    appendMessage('resumed');
    timer = setInterval(
      updateCurrentTime.bind(this),
      PROGRESS_BAR_UPDATE_DELAY,
    );
  }
}

/**
 * stop media
 * @this stopMedia
 */
export function stopMedia() {
  appendMessage('at stopMedia');
  if (!currentMediaSession) { return; }

  currentMediaSession.stop(
    null,
    mediaCommandSuccessCallback.bind(this, `stopped ${
      currentMediaSession.sessionId}`),
    onError,
  );
  // var playpauseresume = document.getElementById('playpauseresume');
  // playpauseresume.innerHTML = 'Play';
  store.dispatch(chromecast(false));
  store.dispatch(reloadVideo(true));
  appendMessage('media stopped');
  if (timer) {
    clearInterval(timer);
  }
}

/**
 * set media volume
 * @param {Number} level A number for volume level
 * @param {Boolean} mute A true/false for mute/unmute
 * @this setMediaVolume
 */
function setMediaVolume(level, mute) {
  appendMessage('at setMediaVolume', level, mute);
  if (!currentMediaSession) { return; }

  const volume = new chrome.cast.Volume();
  volume.level = level;
  currentVolume = volume.level;
  volume.muted = mute;
  const request = new chrome.cast.media.VolumeRequest();
  request.volume = volume;
  currentMediaSession.setVolume(
    request,
    mediaCommandSuccessCallback.bind(this, 'media set-volume done'),
    onError,
  );
}

/**
 * set receiver volume
 * @param {Number} level A number for volume level
 * @param {Boolean} mute A true/false for mute/unmute
 * @this setReceiverVolume
 */
export function setReceiverVolume(level, mute) {
  appendMessage('at setReceiverVolume', level, mute);
  // appendMessage('AT SET RECIEVER VOLUMNE!');
  if (!session) { return; }

  if (!mute) {
    session.setReceiverVolumeLevel(
      level,
      mediaCommandSuccessCallback.bind(this, 'media set-volume done'),
      onError,
    );
    currentVolume = level;
  } else {
    session.setReceiverMuted(
      true,
      mediaCommandSuccessCallback.bind(this, 'media set-volume done'),
      onError,
    );
  }
}

/**
 * mute media
 */
function muteMedia() {
  appendMessage('at muteMedia');
  if (!session || !currentMediaSession) {
    return;
  }

  const muteunmute = document.getElementById('muteunmute');
  // It's recommended that setReceiverVolumeLevel be used
  // but media stream volume can be set instread as shown in the
  // setMediaVolume(currentVolume, true);
  if (muteunmute.innerHTML == 'Mute media') {
    muteunmute.innerHTML = 'Unmute media';
    setReceiverVolume(currentVolume, true);
    appendMessage('media muted');
  } else {
    muteunmute.innerHTML = 'Mute media';
    setReceiverVolume(currentVolume, false);
    appendMessage('media unmuted');
  }
}

/**
 * seek media position
 * @param {Number} pos A number to indicate percent
 * @this seekMedia
 */
export function seekMedia(pos) {
  // console.log('pos', pos);
  appendMessage('at seekMedia', pos);
  appendMessage(`Seeking ${currentMediaSession.sessionId}:${
    currentMediaSession.mediaSessionId} to ${pos}%`);
  progressFlag = 0;
  const request = new chrome.cast.media.SeekRequest();
  request.currentTime = pos * currentMediaSession.media.duration / 100;
  currentMediaSession.seek(
    request,
    onSeekSuccess.bind(this, 'media seek done'),
    onError,
  );
}

/**
 * callback on success for media commands
 * @param {string} info A message string
 */
function onSeekSuccess(info) {
  appendMessage('at onSeekSuccess', info);
  appendMessage(info);
  appendMessage(info);
  setTimeout(() => { progressFlag = 1; }, PROGRESS_BAR_UPDATE_DELAY);
}

/**
 * callback on success for media commands
 * @param {string} info A message string
 */
function mediaCommandSuccessCallback(info) {
  appendMessage('at mediaCommandSuccessCallback', info);
}

/**
 * append message to debug message window
 * @param {string} message A message string
 */
function appendMessage(message) {
  // appendMessage('at appendMessage', message);
}
