import { playlistItemTypes } from '../constants';

const Guid = function Guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};


const getItemType = function getItemType(item) {
  switch (true) {
    case (item.audio !== null && item.audio.id !== null): return playlistItemTypes.audio;
    case (item.video !== null && item.video.id !== null): return playlistItemTypes.video;
    case (item.subFolder !== null): return playlistItemTypes.subFolder;
    default:
      return 'ERROR';
  }
};

const itemsIntoFolder = function itemsIntoFolder(mainFolder) {
  const folder = {
    id: Guid(),
    audio: null,
    video: null,
    subFolder: {
      assets: [],
      description: 'Media',
      id: mainFolder.id,
      largeImage: null,
      name: 'media',
      queryDB: false,
      __typename: 'Folder',
    },
    __typename: 'Asset',
  };

  const folders = [];
  mainFolder.assets.forEach(item => {
    const type = getItemType(item);
    if (type === playlistItemTypes.subFolder) {
      folders.push(item);
    } else if (type === playlistItemTypes.audio || type === playlistItemTypes.video) {
      folder.subFolder.assets.push(item);
    }
    // const type = getItemType(item);
  });
  if (folder.subFolder.assets.length > 0) {
    folders.unshift(folder);
  }
  return folders;
};

export { getItemType, itemsIntoFolder };
