import { ApolloClient } from 'apollo-client';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
};

const defaultLink = new HttpLink({ uri: '/api/graphql' });
const resetToken = onError(({ networkError }) => {
  // console.log('CHECK');
  if (networkError && networkError.statusCode === 401) {
    // remove cached token on 401 from the server
    // console.log('we should log out here', response);
  }
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('id_token');
  if (token !== undefined && token !== '') {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  }
  return {
    headers: {
      ...headers,
    },
  };
});

const authFlowLink = authLink.concat(defaultLink).concat(resetToken);
export default () => new ApolloClient({
  // By default, this client will send queries to the
  //  `/graphql` endpoint on the same host
  // Pass the configuration option { uri: YOUR_GRAPHQL_API_URL } to the `HttpLink` to connect
  // to a different host
  link: authFlowLink,
  cache: new InMemoryCache({
    dataIdFromObject: (d) => {
      if (d.id) {
        return d.id;
      }
      return (d.folder && d.folder.id) || (d.video && d.video.id) || 'nothing';
    },
  }),
  defaultOptions,
});
