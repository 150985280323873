import { store } from '../../store';
import { selectMedia, autoPlayIntermission, lastVideo } from '../../actions/index';


function determineVideoType(media) {
  if (media.video) {
    return media.video;
  }
  if (media.audio) {
    return media.audio;
  }
  return null;
}

// 11/21/18 isLastVideo should be used for if statement but is currently unreachable
function nextVideo(mainData, media) {
  // CHECKLINT
  const index = mainData.map((mediaItem) => determineVideoType(mediaItem).id).indexOf(media.id);
  let newMedia = null;
  if (mainData[index + 1]) {
    newMedia = determineVideoType(mainData[index + 1]);
  } else {
    newMedia = determineVideoType(mainData[0]);
  }
  if (newMedia) {
    store.dispatch(selectMedia(newMedia));
    store.dispatch(autoPlayIntermission(true));
    return newMedia;
  }
  store.dispatch(lastVideo(true));
  store.dispatch(autoPlayIntermission(true));
  return null;
}

export default nextVideo;
