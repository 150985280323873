import React, { Component } from 'react';
import { push } from 'react-router-redux';
import { Col, Grid } from 'react-bootstrap';
import { store } from '../../../store';
import { VideoName, Preview, CustomRow } from '../constants.js';
import { loadCustomMedia } from '../Chromecast/chromecast';
import {
  selectMedia, selectedPlaylist, chromecast, autoPlayIntermission, reloadVideo, selectedPlaylistId,
} from '../../../actions/index';


class MobileVideoItem extends Component {
  onClickDispatches() {
    const {
      subFolder, media, currentPlaylist, externalMediaPlayer, refetchId,
    } = this.props;
    if (!subFolder) {
      document.title = media.name;
      window.scrollTo(0, 0);
      store.dispatch(push(`/playlist/${currentPlaylist.id}/media/${media.id}`));
      if (!externalMediaPlayer.chromecastConnected) {
        store.dispatch(chromecast(false));
        store.dispatch(reloadVideo(true));
        store.dispatch(autoPlayIntermission(false));
      } else {
        loadCustomMedia(media);
      }
    } else {
      store.dispatch(selectedPlaylistId(media.id));
      store.dispatch(push(`/test/${media.id}?preview=true`));
      refetchId();
    }
    store.dispatch(selectedPlaylist(currentPlaylist));
    store.dispatch(selectMedia(media));
  }

  render() {
    const { media, orientation, chrome } = this.props;
    const colNumber = orientation.isMobileLandscape ? 12 : 4;
    let trimmedString = media.name.substr(0, 45);
    if (media.name.length > 65) {
      trimmedString = `${trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')))}...`;
    }
    if (window.screen.width <= 736) {
      return (
        <Grid
          fluid
          onClick={() => {
            this.onClickDispatches();
          }}
        >
          <CustomRow>
            <Col xs={6}>
              <Preview
                src={media.largeImage ? media.largeImage : media.smallImage}
                view={(chrome)}
                alt="media preview"
              />
            </Col>
            <Col xs={6}>
              <VideoName>{trimmedString}</VideoName>
            </Col>
          </CustomRow>
        </Grid>
      );
    }
    return (
      <Col
        xs={colNumber}
        onClick={() => {
          this.onClickDispatches();
        }}
      >
        <Preview
          src={media.largeImage ? media.largeImage : media.smallImage}
          view={(chrome)}
          alt="Video preview"
        />
        <VideoName>{trimmedString}</VideoName>
      </Col>
    );
  }
}

export default MobileVideoItem;
