export const MEDIA_SELECTED = 'MEDIA_SELECTED';
export const HIGHLIGHT_VIDEO = 'HIGHLIGHT_VIDEO';
export const HIGHLIGHT_ROW = 'HIGHLIGHT_ROW';
export const PLAYLIST_INDEX = 'PLAYLIST_INDEX';
export const CAROUSEL_POSITION = 'CAROUSEL_POSITION';
export const MOBILE_DROPDOWN = 'MOBILE_DROPDOWN';
export const THEME = 'THEME';
export const SCREEN_ORIENTATION = 'SCREEN_ORIENTATION';
export const CHROMECAST_AVAILABLE = 'CHROMECAST_AVAILABLE';
export const CHROMECAST_CONNECTED = 'CHROMECAST_CONNECTED';
export const CHROMECAST = 'CHROMECAST';
export const PLAY_STATUS = 'PLAY_STATUS';
export const MEDIA_PLAY_TIME = 'MEDIA_PLAY_TIME';
export const AUTO_PLAY_INTERMISSION = 'AUTO_PLAY_INTERMISSION';
export const COLLAPSE_DESCRIPTION = 'COLLAPSE_DESCRIPTION';
export const VIDEO_CAST_PLAYING = 'VIDEO_CAST_PLAYING';
export const CHROMECAST_PAUSED = 'CHROMECAST_PAUSED';
export const RELOAD_VIDEO = 'RELOAD_VIDEO';
export const SLIDER_UPDATE = 'SLIDER_UPDATE';
export const MOBILE_WIDTH = 'MOBILE_WIDTH';
export const VIDEO_ORDER = 'VIDEO_ORDER';
export const PREVENT_CHANGE = 'PREVENT_CHANGE';
export const ROW_LENGTHS = 'ROW_LENGTHS';
export const ROW_NUMBER = 'ROW_NUMBER';
export const COL_POSITION = 'COL_POSITION';
export const FAST_FORWARD = 'FAST_FORWARD';
export const REWIND = 'REWIND';
export const VOLUME_UP = 'VOLUME_UP';
export const VOLUME_DOWN = 'VOLUME_DOWN';
export const TOGGLE_PLAY = 'TOGGLE_PLAY';
export const VIDEO_PLAYING = 'VIDEO_PLAYING';
export const LOGO_COL_NUMBER = 'LOGO_COL_NUMBER';
export const SCREEN_OVERLAY = 'SCREEN_OVERLAY';
export const LOGIN_SELECTED = 'LOGIN_SELECTED';
export const LAST_VIDEO = 'LAST_VIDEO';
export const JUST_LOGGED_IN = 'JUST_LOGGED_IN';
export const SITE_PREVIEW_URL = 'SITE_PREVIEW_URL';
export const USER_ID = 'USER_ID';
export const MEDIA_DURATION = 'MEDIA_DURATION';
export const MEDIA_BACKGROUND_UPDATED = 'MEDIA_BACKGROUND_UPDATED';
export const SELECTED_PLAYLIST_ID = 'SELECTED_PLAYLIST_ID';
export const MAIN_DATA = 'MAIN_DATA';
export const CASTED_VIDEO_ENDED = 'CASTED_VIDEO_ENDED';
export const SHOW_SEARCH_ITEMS = 'SHOW_SEARCH_ITEMS';
export const UPDATE_SEARCH = 'UPDATE_SEARCH';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';
export const RESET_SEARCH = 'RESET_SEARCH';
