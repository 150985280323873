import React from 'react';
import HttpsRedirect from 'react-https-redirect';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ApolloProvider } from 'react-apollo';
import { ConnectedRouter } from 'react-router-redux';
import createClient from './lib/client';
import { store, history } from './store';
import { CheckLogin } from './components/auth/auth';
import Browser from './containers/browser';
import ThemeWrapper from './lib/themeWrapper';
import css from '../css/app.css'; //eslint-disable-line

CheckLogin();

render(
  <HttpsRedirect>
    <Provider store={store}>
      <ApolloProvider client={createClient()}>
        <ThemeWrapper>
          <ConnectedRouter history={history}>
            <Browser />
          </ConnectedRouter>
        </ThemeWrapper>
      </ApolloProvider>
    </Provider>
  </HttpsRedirect>,
  document.getElementById('root'),
);
