import React from 'react';
import Decoration from '../../decoration';
import BrowserPlaylist from './browserPlaylist';
import { playlistItemTypes } from '../constants';
import { getItemType, itemsIntoFolder } from './helpers';
import BrowserSinglePlaylistItem from './browserSinglePlaylistItem';
import MobileSinglePlaylistItem from './mobileSinglePlaylistItem';
import MobilePlaylist from './mobilePlaylist';
import SearchSingleItem from '../Search/searchSingleItem';

const ConditionalDecor = (props) => {
  const { orientation } = props;
  if (window.screen.width <= 768 || !orientation.device) {
    return <Decoration decor={props.decor} />;
  }
  return <div />;
};

function getCorrectListComponentMobile(props) {
  return function mapProcessor(item, index) {
    const type = getItemType(item);
    if (type === playlistItemTypes.subFolder) {
      return (
        <MobilePlaylist
          name={item.subFolder.name}
          items={item.subFolder.assets}
          refetchId={props.refetchId}
          key={`${item.subFolder.id}${index}`}
          playlistTracker={props.playlistTracker}
          currentPlaylist={item.subFolder}
          highlightedVideo={props.highlightedVideo}
          rowNumber={index}
          orientation={props.orientation}
          chromecastConnected={props.chromecastConnected}
        />
      );
    }
    if (type === playlistItemTypes.audio) {
      return (
        <MobileSinglePlaylistItem
          key={`${item.audio.id}${index}`}
          media={item.audio}
          currentPlaylist={props.mainData}
          playlistTracker={props.playlistTracker}
          previewImageUrl={item.audio.smallImage}
          name={item.audio.name}
          orientation={props.orientation}
          chromecastConnected={props.chromecastConnected}
        />
      );
    }
    if (type === playlistItemTypes.video) {
      return (
        <MobileSinglePlaylistItem
          key={`${item.video.id}${index}`}
          media={item.video}
          currentPlaylist={props.mainData}
          playlistTracker={props.playlistTracker}
          previewImageUrl={item.video.largeImage}
          name={item.video.name}
          orientation={props.orientation}
          chromecastConnected={props.chromecastConnected}
        />
      );
    }
    return null;
  };
}

function getCorrectListComponentSearch(props) {
  return function mapProcessor(item, index) {
    const type = getItemType(item);
    if (type === playlistItemTypes.Folder) {
      return (
        <SearchSingleItem
          name={item.folder.name}
          media={item.folder}
          refetchId={props.refetchId}
          key={`${item.folder.id}${index}`}
          playlistTracker={props.playlistTracker}
          currentPlaylist={item.folder}
          highlightedVideo={props.highlightedVideo}
          rowNumber={index}
          orientation={props.orientation}
          chromecastConnected={props.externalMediaPlayer.chromecastConnected}
        />
      );
    }
    if (type === playlistItemTypes.audio) {
      return (
        <SearchSingleItem
          key={`${item.audio.id}${index}`}
          media={item.audio}
          currentPlaylist={props.mainData}
          playlistTracker={props.playlistTracker}
          previewImageUrl={item.audio.smallImage}
          name={item.audio.name}
          orientation={props.orientation}
          chromecastConnected={props.externalMediaPlayer.chromecastConnected}
        />
      );
    }
    if (type === playlistItemTypes.video) {
      return (
        <SearchSingleItem
          key={`${item.video.id}${index}`}
          media={item.video}
          currentPlaylist={props.mainData}
          playlistTracker={props.playlistTracker}
          previewImageUrl={item.video.largeImage}
          name={item.video.name}
          orientation={props.orientation}
          chromecastConnected={props.externalMediaPlayer.chromecastConnected}
        />
      );
    }
    return (
      <div
        key="NothingHere"
      />
    );
  };
}

function getCorrectListComponent(props) {
  return function mapProcessor(item, index) {
    const type = getItemType(item);
    if (type === playlistItemTypes.subFolder) {
      return (
        <BrowserPlaylist
          name={item.subFolder.name}
          items={item.subFolder.assets}
          refetchId={props.refetchId}
          key={`${item.subFolder.id}${index}`}
          currentPlaylist={item.subFolder}
          playlistTracker={props.playlistTracker}
          highlightedVideo={props.highlightedVideo}
          rowNumber={index}
          orientation={props.orientation}
          chromecastConnected={props.chromecastConnected}
        />
      );
    }
    if (type === playlistItemTypes.audio) {
      return (
        <BrowserSinglePlaylistItem
          key={`${item.audio.id}${index}`}
          media={item.audio}
          currentPlaylist={props.mainData}
          playlistTracker={props.playlistTracker}
          previewImageUrl={item.audio.smallImage}
          name={item.audio.name}
          chromecastConnected={props.chromecastConnected}
        />
      );
    }
    if (type === playlistItemTypes.video) {
      return (
        <BrowserSinglePlaylistItem
          key={`${item.video.id}${index}`}
          media={item.video}
          currentPlaylist={props.mainData}
          playlistTracker={props.playlistTracker}
          previewImageUrl={item.video.largeImage}
          name={item.video.name}
          chromecastConnected={props.chromecastConnected}
        />
      );
    }
    return null;
  };
}


const CarouselWrapper = (props) => {
  const {
    search, decor, orientation, mainData,
    selectedPlaylist, refetchId, chromecastConnected, urlPath,
  } = props;

  const parentFolder = itemsIntoFolder(mainData);
  if (search) {
    return (
      <div>
        {
          mainData
            .map(getCorrectListComponentSearch(props))
        }
      </div>
    );
  }
  return (
    <div>
      <ConditionalDecor decor={decor} orientation={orientation} />
      {(() => {
        if (urlPath.startsWith('/playlist/s')) {
          return <div />;
        }
        if (urlPath.startsWith('/playlist/')) {
          if (selectedPlaylist === undefined) {
            return <div />;
          }
          if (orientation.device) {
            return (
              <MobilePlaylist
                name={mainData.name}
                items={mainData.assets}
                playlistId={mainData.id}
                refetchId={refetchId}
                currentPlaylist={mainData}
                orientation={orientation}
                chromecastConnected={chromecastConnected}
              />
            );
          }
          return (
            <BrowserPlaylist
              name={mainData.name}
              items={mainData.assets}
              refetchId={refetchId}
              playlistId={mainData.id}
              currentPlaylist={mainData}
              orientation={orientation}
              chromecastConnected={chromecastConnected}
            />
          );
        }
        if (orientation.device) {
          return parentFolder
            .map(getCorrectListComponentMobile(props));
        }
        return parentFolder
          .map(getCorrectListComponent(props));
      })()}
    </div>
  );
};


export default CarouselWrapper;
