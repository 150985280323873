import React, { Component } from 'react';
import styled from 'styled-components';
import TVAppCarousel from './tvAppCarousel';

const CarouselWrapper = styled.div`
  position:absolute;
  width: 100%;
  height: 100%;
  transform: translate3d(0px, ${props => props.absoluteHeight}px, 0px); 
  ${props => props.toggleTransition};
  padding-right: 7.8%;
  padding-left: 7.8%;
  margin-right: auto;
  margin-left: auto; 
  & * {
    -webkit-transition: all 150ms linear; /* Safari and Chrome */
    -moz-transition: all 150ms linear; /* Firefox */set
    -ms-transition: all 150ms linear; /* IE 9 */
    -o-transition: all 150ms linear; /* Opera */
    transition: all 150ms linear;
  }
`;

const Title = styled.h5`
  color: white;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  font-size: 20px;
  padding-left: 15px;
  margin-bottom: ${props => props.marginBottom}px;
`;

const LoginInfo = {
  subFolder: {
    updated: '2017-10-03T02:00:31.783Z',
    order: -0.5,
    name: 'Navigation',
    autoId: '34asgerEFWE4gD',
    id: 'LoginSearchoiwfj904',
    subFolder: {
      assets: [{
        menu: {
          navType: 'login',
          name: 'Login',
          id: 'Login3994028jirhslk34',
          type: 'Login',
        },
      }, {
        menu: {
          navType: 'search',
          name: 'Search',
          id: 'SearchlsjdSDIOFJ894jl',
          type: 'Search',
        },
      }],
      name: 'Menu',
    },
  },
};

const transition = `
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  -o-transition: all 150ms linear;
`;

const determineTitle = (item) => {
  if (item.audio) {
    return item.audio.name;
  }
  if (item.video) {
    return item.video.name;
  }
  if (item.subFolder) {
    return item.subFolder.name;
  }
  return null;
};

class TVKeyboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      rowPosition: [-190, 0, 230, 430, 630, 630, 630],
      direction: '',
    };
    this.moveRows = this.moveRows.bind(this);
    this.hotkeyHandler = this.handleHotkey.bind(this);
  }

  // shouldComponentUpdate(nextProps, nextState){
  //   return this.state.rows != nextState.rows;
  // }


  componentWillMount() {
    const { mainData } = this.props;
    const rows = mainData.slice();
    const rowPosition = this.adjustRowPosition(rows);
    rows.unshift(LoginInfo.subFolder);
    this.setState({
      rows,
      rowPosition,
    });
  }

  componentDidMount() {
    const { mainData, setBackground } = this.props;
    if (mainData) {
      const media = mainData[0];
      setBackground(media);
    }
    document.addEventListener('keydown', this.hotkeyHandler);
  }

  // Detatch the keyboard listener
  componentWillUnmount() {
    document.removeEventListener('keydown', this.hotkeyHandler);
  }

  adjustRowPosition(rows) {
    const { rowPosition } = this.state;
    if (rows.length >= rowPosition.length) {
      while (rows.length > rowPosition.length - 1) {
        rowPosition.push(630);
      }
    } else if (rows.length < rowPosition.length) {
      while (rows.length < rowPosition.length - 1) {
        rowPosition.pop();
      }
    }
    return rowPosition;
  }

  handleHotkey(event) {
    const { continuousKeyPressed } = this.state;
    // 8 = back
    // 179 = play
    // forward = 228
    // back = 227
    // handles user holding down arrowkeys
    if (event.repeat) {
      if (!continuousKeyPressed) {
        this.handleSpecificKey(event);
        this.setState({
          continuousKeyPressed: true,
        });
        setTimeout(() => {
          this.setState({
            continuousKeyPressed: false,
          });
        }, 150);
      }
    } else {
      this.handleSpecificKey(event);
    }
  }

  handleSpecificKey(event) {
    switch (event.keyCode) {
      case 38:
        return this.upKey.call(this, event);
      case 40:
        return this.downKey.call(this, event);
      default:
        return null;
    }
  }

  upKey() {
    this.moveRows('up');
  }

  downKey() {
    this.moveRows('down');
  }

  moveRows(direction) {
    let { rowPosition } = this.state;
    rowPosition = rowPosition.slice();
    if (direction === 'up') {
      rowPosition.push(rowPosition.shift());
    } else if (direction === 'down') {
      rowPosition.unshift(rowPosition.pop());
    }
    this.setState({
      rowPosition,
      direction,
    });
  }


  render() {
    const { direction, rows, rowPosition } = this.state;
    const {
      keyboard, mainData, setBackground, selectedPlaylist,
      decor, urlPath, highlightedVideo, orientation, location,
    } = this.props;
    return (
      <div>
        {
          rows
            .map((mainDataPosition, index) => {
              const yPosition = rowPosition[index];
              const toggleTransition = (yPosition > -190 && direction === 'up') || (yPosition < 430 && direction === 'down');
              // let absoluteHeight = (this.props.updatedRowHeights[index] === undefined) ?
              // this.props.updatedRowHeights[this.props.updatedRowHeights.length-1] :
              // this.props.updatedRowHeights[index]
              return (
                <div key={mainDataPosition.id}>
                  <CarouselWrapper absoluteHeight={yPosition} toggleTransition={toggleTransition ? transition : ''} className="CarouselWrapper">
                    <Title
                      marginBottom={(rowPosition[index] === 0) ? 10 : -10}
                    >
                      {determineTitle(mainDataPosition)}
                    </Title>
                    <TVAppCarousel
                      colPosition={keyboard.colPositions[index]}
                      index={index}
                      moveRows={this.moveRows}
                      absoluteHeight={yPosition}
                      items={mainDataPosition}
                      mainData={mainData}
                      setBackground={setBackground}
                      keyboard={keyboard}
                      selectedPlaylist={selectedPlaylist}
                      decor={decor}
                      urlPath={urlPath}
                      highlightedVideo={highlightedVideo}
                      orientation={orientation}
                      location={location}
                    />
                  </CarouselWrapper>
                </div>
              );
            })
        }
      </div>
    );
  }
}

export default TVKeyboard;


// THE FOLLOWING CODE IS FOR INFINITE SCROLL
// THE ANIMATION ISN'T SMOOTH

// import React,{ Component } from 'react';
// import TVAppCarousel from './tvAppCarousel';
// import styled from 'styled-components';

// const CarouselWrapper = styled.div`
//   position:absolute;
//   width: 100%;
//   height: 100%;
//   transform: translate3d(0px, ${props => props.absoluteHeight}px, 0px);
//   padding-right: 7.8%;
//   padding-left: 7.8%;
//   margin-right: auto;
//   margin-left: auto;
// `

// const Title = styled.h5`
//   color: white;
//   font-family: 'Lato', sans-serif;
//   font-weight: 500;
//   font-size: 20px;
//   padding-left: 15px;
//   margin-bottom: -${props => props.marginBottom}px;
// `

// const LoginInfo =
//   {
//     "subFolder": {
//       "updated": "2017-10-03T02:00:31.783Z",
//       "order": -0.5,
//       "name": "Navigation",
//       "autoId": "34asgerEFWE4gD",
//       "id": "LoginSearchoiwfj904",
//       "subFolder": {
//         "assets": [{
//           "menu": {
//             "navType": "login",
//             "name": "Login",
//             "id": "Login3994028jirhslk34",
//             "type": "Login",
//           }}, {
//           "menu": {
//             "navType": "search",
//             "name": "Search",
//             "id": "SearchlsjdSDIOFJ894jl",
//             "type": "Search",
//           }
//         }],
//         "name": "Menu"
//       },
//     }
//   }

//   const determineType = (item) => {
//     if(item.audio){
//       return item.audio;
//     } else if (item.video){
//       return item.video;
//     } else if (item.subFolder){
//       return item.subFolder
//     }
//   }

//   const determineTitle = (item) => {
//     if(item.audio){
//       return item.audio.name;
//     } else if (item.video){
//       return item.video.name;
//     } else if (item.subFolder){
//       return item.subFolder.name
//     }
//   }

// class TVKeyboard extends Component {
//   constructor(props){
//     super(props)
//     this.state = {
//       rows: [],
//       rowNumbers: []
//     }
//     this.moveRows = this.moveRows.bind(this)
//     this.hotkeyHandler = this.handleHotkey.bind(this)
//   }

//   shouldComponentUpdate(nextProps, nextState){
//     return this.state.rows != nextState.rows;
//   }

//   componentWillMount(){
//     let rows = this.props.mainData.slice()
//     rows.unshift(LoginInfo.subFolder)
//     let rowNumbers = []
//     for (let i = 0; i < rows.length; i++) {
//       rowNumbers.push(i)
//     }
//     this.setState({
//       rows,
//       rowNumbers
//     })
//   }

//   componentDidMount() {
//     if(this.props.mainData){
//       let media = this.props.mainData[0]
//       this.props.setBackground(media);
//     }
//     document.addEventListener('keydown', this.hotkeyHandler);
//   }

//   // Detatch the keyboard listener
//   componentWillUnmount() {
//     document.removeEventListener('keydown', this.hotkeyHandler);
//   }

//   handleHotkey(event) {
//     //8 = back
//     //179 = play
//     //forward = 228
//     //back = 227

//     //handles user holding down arrowkeys
//     if(event.repeat){
//       if (!this.state.continuousKeyPressed) {
//         this.handleSpecificKey(event)
//           this.setState({
//             continuousKeyPressed: true,
//           })
//           setTimeout(() => {
//             this.setState({
//               continuousKeyPressed: false,
//             })
//           }, 150)
//           } else {
//       }
//     } else {
//       this.handleSpecificKey(event)
//     }
//   }

//   handleSpecificKey(event){
//     this.setState({
//       keypressed: event.keyCode
//     })
//     let whatever = Object.keys(event);
//     switch (event.keyCode) {
//       case 38:
//         return this.upKey.call(this, event);
//       case 40:
//         return this.downKey.call(this, event);
//     }
//   }

//   upKey(event){
//     this.moveRows('up')
//   }

//   downKey(event){
//     this.moveRows('down')
//   }

//   moveRows(direction) {
//     let rows = this.state.rows.slice()
//     if(direction === 'up') {
//       rows.unshift(rows.pop())
//     } else if (direction === 'down') {
//       rows.push(rows.shift())
//     }
//     this.setState({
//       rows
//     })
//   }


//   render(){
//     return (
//       <div>
//         {
//           /*
//             short order is used in place of mainData because not all mainData
//             is sent in every render.  Also, mainData is not always sent in starting
//             on index zero, so an array of index positions is used
//           */
//           this.state.rows
//           .map((mainDataPosition, index) => {
//             let absoluteHeight = (this.props.updatedRowHeights[index] === undefined) ?
//                this.props.updatedRowHeights[this.props.updatedRowHeights.length-1] :
//                this.props.updatedRowHeights[index]
//               return (
//                 <div key={mainDataPosition.id}>
//                   <CarouselWrapper absoluteHeight={absoluteHeight}>
//                   <Title
//                   marginBottom = {(this.props.updatedRowHeights[index] === 0) ? 'auto' : 15}
//                   >{determineTitle(mainDataPosition)}</Title>
//                   <TVAppCarousel
//                     colPosition={this.props.keyboard.colPositions[index]}
//                     index={index}
//                     moveRows={this.moveRows}
//                     absoluteHeight={absoluteHeight}
//                     items={mainDataPosition}
//                     mainData={this.props.mainData}
//                     setBackground={this.props.setBackground}
//                     keyboard={this.props.keyboard}
//                     selectedPlaylist={this.props.selectedPlaylist}
//                     decor={this.props.decor}
//                     urlPath={this.props.urlPath}
//                     highlightedVideo={this.props.highlightedVideo}
//                     orientation={this.props.orientation}
//                     location={this.props.location}
//                   />
//                 </CarouselWrapper>
//               </div>
//               )
//           })
//         }
//       </div>
//     )
//   }
// }

// export default TVKeyboard;
