import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'react-router-redux';
import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import { selectMedia, selectedPlaylist, autoPlayIntermission } from '../../../actions/index';
import { store } from '../../../store';
import Description from '../../description';
import Decoration from '../../decoration';


const VideoRow = styled(Row)`
  padding-right: ${props => props.orientation}%;
  padding-left: ${props => props.orientation}%;
  margin-right: auto;
  margin-left: auto;
  background-color: rgb(0,0,0);
  @media (max-width: 1024px) {
    padding: 0;
  }
`;


const Image = styled.img`
  width:100%;
`;

const PlayButton = styled.img`
  position: absolute;
  width: 90px;
  height: auto;
  margin: auto auto auto auto;
  cursor: pointer;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0,0,0);
`;

const Gradient = styled.div`
  position: absolute;
  background-image: url("/images/blackGradient.png");
  background-size: cover;
  margin-left: 0;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  opacity: 1;
`;


const VideoPreview = (props) => {
  const {
    media, orientation, singleItem, playlist, decor, descriptionShown,
  } = props;
  return (
    <div>
      <VideoRow orientation={(orientation.isMobileLandscape) ? '0' : '25'}>
        <Wrapper className="wrapper">
          <Image
            src={media.largeImage}
            alt="Video Preview"
          />
          <Gradient className="CLASS GRADIENTWHATEVER" />
          <PlayButton
            onClick={() => {
              if (singleItem) {
                store.dispatch(push(`/playlist/${playlist.id}/media/${media.id}`));
              } else {
                store.dispatch(push(`/playlist/${playlist.id}/media/${media.id}`));
                store.dispatch(selectedPlaylist(playlist));
              }
              store.dispatch(selectMedia(media));
              store.dispatch(autoPlayIntermission(false));
              window.scrollTo(0, 0);
            }}
            src="/images/play.png"
          />
        </Wrapper>
      </VideoRow>
      {
        orientation.device && (
          <div>
            <Decoration decor={decor} />
            <Description
              media={media}
              orientation={orientation}
              descriptionShown={descriptionShown}
            />
          </div>
        )
      }
    </div>
  );
};

export default withRouter(connect()(VideoPreview));

// background: -webkit-linear-gradient(bottom, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 100%);
// background: linear-gradient(to top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 100%);
