const initialState = {
  visible: true,
  row: 0,
  collapse: 'auto',
  duration: 500,
  change: true,
  xAxis: 0,
  yAxis: 1,
  rowPosition: 0,
  rowHeights: [-420, -190, 0, 230, 430, 630],
  overlay: false,
  loginSelected: true,
  backgroundUpdated: false,
};


const keyboardNavigation = (state = initialState, action) => {
  switch (action.type) {
    case 'VIDEO_ORDER':
      return {
        ...state,
        order: action.payload,
      };
    case 'PREVENT_CHANGE':
      return {
        ...state,
        change: action.payload,
      };
    case 'ROW_LENGTHS':
      return {
        ...state,
        rowLength: action.payload,
      };
    case 'ROW_NUMBER':
      return {
        ...state,
        yAxis: action.payload,
      };
    case 'COL_POSITION':
      return {
        ...state,
        colPositions: action.payload,
      };
    case 'SCREEN_OVERLAY':
      return {
        ...state,
        overlay: action.payload,
      };
    case 'LOGIN_SELECTED':
      return {
        ...state,
        loginSelected: action.payload,
      };
    case 'MEDIA_BACKGROUND_UPDATED':
      return {
        ...state,
        backgroundUpdated: action.payload,
      };
    default:
      return state;
  }
};

export default keyboardNavigation;
