import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { store } from '../../../store';
import { chromeCastPaused, sliderUpdate, castedVideoEnded } from '../../../actions/index';
import { playMedia, seekMedia, launchApp } from './chromecast';
import nextVideo from '../../util/dispatchNextVideo';

const VideoRow = styled.div`
  padding-right: ${props => props.orientation}%;
  padding-left: ${props => props.orientation}%;
  margin-right: auto;
  margin-left: auto;
  background-color: rgb(0,0,0);
`;

const Image = styled.img`
width:100%;
filter: brightness(25%)
`;

const PlayButton = styled(FontAwesome)`
  color: white;
  top: 28%;
  font-size: 100px;
  position: absolute;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 85px;
  }
  @media (max-width: 414px) {
    font-size: 41px;
  }
`;

const SliderWrapper = styled.div`
  position:absolute;
  bottom: 23%;
  width:63%;
`;


const TimeTracker = styled.div`
  position: absolute;
  top: 52%;
  color: white;
  font-size: 58px;
    @media (max-width: 768px) {
    font-size: 50px;
  }
  @media (max-width: 414px) {
    font-size: 30px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0,0,0);
`;

const CastButton = styled.img`
  position: absolute;
  right: 23%;
  top: 27%;
  cursor: pointer;
`;

// const Gradient = styled.div `
// position: absolute;
// background-image: url("/images/blackGradient.png");
// background-size: cover;
// margin-left: 0;
// bottom: 0;
// left: 0;
// right: 0;
// top: 0;
// opacity: 1;
// `

function castClick(player, video) {
  launchApp(video);
}

function playButtonAction(media) {
  store.dispatch(chromeCastPaused(!media.paused));
  playMedia(media.status);
}

function changeBarValueTrue(time) {
  store.dispatch(sliderUpdate(true));
  seekMedia(time);
}

function changeBarValueFalse() {
  store.dispatch(sliderUpdate(false));
}

class VideoCasted extends Component {
  componentDidMount() {
    // play media needs to be called to keep time from rarely getting stuck while switcing
    // between media
    playMedia('Resume');
  }

  componentWillReceiveProps() {
    const { externalMediaPlayer } = this.props;
    if (externalMediaPlayer.castedVideoEnded && externalMediaPlayer.changeOneVideo) {
      const {
        mainData, media, selectedPlaylist, lastVideo,
      } = this.props;
      nextVideo(mainData, media, selectedPlaylist, lastVideo);
      store.dispatch(castedVideoEnded(false));
    }
  }

  render() {
    const {
      castedTimeListeners, externalMediaPlayer, orientation, image, currentVideo,
    } = this.props;
    let sliderNumValue;
    const videoLength = castedTimeListeners.mediaDuration;
    if (externalMediaPlayer.update) {
      sliderNumValue = (castedTimeListeners.currentPlayTime / videoLength) * 100;
    }
    const maxMinutes = Math.floor((videoLength % 3600) / 60);
    const maxSeconds = Math.floor((videoLength % 3600 % 60));
    const minutes = Math.floor((castedTimeListeners.currentPlayTime % 3600) / 60);
    const seconds = Math.floor((castedTimeListeners.currentPlayTime % 3600 % 60));
    return (
      <VideoRow
        orientation={(orientation.device) ? '0' : '25'}
      >
        <Wrapper className="wrapper">
          <Image src={image} alt="Video Preview" />
          {/* <Gradient className="CLASS GRADIENTWHATEVER"></Gradient> */}
          <PlayButton size="3x" name={(externalMediaPlayer.status === 'Pause') ? 'pause' : 'play'} onClick={() => { playButtonAction(externalMediaPlayer); }} />
          {
            (Number.isNaN(maxMinutes) === false) && (Number.isNaN(maxSeconds) === false) && (
              <TimeTracker>
                {minutes}
                :
                {seconds > 9 ? seconds : `0${seconds}`}
                /
                {maxMinutes}
                :
                {maxSeconds > 9 ? maxSeconds : `0${maxSeconds}`}
              </TimeTracker>
            )
          }
          <SliderWrapper>
            <input
              className="vertical"
              type="range"
              min="0"
              max="100"
              step="1"
              value={sliderNumValue}
              onMouseDown={() => changeBarValueFalse()}
              onMouseUp={e => changeBarValueTrue(e.target.value)}
              onChange={e => (e.preventDefault())}
            />
          </SliderWrapper>
          <CastButton
            src="/images/cast_icon_active.png"
            onClick={() => { castClick(externalMediaPlayer, currentVideo); }}
          />
        </Wrapper>
      </VideoRow>
    );
  }
}

function mapStateToProps(state) {
  return {
    castedTimeListeners: state.castedTimeListeners,
  };
}


// {maxMinutes}:{9<maxSeconds?maxSeconds:'0'+maxSeconds}

export default connect(mapStateToProps)(VideoCasted);
