import React from 'react';
import { Mutation } from 'react-apollo';
import mutation from './remove.graphql';

const Add = ({ removeFromMyList, assetId }) => (
  <div
    tabIndex={0}
    role="button"
    onKeyDown={() => removeFromMyList({
      variables: { assetId },
      refetchQueries: ['getFolder'],
    })}
    onClick={() => removeFromMyList({
      variables: { assetId },
      refetchQueries: ['getFolder'],
    })}
    style={{
      height: 50,
      width: 50,
      textAlign: 'center',
      color: '#FFFFFF',
      fontSize: 32,
      fontWeight: 'bold',
    }}
  >
    -
  </div>
);
// eslint-disable-next-line
const WrappedMutation = (props) => (
  <Mutation mutation={mutation}>
    { removeFromMyList => (
      <Add removeFromMyList={removeFromMyList} {...props} />
    )}
  </Mutation>
);


export default WrappedMutation;
