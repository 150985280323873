
const initialState = {
  justLoggedIn: null,
  sitePreviewURL: null,
  userID: null,
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'JUST_LOGGED_IN':
      return {
        ...state,
        justLoggedIn: action.payload,
      };
    case 'SITE_PREVIEW_URL':
      return {
        ...state,
        sitePreviewURL: action.payload,
      };
    case 'USER_ID':
      return {
        ...state,
        userID: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
