const initialState = {
  casted: false,
  status: 'Pause',
  paused: false,
  chromecastAvailable: false,
  castedVideoEnded: false,
  chromecastConnected: false,
  videoCastPlaying: false,
  reload: false,
  update: true,
  changeOneVideo: true,
};

const externalMediaPlayer = (state = initialState, action) => {
  switch (action.type) {
    case 'CHROMECAST':
      return {
        ...state,
        casted: action.payload,
      };
    case 'PLAY_STATUS':
      return {
        ...state,
        status: action.payload,
      };
    case 'CHROMECAST_AVAILABLE':
      return {
        ...state,
        chromecastAvailable: action.payload,
      };
    case 'CHROMECAST_CONNECTED':
      return {
        ...state,
        chromecastConnected: action.payload,
      };
    case 'VIDEO_CAST_PLAYING':
      return {
        ...state,
        videoCastPlaying: action.payload,
      };
    case 'CHROMECAST_PAUSED':
      return {
        ...state,
        paused: action.payload,
      };
    case 'RELOAD_VIDEO':
      return {
        ...state,
        reload: action.payload,
      };
    case 'SLIDER_UPDATE':
      return {
        ...state,
        update: action.payload,
      };
    case 'CASTED_VIDEO_ENDED':
      return {
        ...state,
        castedVideoEnded: action.payload,
      };
    default:
      return state;
  }
};

export default externalMediaPlayer;
