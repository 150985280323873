import {
  MEDIA_SELECTED,
  HIGHLIGHT_VIDEO,
  HIGHLIGHT_ROW,
  PLAYLIST_INDEX,
  CAROUSEL_POSITION,
  MOBILE_DROPDOWN,
  THEME,
  SCREEN_ORIENTATION,
  CHROMECAST_AVAILABLE,
  CHROMECAST_CONNECTED,
  CHROMECAST,
  PLAY_STATUS,
  MEDIA_PLAY_TIME,
  AUTO_PLAY_INTERMISSION,
  COLLAPSE_DESCRIPTION,
  VIDEO_CAST_PLAYING,
  CHROMECAST_PAUSED,
  RELOAD_VIDEO,
  SLIDER_UPDATE,
  MOBILE_WIDTH,
  VIDEO_ORDER,
  PREVENT_CHANGE,
  ROW_LENGTHS,
  ROW_NUMBER,
  COL_POSITION,
  FAST_FORWARD,
  REWIND,
  VOLUME_UP,
  VOLUME_DOWN,
  TOGGLE_PLAY,
  VIDEO_PLAYING,
  LOGO_COL_NUMBER,
  SCREEN_OVERLAY,
  LOGIN_SELECTED,
  LAST_VIDEO,
  JUST_LOGGED_IN,
  SITE_PREVIEW_URL,
  USER_ID,
  MEDIA_DURATION,
  MEDIA_BACKGROUND_UPDATED,
  SELECTED_PLAYLIST_ID,
  MAIN_DATA,
  CASTED_VIDEO_ENDED,
  SHOW_SEARCH_ITEMS,
  UPDATE_SEARCH,
  CLEAR_SEARCH,
  RESET_SEARCH,
} from './actionTypes';


export function selectMedia(payload) {
  return {
    type: MEDIA_SELECTED,
    payload,
  };
}

export function highlightVideo(payload) {
  return {
    type: HIGHLIGHT_VIDEO,
    payload,
  };
}

export function highlightRow(payload) {
  return {
    type: HIGHLIGHT_ROW,
    payload,
  };
}

export function selectedPlaylist(payload) {
  return {
    type: PLAYLIST_INDEX,
    payload,
  };
}

export function carouselPosition(payload) {
  return {
    type: CAROUSEL_POSITION,
    payload,
  };
}

export function mobileDropdown(payload) {
  return {
    type: MOBILE_DROPDOWN,
    payload,
  };
}

export function themes(payload) {
  return {
    type: THEME,
    payload,
  };
}

export function screenOrientation(payload) {
  return {
    type: SCREEN_ORIENTATION,
    payload,
  };
}

export function chromecastAvailable(payload) {
  return {
    type: CHROMECAST_AVAILABLE,
    payload,
  };
}

export function chromecastConnected(payload) {
  return {
    type: CHROMECAST_CONNECTED,
    payload,
  };
}

export function chromecast(payload) {
  return {
    type: CHROMECAST,
    payload,
  };
}

export function mediaPlayStatus(payload) {
  return {
    type: PLAY_STATUS,
    payload,
  };
}

export function getMediaTime(payload) {
  return {
    type: MEDIA_PLAY_TIME,
    payload,
  };
}

export function autoPlayIntermission(payload) {
  return {
    type: AUTO_PLAY_INTERMISSION,
    payload,
  };
}

export function collapseDescription(payload) {
  return {
    type: COLLAPSE_DESCRIPTION,
    payload,
  };
}

export function videoCastPlaying(payload) {
  return {
    type: VIDEO_CAST_PLAYING,
    payload,
  };
}

export function chromeCastPaused(payload) {
  return {
    type: CHROMECAST_PAUSED,
    payload,
  };
}

export function reloadVideo(payload) {
  return {
    type: RELOAD_VIDEO,
    payload,
  };
}

export function sliderUpdate(payload) {
  return {
    type: SLIDER_UPDATE,
    payload,
  };
}

export function mobileWidth(payload) {
  return {
    type: MOBILE_WIDTH,
    payload,
  };
}

export function videoOrder(payload) {
  return {
    type: VIDEO_ORDER,
    payload,
  };
}

export function preventChange(payload) {
  return {
    type: PREVENT_CHANGE,
    payload,
  };
}

export function rowLengths(payload) {
  return {
    type: ROW_LENGTHS,
    payload,
  };
}

export function rowNumber(payload) {
  return {
    type: ROW_NUMBER,
    payload,
  };
}

export function colPosition(payload) {
  return {
    type: COL_POSITION,
    payload,
  };
}

export function fastForward(payload) {
  return {
    type: FAST_FORWARD,
    payload,
  };
}

export function rewind(payload) {
  return {
    type: REWIND,
    payload,
  };
}

export function volumeUp(payload) {
  return {
    type: VOLUME_UP,
    payload,
  };
}

export function volumeDown(payload) {
  return {
    type: VOLUME_DOWN,
    payload,
  };
}

export function togglePlay(payload) {
  return {
    type: TOGGLE_PLAY,
    payload,
  };
}

export function videoPlaying(payload) {
  return {
    type: VIDEO_PLAYING,
    payload,
  };
}

export function logoColNumber(payload) {
  return {
    type: LOGO_COL_NUMBER,
    payload,
  };
}

export function screenOverlay(payload) {
  return {
    type: SCREEN_OVERLAY,
    payload,
  };
}

export function loginSelected(payload) {
  return {
    type: LOGIN_SELECTED,
    payload,
  };
}

export function lastVideo(payload) {
  return {
    type: LAST_VIDEO,
    payload,
  };
}

export function justLoggedIn(payload) {
  return {
    type: JUST_LOGGED_IN,
    payload,
  };
}

export function sitePreviewURL(payload) {
  return {
    type: SITE_PREVIEW_URL,
    payload,
  };
}

export function userID(payload) {
  return {
    type: USER_ID,
    payload,
  };
}

export function mediaDuration(payload) {
  return {
    type: MEDIA_DURATION,
    payload,
  };
}

export function mediaBackgroundUpdated(payload) {
  return {
    type: MEDIA_BACKGROUND_UPDATED,
    payload,
  };
}

export function selectedPlaylistId(payload) {
  return {
    type: SELECTED_PLAYLIST_ID,
    payload,
  };
}

export function mainData(payload) {
  return {
    type: MAIN_DATA,
    payload,
  };
}

export function castedVideoEnded(payload) {
  return {
    type: CASTED_VIDEO_ENDED,
    payload,
  };
}

export function showSearchResults(payload) {
  return {
    type: SHOW_SEARCH_ITEMS,
    payload,
  };
}

export function updateSearch(payload) {
  return {
    type: UPDATE_SEARCH,
    payload,
  };
}

export function clearSearch(payload) {
  return {
    type: CLEAR_SEARCH,
    payload,
  };
}

export function resetSearch(payload) {
  return {
    type: RESET_SEARCH,
    payload,
  };
}
