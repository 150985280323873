import * as React from 'react';
import Button from 'components/button';
import { Mutation } from 'react-apollo';
import styled from 'styled-components';
import { push } from 'react-router-redux';
import { store } from '../store';
// import { Login } from '../components/auth/auth';
import mutation from './acceptInvite.graphql';

const MainSpace = styled.div`
  padding-right: 25%;
  padding-left: 25%;
`;

class LoginView extends React.Component<Props, State> {
  state = {
    hasErrors: false,
  };

  render() {
    const { acceptInvite, match } = this.props;
    const { hasErrors } = this.state;

    if (hasErrors) {
      return (
        <div className="sub-auth">
          <div className="subpage-header">
            <div className="inner">
              <h1>Whoops!</h1>
              <div className="sub">You have an expired invitation.</div>
            </div>
          </div>
        </div>
      );
    }
    // Make sure we have the proper match setup
    if (match && match.params && match.params && match.params.invitation_id) {
      localStorage.setItem('re_invitation_id', match.params.invitation_id);
    }
    // We should have a local storage setup at this point otherwise it is messed up.
    const invitationId = localStorage.getItem('re_invitation_id');
    if (invitationId && localStorage.getItem('id_token') !== null) {
      return (
        <Button
          className="accountBtn"
          onClick={() => {
            localStorage.setItem('re_invitation_id', null);
            acceptInvite({ variables: { id: invitationId } })
              .then(({ data }) => {
                const { acceptInvitation } = data;
                localStorage.setItem('re_invitation_id', null);
                if (acceptInvitation) {
                  store.dispatch(push('/'));
                } else {
                  this.setState({ hasErrors: true });
                }
              });
          }}
        >
          Accept Invite
        </Button>
      );
    }
    return (<div>You need to login to accept this invitation.</div>);
  }
}
const WrappedMutation = (props) => (
  <Mutation
    mutation={mutation}
  >
    { acceptInvite => (
      <MainSpace>
        <LoginView acceptInvite={acceptInvite} {...props} />
      </MainSpace>
    )}
  </Mutation>
);

export default WrappedMutation;
