import React from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import Description from './description';
import PlayerAndDescription from './video/playerAndDescription';
import TVKeyboard from './video/TVApp/tvAppCarouselKeyboard';


const LoginInfo = {
  subFolder: {
    updated: '2017-10-03T02:00:31.783Z',
    order: -0.5,
    name: 'Navigation',
    autoId: '34asgerEFWE4gD',
    id: 'LoginSearchoiwfj904',
    assets: [{
      menu: {
        navType: 'login',
        name: 'Login',
        id: 'Login3994028jirhslk34',
        type: 'Login',
      },
    }, {
      menu: {
        navType: 'search',
        name: 'Search',
        id: 'SearchlsjdSDIOFJ894jl',
        type: 'Search',
      },
    },
    ],
  },
};


const RowWrapper = styled.div`
  position: relative;
  top:0px;
`;

const Overflow = styled.div`
  position: fixed;
  overflow: hidden;
  height: 605px;
  width: 100%;
  margin-top: 58px;
`;

// const determineInnerType = (item) => {
//   if (item.audio) {
//     return item.audio;
//   }
//   if (item.video) {
//     return item.video;
//   }
//   if (item.subFolder) {
//     return item.subFolder;
//   }
//   return null;
// };


// const determineType = (item) => {
//   if (item.audio) {
//     return item;
//   } else if (item.video) {
//     return item;
//   } else if (item.subFolder) {
//     return determineInnerType(item.subFolder.assets[0]);
//   }
// };


// const determineTitle = (item) => {
//   if (item.audio) {
//     return item.audio.name;
//   } else if (item.video) {
//     return item.video.name;
//   } else if (item.subFolder) {
//     return item.subFolder.name;
//   }
// };


const SeperateDataFire = (props) => {
  const {
    mainData, selectedPlaylist, orientation, currentVideo, videoKeyboard, keyboard,
    updatedRowHeights, shortOrder, setBackground, decor, location,
  } = props;
  return (
    <Switch>
      <Route
        exact
        path="/playlist/:playlistId/media/:videoId"
        render={() => (
          <div>
            <PlayerAndDescription
              mainData={mainData}
              selectedPlaylist={selectedPlaylist}
              orientation={orientation}
              intermission={currentVideo.intermission}
              video={currentVideo.video}
              videoKeyboard={videoKeyboard}
              yAxis={keyboard.yAxis}
            />
          </div>
        )}
      />
      <Route
        path="/"
        render={() => (
          <div>
            <div>
              <Description
                videoKeyboard={videoKeyboard}
                media={currentVideo.video}
              />
            </div>
            <Overflow>
              <RowWrapper className="RowWrapper">
                <TVKeyboard
                  colPosition={keyboard.colPositions}
                  updatedRowHeights={updatedRowHeights}
                  LoginInfo={LoginInfo}
                  mainData={mainData}
                  shortOrder={shortOrder}
                  setBackground={setBackground}
                  keyboard={keyboard}
                  selectedPlaylist={selectedPlaylist}
                  decor={decor}
                  urlPath={location.pathname}
                  highlightedVideo={currentVideo.highlightedVideo}
                  orientation={orientation}
                  location={location}
                />
              </RowWrapper>
            </Overflow>
          </div>
        )}
      />
    </Switch>
  );
};

export default SeperateDataFire;
