import React from 'react';
import VideoFolder from './videoFolder';

function getIdFromUrl(location) {
  return location.pathname.split('/')[2];
}

const GetFolderUrlFromVideo = (props) => {
  const {
    keyboard, orientation, decor,
    currentVideo, refetchId, selectedPlaylist, playlistTracker,
    location, chromecastConnected, highlightedVideo, urlPath,
  } = props;
  const folderId = getIdFromUrl(location);
  return (
    <VideoFolder
      folderId={folderId}
      keyboard={keyboard}
      orientation={orientation}
      decor={decor}
      currentVideo={currentVideo}
      refetchId={refetchId}
      selectedPlaylist={selectedPlaylist}
      playlistTracker={playlistTracker}
      urlPath={urlPath}
      chromecastConnected={chromecastConnected}
      highlightedVideo={highlightedVideo}
      web
    />
  );
};

export default GetFolderUrlFromVideo;
