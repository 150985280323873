import React from 'react';
import { Mutation } from 'react-apollo';
import mutation from './add.graphql';

const Add = ({ addToMyList, videoId }) => (
  <div
    tabIndex={0}
    role="button"
    onKeyDown={() => addToMyList({
      variables: { videoId },
      refetchQueries: ['getFolder'],
    })}
    onClick={() => addToMyList({
      variables: { videoId },
      refetchQueries: ['getFolder'],
    })}
    style={{
      height: 50,
      width: 50,
      textAlign: 'center',
      color: '#FFFFFF',
      fontSize: 32,
      fontWeight: 'bold',
    }}
  >
    +
  </div>
);
// eslint-disable-next-line
const WrappedMutation = (props) => (
  <Mutation mutation={mutation}>
    { addToMyList => (
      <Add addToMyList={addToMyList} {...props} />
    )}
  </Mutation>
);


export default WrappedMutation;
