/* @flow */
import * as React from 'react';
import Button from 'components/button';
import { Mutation } from 'react-apollo';
import mutation from './addInvite.graphql';

const AddInvite = ({ addInvite }: any) => (
  <Button
    onClick={() => addInvite()}
    className="accountAddBtn"
  >
    Add Sub Account
  </Button>
);

const WrappedMutation = (props: any) => (
  <Mutation
    mutation={mutation}
    refetchQueries={['AccountDetails']}
  >
    { addInvite => (
      <AddInvite addInvite={addInvite} {...props} />
    )}
  </Mutation>
);

export default WrappedMutation;
