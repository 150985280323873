const initialState = {
  currentPlayTime: 0,
};

const castedTimeListeners = (state = initialState, action) => {
  switch (action.type) {
    case 'MEDIA_PLAY_TIME':
      return {
        ...state,
        currentPlayTime: action.payload,
      };
    case 'MEDIA_DURATION':
      return {
        ...state,
        mediaDuration: action.payload,
      };
    default:
      return state;
  }
};

export default castedTimeListeners;
