/* eslint-disable no-underscore-dangle */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'react-router-redux';
import { Grid, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import {
  selectMedia, selectedPlaylist, chromecast, autoPlayIntermission,
  selectedPlaylistId, updateSearch, resetSearch,
} from '../../../actions/index';
import { store } from '../../../store';
import { loadCustomMedia } from '../Chromecast/chromecast';
import { playlistItemTypes } from '../constants';


const TileDetails = styled.div`
    position: absolute;
    margin-left: 0.7vw;
    width: 19.55vw;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    font-size: 10px;
    opacity: 0;
    background: -webkit-linear-gradient(bottom, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 100%);
    background: linear-gradient(to top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 100%);
    -webkit-transition: 450ms opacity;
    transition: 450ms opacity;
    @media(max-width: 1025px){
      margin-left: 0.7vw;
      width: 26.1vw;
    }
    @media(max-width: 769px) {
      width: 40vw;
    }
    &:hover {
        opacity: 1;
    }
    & img {
      position: absolute;
      width: 6vw;
      height: auto;
      margin: auto auto auto auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0.5;
      &:hover {
          opacity: 1;
      }
    }
    & div {
      position: absolute;
      display: block;
      bottom: 0;
      padding: 10px;
      z-index: 1;
      color: white;
      width: 75%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0 0 10px 10px;
    }
    & * {
      font-family: 'Lato', sans-serif;
      font-weight: 500;
    }

`;


const Tile = styled.div`
  position: relative;
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  -webkit-transition: 450ms all;
  transition: 450ms all;
  -webkit-transform-origin: center left;
  transform-origin: center left;
`;

const PreviewImage = styled.img`
  width: 19.5vw;
  // width: 295px;
  height: auto;
  margin-left: 0.75vw;
  margin-right: 40px;
  @media (max-width: 1024px) {
      width: 26vw;
  }
  @media (max-width: 768px) {
      width: 40vw;
  }
`;

const PreviewAndDetails = styled(Row)`
  display: flex;
`;

const Wrapper = styled(Grid)`
    cursor: pointer;
`;

class SearchPlaylistItem extends Component {
  determineItemType() {
    const { media } = this.props;
    const type = playlistItemTypes(media.__typename);
    return type;
  }

  render() {
    const {
      media, subFolder, currentPlaylist, chromecastConnected, dispatch, refetchId, chrome,
    } = this.props;
    return (
      <Wrapper
        fluid

        onClick={() => {
          store.dispatch(updateSearch(''));
          store.dispatch(resetSearch(true));
          if (!subFolder) {
            window.scrollTo(0, 0);
            store.dispatch(push(`/playlist/${currentPlaylist.id}/media/${media.id}`));
            if (!chromecastConnected) {
              dispatch(chromecast(false));
              store.dispatch(autoPlayIntermission(false));
            } else {
              loadCustomMedia(media);
            }
          } else {
            dispatch(selectedPlaylistId(media.id));
            dispatch(push(`/test/${media.id}?preview=true`));
            refetchId();
          }
          dispatch(selectedPlaylist(currentPlaylist));
          dispatch(selectMedia(media));
        }}
      >
        <PreviewAndDetails>
          <Col>
            <Tile>
              <PreviewImage
                src={this.determineItemType()}
                view={(chrome)}
                alt="media preview"
              />
              <TileDetails>
                <img src="/images/play.png" alt="play" />
                <div>
                  {media.name}
                </div>
              </TileDetails>
            </Tile>
          </Col>
          <Col>
            <h3>{media.name}</h3>
            <h5>{media.description}</h5>
          </Col>
        </PreviewAndDetails>
        <Row>
          <hr />
        </Row>

      </Wrapper>
    );
  }
}


export default withRouter(connect()(SearchPlaylistItem));
