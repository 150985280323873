import React from 'react';
import Add from './Add';
import Remove from './Remove';
// import { useMutation } from '@apollo/react-hooks';
// import add from './add.graphql';

// const [addToMyList] = useMutation(add);

export default ({ asset, media, currentPlaylist, children }) => (
  <div style={{ position: 'relative' }}>
    {children}
    { localStorage.id_token && localStorage.id_token !== "" && (
      <div style={{ position: 'absolute', top: 10, right: 30 }}>
        { !currentPlaylist.name.startsWith('My List') && (
          <Add videoId={media.id} />
        )}
        { currentPlaylist.name.startsWith('My List') && (
          <Remove videoId={media.id} assetId={asset.id} />
        )}
      </div>
    )}    
  </div>
);
