import React from 'react';
import VideoPreview from './video/BrowserAndMobile/videoPreview';


function determineMediaTypePreview(props) {
  const {
    mainData, orientation, decor, currentVideo,
  } = props;
  if (mainData[0].audio !== null) {
    const media = props.mainData[0].audio;
    return (
      <VideoPreview
        media={media}
        orientation={orientation}
        playlist={mainData[0]}
        singleItem
        decor={decor}
        descriptionShown={currentVideo.descriptionShown}
      />
    );
  }
  if (mainData[0].video !== null) {
    const media = mainData[0].video;
    return (
      <VideoPreview
        media={media}
        orientation={orientation}
        singleItem
        playlist={mainData[0]}
        decor={decor}
        descriptionShown={currentVideo.descriptionShown}
      />
    );
  }
  if (mainData[0].subFolder && mainData[0].subFolder.assets[0]) {
    if (mainData[0].subFolder.assets[0].audio !== null) {
      const media = mainData[0].subFolder.assets[0].audio;

      return (
        <VideoPreview
          media={media}
          orientation={orientation}
          playlist={mainData[0].subFolder}
          decor={decor}
          descriptionShown={currentVideo.descriptionShown}
        />
      );
    }
    if (mainData[0].subFolder.assets[0].video !== null) {
      const media = mainData[0].subFolder.assets[0].video;
      return (
        <VideoPreview
          media={media}
          orientation={orientation}
          playlist={mainData[0].subFolder}
          decor={decor}
          descriptionShown={currentVideo.descriptionShown}
        />
      );
    }
  }
  return null;
}

const TypeDivergenceForVieoPreview = (props) => (
  <div>
    {determineMediaTypePreview(props)}
  </div>
);


export default TypeDivergenceForVieoPreview;
