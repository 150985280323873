const initialState = {
  selectedPlaylistId: null,
};

const playlistTracker = (state = initialState, action) => {
  switch (action.type) {
    case 'SELECTED_PLAYLIST_ID':
      return {
        ...state,
        selectedPlaylistId: action.payload,
      };
    default:
      return state;
  }
};

export default playlistTracker;
