import React, { Component } from 'react';
import styled from 'styled-components';
import { push } from 'react-router-redux';
import { store } from '../../../store';
import TVAppCarouselItem from './tvAppCarouselItem';

const Wrapper = styled.div`
  position:absolute;
  transform: translate3d(0px, ${props => props.absoluteHeight}px, 0px); 
  width:100%;
  height: 171px;
`;

const ColWrapper = styled.div`
  height:100%;
  width:100%;
  padding-left: ${props => props.columnPadding}px;
`;

const CURRENTLY_SELECTED_VIDEO = '0.99';

function determineSubFolderType(item) {
  if (item.video) {
    return item.video;
  }
  if (item.audio) {
    return item.audio;
  }
  if (item.subFolder) {
    return item.subFolder;
  }
  if (item.menu) {
    return item.menu;
  }
  return null;
}

class TVAppCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colPosition: 0,
      absoluteHeight: '2',
    };
    this.hotkeyHandler = this.handleHotkey.bind(this);
  }

  componentWillMount() {
    const { absoluteHeight } = this.props;
    this.setState({
      absoluteHeight,
    });
  }

  componentDidMount() {
    document.addEventListener('keydown', this.hotkeyHandler);
  }

  // Detatch the keyboard listener
  componentWillUnmount() {
    document.removeEventListener('keydown', this.hotkeyHandler);
  }

  DetermineSelectedVideoOnKeyboardChange(items, colPositions) {
    const { selectMedia, selectedPlaylistId } = this.props;
    if (items.__typename === 'Audio' || items.__typename === 'Video') { //eslint-disable-line
      store.dispatch(selectMedia(items));
    } else if (items.subFolder) {
      if (items.subFolder.assets[colPositions].audio) {
        selectMedia(items.subFolder.assets[colPositions].audio);
      } else if (items.subFolder.assets[colPositions].video) {
        selectMedia(items.subFolder.assets[colPositions].video);
      } else if (items.subFolder.assets[colPositions].subFolder) {
        selectedPlaylistId(items.subFolder.assets[colPositions].subFolder.id);
        selectMedia(items.subFolder.assets[colPositions].subFolder);
      }
    }
  }

  changeBackground(media) {
    const { colPosition } = this.state;
    const { setBackground } = this.props;
    if (media.assets) {
      const item = media.assets[colPosition];
      setBackground(item);
    } else {
      setBackground(media); // eslint-disable-line no-use-before-define
    }
  }

  handleSpecificKey(event) {
    switch (event.keyCode) {
      case 37:
        return this.leftKey.call(this, event);
      case 39:
        return this.rightKey.call(this, event);
      case 227:
        return this.leftKey.call(this, event);
      case 228:
        return this.rightKey.call(this, event);
      case 13:
        return this.enterKey.call(this, event);
      case 38:
        return this.upKey.call(this, event);
      case 40:
        return this.downKey.call(this, event);
      default:
        return null;
    }
  }

  upKey() {
    const { absoluteHeight, items } = this.props;
    if (absoluteHeight === -190) {
      let item;
      if (items.subFolder) {
        item = this.determineItemType();
      } else {
        item = items;
      }
      this.changeBackground(item);
    }
  }

  downKey() {
    const { absoluteHeight, items } = this.props;
    if (absoluteHeight === 230) {
      let item;
      if (items.subFolder) {
        item = this.determineItemType();
      } else {
        item = items;
      }
      this.changeBackground(item);
    }
  }

  leftKey() {
    const { absoluteHeight, items } = this.props;
    const { colPosition } = this.state;
    if (absoluteHeight === 0 && items.subFolder && colPosition > 0) {
      const newPosition = colPosition - 1;
      this.setState({
        colPosition: newPosition,
      });
      const itemsWithType = this.determineItemType();
      this.changeBackground(itemsWithType);
    }
  }

  rightKey() {
    const { absoluteHeight, items } = this.props;
    const { colPosition } = this.state;
    if (absoluteHeight === 0
      && items.subFolder
      && colPosition < items.subFolder.assets.length - 1
    ) {
      const newPosition = colPosition + 1;
      this.setState({
        colPosition: newPosition,
      });
      const itemsWithType = this.determineItemType();
      this.changeBackground(itemsWithType);
    }
  }

  enterKey() {
    const {
      absoluteHeight, location, togglePlay, videoPlaying, videoKeyboard,
      keyboard, videoOrder, rowNumber, data, playlistTracker, items,
    } = this.props;
    const { colPosition } = this.state;
    if (absoluteHeight === 0) {
      if (location.pathname.startsWith('/playlist/')) {
        togglePlay(true);
        videoPlaying(!videoKeyboard.played);
        return;
      }


      let itemInFolder = null;
      const itemsWithType = this.determineItemType();
      if (itemsWithType.assets) {
        itemInFolder = determineSubFolderType(itemsWithType.assets[colPosition]);
      }


      if (!keyboard.overlay) {
        if (itemInFolder && itemInFolder.__typename === 'Folder') { // eslint-disable-line
          videoOrder(null);
          store.dispatch(push(`/test/${playlistTracker.selectedPlaylistId}?preview=true`));
          rowNumber(1);
          data.refetch();
        } else if (itemInFolder != null) {
          this.DetermineSelectedVideoOnKeyboardChange(itemInFolder);
          store.dispatch(push(`/playlist/${itemsWithType.id}/media/${itemInFolder.id}`));
          window.scrollTo(0, 0);
        } else {
          const media = this.determineSubFolderType(items);
          this.DetermineSelectedVideoOnKeyboardChange(media);
          store.dispatch(push(`/playlist/${itemsWithType.id}/media/${media.id}`));
        }
      }
    }
  }

  handleHotkey(event) {
    const { continuousKeyPressed } = this.state;
    // 8 = back
    // 179 = play
    // forward = 228
    // back = 227
    // handles user holding down arrowkeys
    if (event.repeat) {
      if (!continuousKeyPressed) {
        this.handleSpecificKey(event);
        this.setState({
          continuousKeyPressed: true,
        });
        setTimeout(() => {
          this.setState({
            continuousKeyPressed: false,
          });
        }, 150);
      }
    } else {
      this.handleSpecificKey(event);
    }
  }

  determineItemType() {
    const { items } = this.props;
    if (items.subFolder !== null) {
      return items.subFolder;
    }
    if (items.audio !== null) {
      return items.audio;
    }
    if (items.video !== null) {
      return items.video;
    }
    return null;
  }

  determineHorizontalPosition(index) {
    const { colPosition } = this.state;
    if (index < colPosition - 1) {
      return -545;
    }
    if (index < colPosition) {
      return -290;
    }
    if (index === colPosition) {
      return 0;
    }
    return null;
  }

  render() {
    const {
      absoluteHeight, scale, keyboard, items: pItems,
    } = this.props;
    const { colPosition, absoluteHeight: sAbsoluteHeight } = this.state;
    let imageFade = '-webkit-gradient(linear,left 100%,left 56%,from(rgba(0,0,0,1)),to(rgba(0,0,0,1)))';
    const items = this.determineItemType();
    // const sizesDifference = -25;
    let columnPadding = 0;
    let brightness = 100;


    if (absoluteHeight) {
      imageFade = '-webkit-gradient(linear,left 100%,left 56%,from(rgba(0,0,0,1)),to(rgba(0,0,0,0)))';
    }
    if (absoluteHeight === 0) {
      // horizontalPosition = [-545, -290, 0, 290, 545, 800, 1055, 1310, 1565, 1820];
      columnPadding = 5;
    } else {
      brightness = 50;
      // horizontalPosition = [-510 + sizesDifference, -255
      // + sizesDifference, 0 + sizesDifference, 255 + sizesDifference, 510
      // + sizesDifference, 765 + sizesDifference, 1020 + sizesDifference, 1275
      // + sizesDifference, 1530 + sizesDifference, 1785 + sizesDifference, 2040 + sizesDifference];
    }
    // column lengths
    const num = 10;
    // if (this.state.colPosition <= 1){
    //   horizontalPosition.shift()
    //   num--;
    // }
    // if(this.state.colPosition === 0){
    //   horizontalPosition.shift()
    //   num--;
    // }

    const index = colPosition + num;
    if (items.assets && sAbsoluteHeight !== undefined) {
      return (
        <Wrapper className="PARENTWRAPPER" scale={scale}>
          <ColWrapper className="ColumnWrapper" columnPadding={columnPadding}>
            {(() => {
              const videos = [];
              let prevVideos = -290;
              let nextVideos = 290;
              for (let i = 0; i < colPosition; i += 1) {
                videos.push(<TVAppCarouselItem
                  key={this.determineSubFolderType(items.assets[i]).id}
                  thing={this.determineSubFolderType(items.assets[i]).id}
                  media={items.assets[i]}
                  index={index}
                  keyboard={keyboard}
                  absoluteHeight={absoluteHeight}
                  num={num}
                  scale="0.75"
                  horizontalPosition={(colPosition - i === 1 ? -290 : -545)}
                  imageFade={imageFade}
                  brightness={brightness}
                />);
                if (prevVideos >= -545) {
                  prevVideos -= 290;
                }
              }
              videos.push(<TVAppCarouselItem
                key={this.determineSubFolderType(items.assets[colPosition]).id}
                media={items.assets[colPosition]}
                thing={this.determineSubFolderType(items.assets[colPosition]).id}
                index={index}
                keyboard={keyboard}
                absoluteHeight={absoluteHeight}
                num={num}
                scale={(absoluteHeight === 0) ? CURRENTLY_SELECTED_VIDEO : '0.75'}
                horizontalPosition={0}
                imageFade={imageFade}
                brightness={brightness}
              />);
              for (let i = colPosition + 1; i <= items.assets.length - 1; i += 1) {
                videos.push(<TVAppCarouselItem
                  key={this.determineSubFolderType(items.assets[i]).id}
                  media={items.assets[i]}
                  thing={this.determineSubFolderType(items.assets[i]).id}
                  index={index}
                  keyboard={keyboard}
                  absoluteHeight={absoluteHeight}
                  num={num}
                  scale="0.75"
                  horizontalPosition={nextVideos}
                  imageFade={imageFade}
                  brightness={brightness}
                />);
                if (nextVideos <= 1820) {
                  nextVideos += 290;
                }
              }
              return videos;
            })()
              // items.assets
              // .map((item, index) =>{
              //   return <TVAppCarouselItem
              //     key={index}
              //     video={this.determineSubFolderType(item)}
              //     index={index}
              //     keyboard={keyboard}
              //     absoluteHeight={this.props.absoluteHeight}
              //     num={num}
              //     horizontalPosition={this.determineHorizontalPosition(index)}
              //     imageFade={imageFade}
              //     brightness={brightness}
              //   />
              // })
            }
          </ColWrapper>
        </Wrapper>
      );
    }
    return (
      <Wrapper className="singleWRAPPER" scale={scale}>
        <ColWrapper className="ColumnWrapper" columnPadding={columnPadding}>
          <TVAppCarouselItem
            media={pItems}
            index={0}
            keyboard={keyboard}
            absoluteHeight={absoluteHeight}
            scale={(absoluteHeight === 0) ? CURRENTLY_SELECTED_VIDEO : '0.75'}
            num={num}
            horizontalPosition={0}
            imageFade={imageFade}
            brightness={brightness}
          />
        </ColWrapper>
      </Wrapper>
    );
  }
}

export default TVAppCarousel;
