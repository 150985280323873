import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';

const DecorWrapper = styled(Row)`
padding-right: 7%;
padding-left: 7%;
margin-right: auto;
margin-left: auto;
`;

const Decoration = (props) => (
  <DecorWrapper className="DECOR">
    <Col xs={12}>
      <props.decor />
    </Col>
  </DecorWrapper>
);

export default Decoration;
