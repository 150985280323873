import * as React from 'react';
import styled from 'styled-components';
import UninviteButton from 'components/subAccount/UninviteButton';

const SubAccountContent = styled.div`
  font-size: 18px;
  display: flex;
  align-items: stretch;
  margin-bottom: 5px;
`;

const SubAccountItemDiv = styled.div`
  padding: 0 5px 0 0px;
  margin-left: 0px;
  margin-right: 0px;
`;

const RemoveDiv = styled.div`
  margin: auto;
  margin-right: 0px;
`;

const SubAccountItem = (data) => {
  if (data.invitePending) {
    return (
      <SubAccountContent key={data.id}>
        <SubAccountItemDiv>
          <a href={`${window.location.origin}/invite/${data.id}`}>
            {window.location.origin}
            /invite/
            {data.id}
          </a>
        </SubAccountItemDiv>
        <RemoveDiv>
          <UninviteButton subAccount={data} />
        </RemoveDiv>
      </SubAccountContent>
    );
  }
  return (
    <SubAccountContent key={data.id}>
      <SubAccountItemDiv>{data.email}</SubAccountItemDiv>
      <RemoveDiv>
        <UninviteButton subAccount={data} />
      </RemoveDiv>
    </SubAccountContent>
  );
};

export default SubAccountItem;
