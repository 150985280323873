import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'react-router-redux';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import Slider from 'react-slick';
import {
  selectMedia, selectedPlaylist, chromecast, autoPlayIntermission,
} from '../../../actions/index';
import { store } from '../../../store';
import { loadCustomMedia } from '../Chromecast/chromecast';
import {
  Carousel,
  Title,
  ImageWrapper,
  RowInner,
} from '../constants';

const TileDetails = styled.div`
    position: absolute;
    margin-left: 0.7vw;
    width: 19.55vw;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    font-size: 10px;
    opacity: 0;
    background: -webkit-linear-gradient(bottom, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 100%);
    background: linear-gradient(to top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 100%);
    -webkit-transition: 450ms opacity;
    transition: 450ms opacity;
    @media(max-width: 1025px){
      margin-left: 0.7vw;
      width: 26.1vw;
    }
    @media(max-width: 769px) {
      width: 40vw;
    }
    &:hover {
        opacity: 1;
    }
    & img {
      position: absolute;
      width: 6vw;
      height: auto;
      margin: auto auto auto auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0.5;
      & :hover & {
          opacity: 1;
      }
    }
    & div {
      position: absolute;
      display: block;
      bottom: 0;
      padding: 10px;
      z-index: 1;
      color: white;
      width: 75%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0 0 10px 10px;
    }

`;

const Tile = styled.div`
  position: relative;
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  -webkit-transition: 450ms all;
  transition: 450ms all;
  -webkit-transform-origin: center left;
  transform-origin: center left;
`;

const PreviewImage = styled.img`
  width: 19.5vw;
  // width: 295px;
  height: auto;
  margin-left: 0.75vw;
  margin-right: 40px;
  @media (max-width: 1024px) {
      width: 26vw;
  }
  @media (max-width: 768px) {
      width: 40vw;
  }
`;

const Wrapper = styled.div`
    cursor: pointer;
`;

class BrowserSinglePlaylistItem extends Component {
  handleClick = () => {
    const {
      currentPlaylist, media, chromecastConnected, dispatch,
    } = this.props;
    window.scrollTo(0, 0);
    store.dispatch(push(`/playlist/${currentPlaylist.id}/media/${media.id}`));
    if (!chromecastConnected) {
      dispatch(chromecast(false));
      store.dispatch(autoPlayIntermission(false));
    } else {
      loadCustomMedia(media);
    }
    dispatch(selectedPlaylist(currentPlaylist));
    dispatch(selectMedia(media));
  }

  render() {
    const { name, previewImageUrl } = this.props;
    const settings = {
      adaptiveHeight: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
    };
    return (
      <Carousel>
        <Row>
          <Col xs={12}>
            <Title>
              {name.toUpperCase()}
            </Title>
          </Col>
        </Row>
        <ImageWrapper>
          <Col md={12}>
            <Row>
              <RowInner className="rowBeforeSlider">
                <Slider {...settings}>
                  <Wrapper onClick={this.handleClick}>
                    <Tile>
                      <PreviewImage
                        src={previewImageUrl}
                        alt="media preview"
                      />
                      <TileDetails>
                        <img src="/images/play.png" alt="play" />
                        <div>
                          {name}
                        </div>
                      </TileDetails>
                    </Tile>

                  </Wrapper>
                </Slider>
              </RowInner>
            </Row>
          </Col>
        </ImageWrapper>
      </Carousel>
    );
  }
}


export default withRouter(connect()(BrowserSinglePlaylistItem));
