/* global WURFL */

const portrait = window.matchMedia('(orientation: portrait)').matches;

const initialState = {
  view: portrait,
  device: (WURFL.is_mobile || (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream)),
  toggle: false,
  phone: WURFL.form_factor === 'Smartphone',
  width: (portrait ? window.screen.width : window.screen.height),
  colNum: 4,
};

const screenOrientation = (state = initialState, action) => {
  switch (action.type) {
    case 'SCREEN_ORIENTATION':
      return {
        ...state,
        view: action.payload,
      };
    case 'MOBILE_DROPDOWN':
      return {
        ...state,
        toggle: action.payload,
      };
    case 'MOBILE_WIDTH':
      return {
        ...state,
        width: action.payload,
      };
    case 'LOGO_COL_NUMBER':
      return {
        ...state,
        colNum: action.payload,
      };
    default:
      return state;
  }
};

export default screenOrientation;
