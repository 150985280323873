import React from 'react';
import styled from 'styled-components';
// import { Col } from 'react-bootstrap';
import Account from 'pages/account';
import Invite from 'pages/invite';
import { Route, Switch } from 'react-router-dom';
import SearchQuery from 'components/video/Search/searchQuery';
import { push } from 'react-router-redux';
import { store } from '../store';
import SyncPurchases from './video/UserContent/syncPurchases';
import HomeFolder from './folders/homeFolder';
import GetFolderUrlID from './folders/getFolderUrlID';
import GetFolderUrlFromVideo from './folders/getFolderUrlFromVideo';
import Folder from './folder';
import { ConditionalCol } from './video/constants';
import PlayerAndDescription from './video/playerAndDescription';
// import TypeDivergenceForVieoPreview from './TypeDivergenceForVieoPreview';
import Content from './video/UserContent/content';

const Wrapper = styled.div`
  height: auto;
`;

const SearchAndMyContentWrapper = styled.div`
  padding-top: 100px;
`;

const Routes = (props) => {
  const {
    orientation, refetchId, selectedPlaylist,
    decor, playlistTracker, location, currentVideo,
    keyboard, search, externalMediaPlayer, selectedPlaylistId,
    folderId,
  } = props;
  const invId = localStorage.getItem('re_invitation_id');
  if (invId !== 'null' && invId !== null && invId !== undefined && !location.pathname.includes('invite')) {
    store.dispatch(push(`/inviteBack/${invId}`));
  }
  if (search.searchTerm.length >= 3) {
    return (
      <div>
        <SearchAndMyContentWrapper>
          <SearchQuery
            refetchId={refetchId}
            // mainData={data.folder[0]}
            selectedPlaylist={selectedPlaylist}
            decor={decor}
            playlistTracker={playlistTracker}
            urlPath={location.pathname}
            highlightedVideo={currentVideo.highlightedVideo}
            orientation={orientation}
            keyboard={keyboard}
            search={search}
            externalMediaPlayer={externalMediaPlayer}
          />
        </SearchAndMyContentWrapper>
      </div>
    );
  }
  return (
    <Wrapper>
      <Switch>
        <Route
          path="/account"
          render={Account}
        />
        <Route
          path="/inviteBack/:re_invitation_id"
          render={Invite}
        />
        <Route
          path="/invite/:invitation_id"
          render={Invite}
        />
        <Route
          path="/syncpurchases"
          render={() => (
            <SearchAndMyContentWrapper>
              <SyncPurchases />
            </SearchAndMyContentWrapper>
          )}
        />
        <Route
          path="/mycontent/:contentId"
          render={() => (
            <SearchAndMyContentWrapper>
              <Content
                refetchId={refetchId}
                // mainData={data.folder[0]}
                selectedPlaylist={selectedPlaylist}
                decor={decor}
                playlistTracker={playlistTracker}
                urlPath={location.pathname}
                highlightedVideo={currentVideo.highlightedVideo}
                orientation={orientation}
                keyboard={keyboard}
                externalMediaPlayer={externalMediaPlayer}
              />
            </SearchAndMyContentWrapper>
          )}
        />
        <Route
          path="/playlist/:playlistId/media/:videoId"
          render={() => (
            <GetFolderUrlFromVideo
              selectedPlaylist={selectedPlaylist}
              orientation={orientation}
              externalMediaPlayer={externalMediaPlayer}
              intermission={currentVideo.intermission}
              lastVideo={currentVideo.lastVideo}
              refetchId={refetchId}
              location={location}
              currentVideo={currentVideo}
              decor={decor}
              playlistTracker={playlistTracker}
              urlPath={location.pathname}
              chromecastConnected={externalMediaPlayer.chromecastConnected}
              highlightedVideo={currentVideo.highlightedVideo}
              keyboard={keyboard}
            />
          )}
        />
        <Route
          path="/media/:videoId"
          render={() => (
            <div>
              <Folder
                folderId={selectedPlaylistId}
              >
                <ConditionalCol xs={8} orientation={orientation}>
                  <PlayerAndDescription
                    // mainData={data.folder[0].assets}
                    selectedPlaylist={selectedPlaylist}
                    orientation={orientation}
                    externalMediaPlayer={externalMediaPlayer}
                    intermission={currentVideo.intermission}
                    lastVideo={currentVideo.lastVideo}
                  />
                </ConditionalCol>
              </Folder>
            </div>
          )}
        />
        <Route
          path="/folder/:mediaId"
          render={() => (
            <div>
              <GetFolderUrlID
                folderId={selectedPlaylistId}
                keyboard={keyboard}
                orientation={orientation}
                decor={decor}
                currentVideo={currentVideo}
                refetchId={refetchId}
                selectedPlaylist={selectedPlaylist}
                playlistTracker={playlistTracker}
                urlPath={location.pathname}
                chromecastConnected={externalMediaPlayer.chromecastConnected}
                highlightedVideo={currentVideo.highlightedVideo}
                location={location}
                web
              />
            </div>
          )}
        />
        <Route
          path="/"
          render={() => (
            <div>
              <HomeFolder
                folderId={folderId}
                keyboard={keyboard}
                orientation={orientation}
                decor={decor}
                currentVideo={currentVideo}
                refetchId={refetchId}
                selectedPlaylist={selectedPlaylist}
                playlistTracker={playlistTracker}
                urlPath={location.pathname}
                chromecastConnected={externalMediaPlayer.chromecastConnected}
                highlightedVideo={currentVideo.highlightedVideo}
                web
                landingPage
              />
            </div>
          )}
        />
      </Switch>
    </Wrapper>
  );
};

export default Routes;
