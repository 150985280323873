import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Col, Row, Grid } from 'react-bootstrap';
import { push } from 'react-router-redux';
import { Collapse } from 'react-collapse';
import FontAwesome from 'react-fontawesome';
import { Login } from './auth/auth';
import { store } from '../store';
import {
  mobileDropdown, selectedPlaylistId, updateSearch, resetSearch,
} from '../actions/index';

const GridWrapper = styled(Grid)`
  position: static;
  top: 0;
  @media (max-width: 414px) {
    position: sticky;
  }
  & * {
    font-family: 'Lato', sans-serif;
    font-weight: 500;
  }
`;

const NavRow = styled(Row)`
  padding-right: 6.8%;
  padding-left: 6.8%;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const LogoParameters = styled.img`
  height: ${props => props.theme.logoHeight}px;
  width: auto;
  margin: 15px 0 15px 0;
  cursor: pointer;
  @media (max-width: 1024px) {
    height: 50px
}
  @media (max-width: 736px) {
    height: 25px;
    margin: 15px 0 15px 0;
  }
`;
const LogoCol = styled(Col)`
  display: flex;
  justify-content: center;
`;

const HamburgerWrapper = styled.div`
  margin: 9px;
`;

const HamburgerColumn = styled(Col)`
  display: flex;
  align-content: center;
  padding: 0;
`;

const LoginCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-content: center;
`;

const NavButtons = styled.h5`
  font-weight: ${props => props.weight};
  letter-spacing: 3px;
  cursor: pointer;
  padding: ${props => props.padding};
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  &:hover{
    text-decoration: underline;
  }
`;

const Hamburger = styled(FontAwesome)`
font: normal normal normal 23px/1 FontAwesome !important;
  @media (max-width: 736px) {
    font-size: 150%
  }
`;
/* eslint-disable */
const SearchWrapper = styled.div`
  position:relative;
  width:152px
`;

const SearchBar = styled.img`
  position: absolute;
  opacity: ${props => props.opacity};
  width: 152px;
  height: auto;
  margin-top: 4px;
  padding-right: 33px;
  cursor: text;
`;
// height: 28px;

const SearchInput = styled.input`
  position: absolute;
  width: 119px;
  opacity: ${props => props.opacity};
  width: ${props => props.width}px;
  right: 0;
  height: 28px;
  margin-top: 4px;
  margin-right: 33px;
  border-radius: 0 !important;
  -webkit-transition: width 200ms linear;, right 200ms linear;
  -moz-transition: width 200ms linear, right 200ms linear;
  -ms-transition: width 200ms linear, right 200ms linear;
  -o-transition: width 200ms linear, right 200ms linear;
  transition: width 200ms linear, right 200ms linear;
`;
/* eslint-enable */

const DropdownWrapper = styled(Row)`
  padding-bottom: 20px;
  padding-bottom: 20px;
  & h3 {
    padding-top: 20px;
    padding-bottom:20px
    magin: 0;
  }
`;
const WAIT_INTERVAL = 500;
// const ENTER_KEY = 13;
const FAUXID = '49043slkwjoi49';

class Logo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      toggleSearch: false,
      toggleSearchWidth: false,
      profileDropdown: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.triggerSearch = this.triggerSearch.bind(this);
    this.handleSearchBar = this.handleSearchBar.bind(this);
    this.checkIfSearchIsPopulated = this.checkIfSearchIsPopulated.bind(this);
    // this.defaultSearchBar = this.defaultSearchBar.bind(this)
    // this.handleKeyDown = this.handleKeyDown.bind(this)
  }

  componentWillMount() {
    this.timer = null;
  }

  componentDidUpdate() {
    const { search } = this.props;
    if (search.resetSearch) {
      this.setState({ //eslint-disable-line
        value: '',
      });
      store.dispatch(resetSearch(false));
      this.handleDefaultSearchBar(true);
    }
  }

  handleMyContentClick() {
    this.checkIfSearchIsPopulated();
    store.dispatch(push(`/mycontent/${FAUXID}`));
  }

  handleInputChange(e) {
    clearTimeout(this.timer);

    this.setState({ value: e.target.value });

    this.timer = setTimeout(this.triggerSearch, WAIT_INTERVAL);
  }

  // handleKeyDown(e){
  //   if(e.keyCode === ENTER_KEY) {
  //     this.triggerSearch()
  //   }
  // }

  checkIfSearchIsPopulated() {
    const { search } = this.props;
    if (search.searchTerm.length > 0) {
      this.setState({
        value: '',
        toggleSearchWidth: false,
      });
      store.dispatch(updateSearch(''));
      setTimeout(() => {
        this.setState({
          toggleSearch: false,
        });
      }, 200);
    }
  }

  handleLogoClick() {
    this.checkIfSearchIsPopulated();
    store.dispatch(selectedPlaylistId(null));
    store.dispatch(push('/'));
  }

  triggerSearch() {
    const { value } = this.state;
    store.dispatch(updateSearch(value));
  }

  // toggle search width needs to be invoked immediately
  // in order to allow animation time before opacity
  // is set to 0
  handleDefaultSearchBar(videoSelected) {
    const { value } = this.state;
    if (value.length === 0 || videoSelected) {
      this.setState({
        toggleSearchWidth: false,
      });
      setTimeout(() => {
        this.setState({
          toggleSearch: false,
        });
      }, 200);
    }
  }

  handleSearchBar() {
    this.setState({
      toggleSearch: true,
      toggleSearchWidth: true,
    });
  }

  toggleProfileDropdown() {
    const { profileDropdown } = this.state;
    this.setState({
      profileDropdown: !profileDropdown,
    });
  }

  render() {
    const {
      videoKeyboard, orientation, user,
    } = this.props;
    const {
      toggleSearch, toggleSearchWidth, value, //eslint-disable-line
    } = this.state;
    if (videoKeyboard) {
      return (
        <GridWrapper fluid>
          <NavRow>
            <LogoCol xs={orientation.colNum}>
              <LogoParameters
                src={logo} //eslint-disable-line
                onClick={() => {
                  store.dispatch(selectedPlaylistId(null));
                  store.dispatch(push('/home'));
                }}
                alt="logo"
              />
            </LogoCol>
          </NavRow>
        </GridWrapper>
      );
    }
    return (
      <GridWrapper fluid>
        <NavRow>
          <HamburgerColumn xs={4}>
            <HamburgerWrapper
              className={!orientation.device ? 'hidden' : 'visible'}
              onClick={() => store.dispatch(mobileDropdown(!orientation.toggle))}
            >
              <Hamburger name="bars" size="2x" />
            </HamburgerWrapper>
          </HamburgerColumn>
          <LogoCol xs={orientation.colNum}>
            <LogoParameters
              src={logo} //eslint-disable-line
              onClick={(e) => {
                e.preventDefault();
                this.handleLogoClick();
              }}
              alt="logo"
            />
          </LogoCol>
          <LoginCol className={orientation.device ? 'hidden' : 'visible'} xs={4}>
            {/* <SearchWrapper>
              <SearchBar
                src="/images/searchBar.png"
              />
              <SearchInput
                onFocus={() => {
                  this.handleSearchBar();
                }}
                onBlur={() => {
                  this.handleDefaultSearchBar(false);
                }}
                width={toggleSearchWidth ? '220' : '119'}
                // right={!toggleSearch ? '25' : '0'}
                opacity={!toggleSearch ? '0' : '0.99'}
                ref={(input) => { this.textInput = input; }}
                type="text"
                value={value}
                onChange={this.handleInputChange}
              />
            </SearchWrapper> */}
            {
                user.isAuthenticated && (
                  <NavButtons
                    weight="500"
                    padding="0 5px 0 0"
                    onClick={() => store.dispatch(push('/account'))}
                  >
                    ACCOUNT
                  </NavButtons>
                )
              }
            {
                !user.isAuthenticated && (
                  <NavButtons padding="0 5px 0 0">
                    <a href="#" onClick={Login}>
                      LOGIN
                    </a>
                  </NavButtons>
                )
              }
          </LoginCol>
        </NavRow>
        <Collapse isOpened={orientation.toggle}>
          <NavRow>
            <DropdownWrapper>
              {
                  user.isAuthenticated && (
                  <NavButtons
                    weight="500"
                    padding="0 5px 0 0"
                    onClick={() => store.dispatch(push('/account'))}
                  >
                    ACCOUNT
                  </NavButtons>
                  )
                }
              {
                  !user.isAuthenticated && (
                    <NavButtons padding="10px 0 10px 10px" onClick={Login}>LOGIN</NavButtons>
                  )
                }
              <NavButtons padding="10px 0 0 10px">SEARCH</NavButtons>
            </DropdownWrapper>
          </NavRow>
        </Collapse>
      </GridWrapper>
    );
  }
}


export default withRouter(Logo);
