const initialState = {
  showSearchResults: false,
  clearSearch: false,
  searchTerm: '',
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_SEARCH_ITEMS':
      return {
        ...state,
        showSearchResults: action.payload,
      };
    case 'CLEAR_SEARCH':
      return {
        ...state,
        clearSearch: action.payload,
      };
    case 'UPDATE_SEARCH':
      return {
        ...state,
        searchTerm: action.payload,
      };
    case 'RESET_SEARCH':
      return {
        ...state,
        resetSearch: action.payload,
      };
    default:
      return state;
  }
};

export default Reducer;
