import React, { Component } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { launchApp, loadCustomMedia } from '../chromecast';


const Castbutton = styled.a`
  background-image: url(${props => props.chrome});
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
`;

class Cast extends Component {
  handleClick() {
    const { externalMediaPlayer, currentVideo } = this.props;
    if (!externalMediaPlayer.casted && externalMediaPlayer.chromecastConnected) {
      loadCustomMedia(currentVideo);
    } else {
      launchApp(currentVideo);
    }
  }


  render() {
    const { externalMediaPlayer, className } = this.props;
    const chromecastUrl = !externalMediaPlayer.casted ? '/images/cast_icon_idle.png' : '/images/cast_icon_active.png';

    return (
      <Castbutton
        chrome={chromecastUrl}
        ref={
          (c) => {
            this.button = c;
          }
        }
        className={(!externalMediaPlayer.chromecastAvailable) ? 'visible CASTBUTTON' : `visible CASTBUTTON${
          classNames(className, {
            'video-react-control': true,
            'video-react-button': true,
          })}`}
        tabIndex="0"
        onClick={() => { this.handleClick(); }}
      />
    );
  }
}


export default Cast;
