import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphqlLodash } from 'graphql-lodash';
import { bindActionCreators } from 'redux';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Row, Grid } from 'react-bootstrap';
import Logo from '../components/logo';
import { ChangeTitle } from '../components/video/constants';
// import Folder from '../components/folder';
import Routes from '../components/routes';
import { selectMedia, highlightVideo } from '../actions/index';
// import CarouselDivergence from '../components/video/BrowserAndMobile/carouselDivergence';

const CustomRow = styled(Row)`
  height: 100vh;
`;

const Decor = styled.hr`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const RestrictedPageHeight = styled(Grid)`
  height: ${props => props.theme.height};
  width: 100vw !important;
  max-width: 100% !important;
`;

class Main extends Component {
  componentDidUpdate() {
    const { currentVideo } = this.props;
    if (currentVideo.video && currentVideo.video.name) {
      ChangeTitle(currentVideo.video.name);
    }
  }

  render() {
    const {
      orientation, data, playlistTracker, user,
      router, search, selectedPlaylist, externalMediaPlayer,
      chromecastConnected, currentVideo, keyboard, logo,
      location,
    } = this.props;
    let landscapeAndMobile = false;
    let landscapeAndPhone = false;
    if (!orientation.view && orientation.device) {
      landscapeAndMobile = true;
    }
    if (!orientation.view && orientation.phone) {
      landscapeAndPhone = true;
    }
    orientation.isMobileLandscape = landscapeAndMobile;
    orientation.isPhoneLandscape = landscapeAndPhone;

    if (!data.web) {
      return (
        <div>loading...</div>
      );
    }
    return (
      <RestrictedPageHeight className="topdown" fluid>
        {/* <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FFreePlay13%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="340" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true"></iframe> */}
        <CustomRow>
          <Logo
            playlistDepthCounter={playlistTracker.playlistDepthCounter}
            logo={logo}
            user={user}
            router={router}
            orientation={orientation}
            search={search}
          />
          <Routes
            playlistTracker={playlistTracker}
            refetchId={data.refetch}
            web
            location={location}
            folderId={data.web.folder.id}
            selectedPlaylist={selectedPlaylist}
            orientation={orientation}
            externalMediaPlayer={externalMediaPlayer}
            chromecastConnected={chromecastConnected}
            currentVideo={currentVideo}
            keyboard={keyboard}
            decor={Decor}
            search={search}
          />
        </CustomRow>
      </RestrictedPageHeight>
    );
  }
}

export function gqlLodash(rawQuery, config) {
  const { query, transform } = graphqlLodash(rawQuery);
  const origProps = (config && config.props) || ((props) => props);

  return (comp) => graphql(query, {
    ...config,
    props: (props) => origProps({
      ...props,
      rawData: props.data,
      data: transform(props.data),
    }),
  })(comp);
}

function mapStateToProps(state) {
  return {
    currentVideo: state.video,
    highlightedVideo: state.highlightedVideo,
    selectedPlaylist: state.video.selectedPlaylist,
    carouselPosition: state.video.carouselPosition,
    user: state.user,
    auth: state.auth,
    playlistTracker: state.playlistTracker,
    keyboard: state.keyboard,
    externalMediaPlayer: state.externalMediaPlayer,
    router: state.router,
    orientation: state.orientation,
    videoKeyboard: state.videoKeyboard,
    search: state.search,
  };
}
// returned value will end up as props in video_player container -
// this.props.selectMedia
function mapDispatchToProps(dispatch) {
  // selectMedia is passed to all reducers when called
  return bindActionCreators({
    selectMedia,
    highlightVideo,
  }, dispatch);
}

const query = gql`
  query getWeb($webId: String!){
    web(id: $webId){
      id,
      folder{
        id,
      }
    }
  }
`;
// promote VideoList from a dumb component to a smart component
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(gqlLodash(query, {
  options: {
    variables: {
      webId: appWebId, //eslint-disable-line
    },
  },
})(Main)));

// handleHotkey(event) {
//   switch (event.code) {
//     case 'ArrowDown':
//       return this.downKey.call(this, event);
//     case 'ArrowUp':
//       return this.upKey.call(this, event);
//     case 'ArrowLeft':
//       return this.leftKey.call(this, event);
//     case 'ArrowRight':
//       return this.rightKey.call(this, event);
//     case 'Enter':
//       return this.enterKey.call(this, event);
//   }
// }

// downKey(event) {
//   event.preventDefault();
//   this.row = this.row !== null ? Math.min(this.mainData.length - 1, this.row + 1) : 0
//   this.column = 0;
//   this.props.highlightVideo(this.mainData[this.row].items[this.column]._id)
// }

// upKey(event) {
//   event.preventDefault();
//   this.row = this.row !== null ? Math.max(0, this.row - 1) : 0
//   this.column = 0;
//   this.props.highlightVideo(this.mainData[this.row].items[this.column]._id)
// }

// leftKey(event) {
//   event.preventDefault();
//   if (!this.row) {
//     this.row = 0;
//   }
//   this.column = this.column !== null ? Math.max(0, this.column - 1) : 0;
//   this.props.highlightVideo(this.mainData[this.row].items[this.column]._id)
// }

// rightKey(event) {
//   event.preventDefault();
//   if (!this.row) {
//     this.row = 0;
//   }
//   this.column = this.column !== null ? Math.min(this.mainData[this.row]
//    .items.length - 1, this.column + 1) : 0;
//   this.props.highlightVideo(this.mainData[this.row].items[this.column]._id)
// }

// We already have the id because the video is highlighted
// now just make it the video being watched.
// enterKey(event) {
//   event.preventDefault();
//   if (this.props.currentVideo.highlightedVideo) {
//     this.props.selectMedia(this.mainData[0].items[0]);
//     store.dispatch(push(`/video/${this.props.currentVideo.highlightedVideo}`));
//     window.scrollTo(0, 0)
//   }
// }

// Attach a keyboard listener to this component
// componentDidMount() {
//   document.addEventListener('keydown', this.hotkeyHandler);
// }

// // Detatch the keyboard listener
// componentWillUnmount() {
//   document.removeEventListener('keydown', this.hotkeyHandler);
// }

// <Folder
// playlistTracker={playlistTracker}
// refetchId={data.refetch}
// web
// folderId={data.web.folder.id}
// selectedPlaylist={selectedPlaylist}
// orientation={orientation}
// externalMediaPlayer={externalMediaPlayer}
// chromecastConnected={chromecastConnected}
// currentVideo={currentVideo}
// keyboard={keyboard}
// decor={Decor}
// search={search}
// />
