import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import {
  selectMedia, logoColNumber, videoOrder, rowLengths, colPosition, highlightVideo, selectedPlaylist,
} from '../actions/index';
// import SeperateDataWeb from './seperateDataWeb';
import SeperateDataFire from './seperateDataFire';
import { store } from '../store';
import query from './folder.graphql';

class Folder extends Component {
  componentDidUpdate() {
    const { data, keyboard, fire } = this.props;
    if (data.folder && !keyboard.order && fire) {
      this.mainData = data.folder[0].assets;
      const rowOrder = [];
      for (let i = 0; i < this.mainData.length + 1; i += 1) {
        rowOrder.push(Number(i));
      }
      store.dispatch(logoColNumber(5));
      store.dispatch(videoOrder(rowOrder));
      this.determineMediaTypePreview();
      const lengthOfRows = [];
      const colPositions = [];
      lengthOfRows.push(2);
      colPositions.push(0);
      this.mainData.forEach((item) => {
        if (item.subFolder !== null) {
          lengthOfRows.push(item.subFolder.assets.length);
        } else {
          lengthOfRows.push(0);
        }
        colPositions.push(0);
      });
      store.dispatch(selectedPlaylist(this.mainData));
      store.dispatch(rowLengths(lengthOfRows));
      store.dispatch(colPosition(colPositions));
    }
  }

  determineMediaTypePreview() {
    const { mainData } = this;
    let media;
    if (mainData[0].audio !== null) {
      media = mainData[0].audio;
    } else if (mainData[0].video !== null) {
      media = mainData[0].video;
    } else if (mainData[0].subFolder) {
      if (mainData[0].subFolder.assets[0].audio !== null) {
        media = mainData[0].subFolder.assets[0].audio;
      } else if (mainData[0].subFolder.assets[0].video !== null) {
        media = mainData[0].subFolder.assets[0].video;
      }
    }
    if (media && media.id) {
      store.dispatch(highlightVideo(media.id));
      store.dispatch(selectMedia(media));
    }
  }

  render() {
    const {
      keyboard, data, web, orientation, externalMediaPlayer, currentVideo,
      decor, location, search, setBackground,
      chromecastConnected, videoKeyboard, fire, children,
    } = this.props;
    let updatedRowHeights;
    let shortOrder;
    if (this.mainData) {
      // determines order of videos in rows based on yAxis position
      if (keyboard.colPositions && keyboard.order) {
        updatedRowHeights = keyboard.rowHeights.slice();
        shortOrder = keyboard.order.slice();

        if (keyboard.yAxis === 0) {
          updatedRowHeights.shift();
          updatedRowHeights.shift();
        } else if (keyboard.yAxis === 1) {
          updatedRowHeights.shift();
        } else if (keyboard.yAxis >= 3) {
          for (let i = 3; i <= keyboard.yAxis; i += 1) {
            updatedRowHeights.unshift(-460);
          }
        }
      }
    }
    if (!data.folder) {
      const childrenWithProps = React.Children.map(children, child => React.cloneElement(child, { data }));
      return (
        <div>
          {' '}
          { childrenWithProps }
          {' '}
        </div>
      );
    }
    if (web) {
      return <div>hello</div>;
    }
    if (fire && currentVideo.video && keyboard.order) {
      return (
        <SeperateDataFire
          updatedRowHeights={updatedRowHeights}
          keyboard={keyboard}
          shortOrder={shortOrder}
          setBackground={setBackground}
          selectedPlaylist={selectedPlaylist}
          chromecastConnected={chromecastConnected}
          mainData={this.mainData}
          decor={decor}
          location={location}
          currentVideo={currentVideo}
          orientation={orientation}
          externalMediaPlayer={externalMediaPlayer}
          videoKeyboard={videoKeyboard}
          search={search}
        />
      );
    }
    return <div>loading...</div>;
  }
}

const FolderWrap = ({ data, ...rest }) => <Folder data={data.web} {...rest} />;

// export default Folder;

// export default withRouter((graphql(query, {
//   options: ({ folderId }) => ({ variables: { playlistId: folderId } }),
// })(Folder)));
export default withRouter((graphql(query, {
  options: ({ folderId }) => ({
    variables: {
      // eslint-disable-next-line
      appWebId, 
      playlistId: folderId,
    },
  }),
})(FolderWrap)));

// 75eb631d-9871-4071-b5a6-758dc39b74b7

// <SeperateDataWeb
// data={data}
// selectedPlaylist={selectedPlaylist}
// orientation={orientation}
// externalMediaPlayer={externalMediaPlayer}
// currentVideo={currentVideo}
// decor={decor}
// refetchId={refetchId}
// playlistTracker={playlistTracker}
// location={location}
// keyboard={keyboard}
// search={search}
// />
