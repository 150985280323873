import React from 'react';
import { Query } from 'react-apollo';
import SubAccountItem from 'components/subAccount/Item';
import InviteButton from 'components/subAccount/InviteButton';
import Button from 'components/button';
import Loading from 'components/loading';
import { Logout } from 'components/auth/auth';
import styled from 'styled-components';
import query from './accountDetails.graphql';


const MainSpace = styled.div`
  padding-right: 25%;
  padding-left: 25%;
`;

const MainAccountDetails = ({ data }) => (
  <MainSpace>
    {
      data.user.isSubAccount !== true && (
        <div>
          {
            data.user.subAccounts.map(sa => (
              <SubAccountItem key={sa.id} {...sa} />
            ))
          }
          <InviteButton />
          <Button style={{ marginLeft: '5px' }} onClick={Logout}>
            LOGOUT
          </Button>
        </div>
      )
    }
    {
      data.user.isSubAccount && (
        <Button style={{ marginLeft: '5px' }} onClick={Logout}>
          LOGOUT
        </Button>
      )
    }
  </MainSpace>
);

const QueryWrap = () => (
  <Query
    query={query}
    pollInterval={60000}
  >
    {({ data, variables, ...rest }) => (
      <Loading {...rest}>
        <MainAccountDetails data={data} />
      </Loading>
    )}
  </Query>
);

export default QueryWrap;
