
const reducer = (state = { user: { isAuthenticated: false } }, action) => {
  switch (action.type) {
    case 'AUTHENTICATED':
      return {
        ...state,
        idToken: localStorage.getItem('id_token'),
        isAuthenticated: true,
      };
    case 'NOT_AUTHENTICATED':
      return {
        ...state,
        idToken: '',
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

export default reducer;
