import React, { Component } from 'react';
import { Col, Row, Grid } from 'react-bootstrap';
import styled from 'styled-components';
import MobilePlaylistItem from './mobilePlaylistItem';


const PlaylistName = styled.h5`
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  margin-top: 0;
  letter-spacing: 2px;
  @media (max-width: 736px) {
    padding-left: 15px;
    font-weight: 700;
  }
`;

const CustomCol = styled(Col)`
  padding-left: 15px;
  padding-right: 3px;
`;

class MobileVideoList extends Component {
  determineProps(item, i) {
    const {
      orientation, rowNumber, highlightedVideo, currentPlaylist, chromecastConnected, refetchId,
    } = this.props;
    if (item.audio) {
      return (
        <div key={i}>
          <MobilePlaylistItem
            media={item.audio}
            orientation={orientation}
            rowNumber={rowNumber}
            highlightedVideo={highlightedVideo}
            index={i}
            currentPlaylist={currentPlaylist}
            chromecastConnected={chromecastConnected}
          />
        </div>
      );
    }
    if (item.video) {
      return (
        <div key={i}>
          <MobilePlaylistItem
            media={item.video}
            orientation={orientation}
            rowNumber={rowNumber}
            highlightedVideo={highlightedVideo}
            index={i}
            currentPlaylist={currentPlaylist}
            chromecastConnected={chromecastConnected}
          />
        </div>
      );
    }
    if (item.subFolder) {
      return (
        <div key={i}>
          <MobilePlaylistItem
            media={item.subFolder}
            orientation={orientation}
            rowNumber={rowNumber}
            highlightedVideo={highlightedVideo}
            index={i}
            refetchId={refetchId}
            subFolder
            currentPlaylist={currentPlaylist}
            chromecastConnected={chromecastConnected}
          />
        </div>
      );
    }
    return null;
  }

  render() {
    const { name, items } = this.props;
    // const numberOfCol = (this.props.orientation.isMobileLandscape) ? 6 : 4;
    return (
      <Grid fluid>
        <Row>
          <CustomCol>
            <PlaylistName>{name}</PlaylistName>
          </CustomCol>
        </Row>
        <Row>
          {items.map((media, i) => this.determineProps(media, i))}
        </Row>
        <hr />
      </Grid>
    );
  }
}

export default MobileVideoList;
