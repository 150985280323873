/* eslint-disable */
/* Player View
 *
 * Handles the media playback
 *
 */
/**
   *
   */

import React, { Component } from 'react';
import styled from 'styled-components';
import { rewind, togglePlay } from '../../../../actions/index';
import { store } from '../../../../store';

const ForwardIndicator = styled.div`
  left: ${props => props.left};
  opacity: ${props => props.opacity};
  background-image: url("/images/skip_indicator.png");
`;

const RewindIndicator = styled.div`
  left: ${props => props.left};
  opacity: ${props => props.opacity};
  background-image: url("/images/skip_indicator.png");
`;

const PauseButton = styled.div`
  opacity: ${props => props.opacity}
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #000;
  & img {
    height: 100%;
    width: auto;
  }
`;

const unusedthing = styled.div`
  height: 100%;
`

const Wrapper = styled.div`
  height:100vh;
  & * {
    -webkit-transition: opacity 200ms linear; /* Safari and Chrome */
    -moz-transition: opacity 200ms linear; /* Firefox */
    -ms-transition: opacity 200ms linear; /* IE 9 */
    -o-transition: opacity 200ms linear; /* Opera */
    transition: opacity 200ms linear;
  }
`;

const ControlBar = styled.div`
  opacity: ${props => props.opacity};
`;

class TVPlayer extends Component {
  // mixin inheritance, initialize this as an event handler for these events:
  // Events.call(this, ['exit', 'videoStatus', 'indexChange', 'error']);
  // jquery constants
  constructor(props) {
    super(props);
    this.state = {
      $el: null,
      $currSeekTime: null,
      canplay: null,
      controlsView: null,
      durationFound: false,
      isLive: false,
      currentTime: null,
      forwardIndicatorPosition: '60px',
      rewindIndicatorPosition: '60px',
      playerOpacity: '0',
      forwardOpacity: '0',
      rewindOpacity: '0',
      isPaused: false,
      // class variables
      fullscreenOpen: false,
      buttonDowntime: null,
      paused: false,
      isSkipping: false,
      items: null,
      currentIndex: null,
      SKIP_LENGTH_DEFAULT: 5,
      PLAYER_TIMEOUT: 60000,
      PLAYER_SLOW_RESPONSE: 30000,
      BUTTON_INTERVALS: [100, 200, 300, 400, 500],
      // the button intervals for when slowing fast forward near the end of the video
      DECELLERATION_BUTTON_INTERVALS: [500, 400, 300, 200, 100],
      // the fast forward/reverse individual jump percentage higher is faster
      FAST_SEEK_JUMP_AMOUNT: 0.03,
      // the percentage left in the video when slowing fast forward begins
      DECELLERATION_PERCENTAGE_MOMENT: 0.3,
      knownPlayerErrorTriggered: false,
      // set skip length
      // skipLength: settings.skipLength || this.SKIP_LENGTH_DEFAULT,
    };
    this.showAndHideControls = this.showAndHideControls.bind(this);
    this.hotkeyHandler = this.handleHotkey.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.hotkeyHandler);
  }

  componentDidUpdate() {
    // this.refs.player.load();
    // if(!this.state.iOS || this.props.media.__typename === playlistItemTypes.audio) {          // store.dispatch(mediaDuration(player.duration))
    //   let mediaPlayer = this.props.externalMediaPlayer
    //   if(mediaPlayer.casted && mediaPlayer.reload) {
    //     store.dispatch(reloadVideo(false))
    //   }
    // this.toggleControlBarVisibility()
    const { videoKeyboard } = this.props;
    const { player } = this.refs;
    switch (true) {
      case videoKeyboard.rewind:
        this.refs.player.currentTime = player.currentTime - 10;
        store.dispatch(rewind(false));
        this.showAndHideRewindIndicator();
        this.showAndHideControls();
        break;
        // case videoKeyboard.volumeUp:
        //   this.refs.player.volume = player.volume + 0.1;
        //   store.dispatch(volumeUp(false))
        //   break;
        // case videoKeyboard.volumeDown:
        //   this.refs.player.volume = player.volume - 0.1;
        //   store.dispatch(volumeDown(false))
        //   break;
      case (videoKeyboard.togglePlay && this.refs.player.paused):
        this.refs.player.play();
        store.dispatch(togglePlay(false));
        this.showAndHideControls();
        this.setState({
          isPaused: false,
        });
        break;
      case (videoKeyboard.togglePlay && !this.refs.player.paused):
        this.refs.player.pause();
        store.dispatch(togglePlay(false));
        this.showAndHideControls();
        this.setState({
          isPaused: true,
        });
        break;
      default:
        return null;
    }
    return null;
  }

  // Detatch the keyboard listener
  componentWillUnmount() {
    document.removeEventListener('keydown', this.hotkeyHandler);
  }

  handleHotkey(event) {
    const { continuousKeyPressed } = this.state;
    event.preventDefault();
    // 8 = back
    // 179 = play
    // forward = 228
    // back = 227
    // handles user holding down arrowkeys
    if (event.repeat) {
      if (!continuousKeyPressed) {
        this.handleSpecificKey(event);
        this.setState({
          continuousKeyPressed: true,
        });
        setTimeout(() => {
          this.setState({
            continuousKeyPressed: false,
          });
        }, 150);
      }
    } else {
      this.handleSpecificKey(event);
    }
  }

  handleSpecificKey(event) {
    switch (event.keyCode) {
      case 37:
        return this.leftKey.call(this, event);
      case 39:
        return this.rightKey.call(this, event);
      case 227:
        return this.leftKey.call(this, event);
      case 228:
        return this.rightKey.call(this, event);
      case 32:
        return this.enterKey.call(this, event);
      case 13:
        return this.enterKey.call(this, event);
      case 179:
        return this.enterKey.call(this, event);
      default:
        return null;
    }
  }

  leftKey() {
    const { player } = this.refs; // eslint-disable-line
    player.currentTime -= 10;
    this.showAndHideRewindIndicator();
    this.showAndHideControls();
  }

  rightKey() {
    const { player } = this.refs; // eslint-disable-line
    player.currentTime += 10;
    // this.refs.player.currentTime = player.currentTime + (player.duration/70);
    this.showAndHideForwardIndicator();
    this.showAndHideControls();
  }

  enterKey() {
    const { isPaused } = this.state;
    const { player } = this.refs; // eslint-disable-line
    if (isPaused) {
      player.play();
      this.showAndHideControls();
      this.setState({
        isPaused: false,
      });
    } else {
      player.pause();
      this.showAndHideControls();
      this.setState({
        isPaused: true,
      });
    }
  }

  backspace() {
  }

  showAndHideRewindIndicator() {
    this.setState({
      rewindOpacity: '0.99',
      forwardOpacity: '0',
    });
    clearTimeout(this.removalTimeoutRewind);
    this.removalTimeoutRewind = setTimeout(() => {
      this.setState({
        rewindOpacity: '0',
      });
      // this.$rewindIndicator.hide();
      // this.$forwardIndicator.hide();
    }, 1000);
  }

  showAndHideForwardIndicator() {
    this.setState({
      forwardOpacity: '0.99',
      rewindOpacity: '0',
    });
    clearTimeout(this.removalTimeoutForward);
    this.removalTimeoutForward = setTimeout(() => {
      this.setState({
        forwardOpacity: '0',
      });
      // this.$rewindIndicator.hide();
      // this.$forwardIndicator.hide();
    }, 1000);
  }

  showAndHideControls() {
    this.setState({
      playerOpacity: '0.99',
    });
    clearTimeout(this.removalTimeout);
    this.removalTimeout = setTimeout(() => {
      this.setState({
        playerOpacity: '0',
      });
      // this.$rewindIndicator.hide();
      // this.$forwardIndicator.hide();
    }, 3000);
  }

  /**
     * Handler for video 'canplay' event
     */
  canPlayHandler() {
    this.canplay = true;
    // prevent triggering 'canplay' event when skipping or when video is paused
    if (!this.paused && !this.isSkipping) {
      this.buttonDowntime = this.videoElement.currentTime;
      this.trigger('videoStatus', this.videoElement.currentTime, this.videoElement.duration, 'canplay');
    }
  }

  /**
     * Handles video element pause event
     */
  pauseEventHandler() {
    // we trigger the video status in the pause event handler because the pause event can come from the system
    // specifically it can be caused by the voice search functionality of Fire OS
    this.clearTimeouts();
    this.trigger('videoStatus', this.videoElement.currentTime, this.videoElement.duration, 'paused');
  }

  /**
     * Handler for video 'ended' event
     */
  videoEndedHandler() {
    this.clearTimeouts();
    this.trigger('videoStatus', this.videoElement.currentTime, this.videoElement.duration, 'ended');
  }

  updateTitleAndDescription(title, description) {
    const { controlsView } = this.state;
    if (controlsView) {
      this.controlsView.updateTitleAndDescription(title, description);
    }
  }

  /**
     * Video On Event handler ONLY
     * This is the handler for the webkitfullscreen event
     * For non-visual on implimentations you can remove this method
     * as well as the event listener in the render function
     */

  fullScreenChangeHandler() {
    const { fullscreenOpen } = this.state;
    if (fullscreenOpen) {
      this.videoEndedHandler();
      this.setState({
        fullscreenOpen: false,
      });
    } else {
      this.setState({
        fullscreenOpen: true,
      });
    }
  }

  /*
      * Controls currently showing status indicator
      */
  controlsCurrentlyShowing() {
    return this.controlsView.controlsShowing();
  }

  /*
      * Handler for the 'durationchange' event
      */
  durationChangeHandler() {
    if (this.videoElement.duration && this.videoElement.duration > 0) {
      this.durationFound = true;
    }
  }

  /*
      * Handler for the 'timeupdate' event
      */
  // timeUpdateHandler() {
  // this.clearTimeouts();
  // if (!this.videoElement.paused) {
  //     this.playerTimeout = setTimeout(function() {
  //                     if (!this.knownPlayerErrorTriggered) {
  //                         this.trigger('error', ErrorTypes.TIMEOUT_ERROR, errorHandler.genStack());
  //                         this.knownPlayerErrorTriggered = true;
  //                     }
  //                 }.bind(this), this.PLAYER_TIMEOUT);
  //     this.playerSlowResponse = setTimeout(function() {
  //                     this.trigger('error', ErrorTypes.SLOW_RESPONSE, errorHandler.genStack());
  //                 }.bind(this), this.PLAYER_SLOW_RESPONSE);
  // }
  // // Don't update when skipping
  // if (!this.isSkipping) {
  //     this.buttonDowntime = this.videoElement.currentTime;
  //     this.trigger('videoStatus', this.videoElement.currentTime, this.videoElement.duration, 'playing');
  // }
  // }

  /*
      * Handler for the media 'error' event
      */
  errorHandler(e) {
    this.clearTimeouts();
    if (this.knownPlayerErrorTriggered) {
      return;
    }
    let errType;
    if (e.target.error && e.target.error.code) {
      switch (e.target.error.code) {
        // A network error of some description caused the user agent to stop fetching the media resource, after the resource was established to be usable.
        case e.target.error.MEDIA_ERR_NETWORK:
          errType = ErrorTypes.NETWORK_ERROR;
          this.knownPlayerErrorTriggered = true;
          break;
          // An error of some description occurred while decoding the media resource, after the resource was established to be usable.
        case e.target.error.MEDIA_ERR_DECODE:
          errType = ErrorTypes.CONTENT_DECODE_ERROR;
          this.knownPlayerErrorTriggered = true;
          break;
          // The media resource indicated by the src attribute was not suitable.
        case e.target.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
          errType = ErrorTypes.CONTENT_SRC_ERROR;
          this.knownPlayerErrorTriggered = true;
          break;
        default:
          errType = ErrorTypes.UNKNOWN_ERROR;
          break;
      }
    } else {
      // no error code, default to unknown type
      errType = ErrorTypes.UNKNOWN_ERROR;
    }
    this.trigger('error', errType, errorHandler.genStack(), arguments);
  }

  /**
     * Remove the video element from the app
     */
  remove() {
    this.videoElement.removeEventListener('error', this.errorHandler);
    this.clearTimeouts();
    this.videoElement.pause();
    this.videoElement.src = '';
    buttons.resetButtonIntervals();
    this.controlsView.remove();
    this.$el.remove();
  }

  /**
     * Clear timeouts
     */
  clearTimeouts() {
    if (this.playerTimeout) {
      clearTimeout(this.playerTimeout);
      this.playerTimeout = 0;
    }
    if (this.playerSlowResponse) {
      clearTimeout(this.playerSlowResponse);
      this.playerSlowResponse = 0;
    }
  }

  /**
     * Hides the video
     */
  hide() {
    this.$el.css('visibility', 'hidden');
  }

  /**
     * Show the video
     */
  show() {
    this.$el.css('visibility', '');
    if (this.durationFound) {
      this.controlsView.showAndHideControls();
    }
  }

  /**
     * Creates the main content view from the template and appends it to the given element
     */

  // ($container, data, index)

  handleClick() {
    this.refs.player.pause();
  }

  updateTime() {
    if (this.refs.player) {
    }
  }

  convertSecondsToHHMMSS(seconds, alwaysIncludeHours) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor(seconds / 60) % 60;
    seconds = Math.floor(seconds % 60);

    let finalString = '';

    if (hours > 0 || alwaysIncludeHours) {
      finalString += `${hours}:`;
    }
    return `${finalString + (`00${minutes}`).slice(-2)}:${(`00${seconds}`).slice(-2)}`;
  }

  timeUpdateHandler(videoDuration, videoCurrentTime) {
    // Calculate the slider value
    const value = (100 / videoDuration) * videoCurrentTime;
    // this.seekHead.style.width = value + "%";

    const forwardIndicatorPosition = `${value - 5}%`;
    const rewindIndicatorPosition = `${value - 5}%`;
    const updatedTime = this.convertSecondsToHHMMSS(videoCurrentTime, videoDuration > 3600);

    this.setState({
      forwardIndicatorPosition,
      rewindIndicatorPosition,
      updatedTime,
    });
  }


  render() {
    // Build the main content template and add it
    //   this.items = data;
    //   var video_data = data[index];
    //   this.currentIndex = index;
    //   var html = utils.buildTemplate($("#player-view-template"), video_data);
    //   $container.append(html);
    //   this.$el = $container.children().last();

    //   this.$containerControls = $container.find(".player-controls-container");
    //   this.containerControls = this.$containerControls[0];

    // create the video element
    // this.videoElement = document.createElement('video');
    // this.videoElement.className = 'player-content-video';
    // this.videoElement.src = video_data.videoURL;
    // this.videoElement.type = 'video/mp4';
    // this.handleClosedCaptioning(video_data.tracks);
    // this.$el.append(this.videoElement);

    //   this.videoElement.focus();

    //   //add event listeners
    //   this.videoElement.addEventListener("canplay", this.canPlayHandler);
    //   this.videoElement.addEventListener("ended", this.videoEndedHandler);

    //   this.videoElement.addEventListener("pause", this.pauseEventHandler);
    //   this.videoElement.addEventListener("error", this.errorHandler);

    //   //listener for visual on video playback only - remove for non-visual on implementation
    //   this.videoElement.addEventListener(utils.vendorPrefix('fullscreenchange').toLowerCase(), this.fullScreenChangeHandler);

    //   // create controls
    //   if (video_data.type === "video-live") {
    //       this.isLive = true;
    //       this.controlsView = new LiveControlsView();
    //       this.controlsView.render(this.$el, video_data, this);
    //   }
    //   else {
    // this.controlsView = new ControlsView();
    // this.controlsView.render(this.$el, this.props.media, this);
    //   }

    //   this.videoElement.addEventListener('durationchange', this.durationChangeHandler);
    //   this.knownPlayerErrorTriggered = false;
    return (
      <Wrapper className="ControllersWrapper">
        {/* <button className="button" onClick={(e) => {
              this.handleClick()
            }

            }>pressme</button> */}
        {
              (this.props.media.__typename === 'Video') && (
                <video
                  onTimeUpdate={(e) => {
                  e.preventDefault();
                  this.timeUpdateHandler(this.refs.player.duration, this.refs.player.currentTime);
                }}

                  ref="player"
                  autoPlay
                  className="player-content-video"
                >
                  <source ref="source" src={this.props.media.hd ? this.props.media.hd : this.props.media.sd} type="video/mp4" />
                </video>
              )
            }

        {
              (this.props.media.__typename === 'Audio') && (
                <div>
                  <audio
                    className="AUDIOOOOO"
                    onTimeUpdate={(e) => {
                    e.preventDefault();
                    this.timeUpdateHandler(this.refs.player.duration, this.refs.player.currentTime);
                  }}

                    ref="player"
                    autoPlay
                    className="player-content-video"
                  >
                    <source ref="source" src={this.props.media.uri} />
                  </audio>
                  <ImageWrapper>
                    <img src={this.props.media.largeImage ? this.props.media.largeImage : this.props.media.smallImage} />
                  </ImageWrapper>
                </div>
              )
            }


        <PauseButton opacity={this.state.isPaused ? '0.99' : '0'} className="player-pause-button">
          <img className="player-pause-indicator" src="images/btn_pause.png" />
        </PauseButton>
        <ControlBar opacity={this.state.playerOpacity} className="player-controls-container">
          <div className="player-controls-timeline-container">
            <div className="player-controls-timeline">
              <div className="player-controls-timeline-playhead" />
            </div>
            <ForwardIndicator opacity={this.state.forwardOpacity} left={this.state.forwardIndicatorPosition} id="forward-indicator" className="player-controls-skip-indicator">
              <img src="images/forward-arrow.png" />
              <span className="player-controls-skip-symbol">+</span>
              <span className="player-controls-skip-number">10</span>
              <span className="player-controls-skip-text">s</span>
            </ForwardIndicator>
            <RewindIndicator opacity={this.state.rewindOpacity} left={this.state.rewindIndicatorPosition} id="rewind-indicator" className="player-controls-skip-indicator">
              <img src="images/rewind-arrow.png" />
              <span className="player-controls-skip-symbol">-</span>
              <span className="player-controls-skip-number">10</span>
              <span className="player-controls-skip-text">s</span>
            </RewindIndicator>
          </div>
          <div className="player-controls-timestamp-curtime">{this.state.updatedTime ? this.state.updatedTime : '00:00'}</div>
          <div className="player-controls-timestamp-totaltime" />
          <div className="player-controls-content-title">{this.props.media.name}</div>
          <div className="player-controls-button-back"><img className="player-back-button" src="/images/btn_back.png" /></div>
          <div className="player-controls-content-subtitle">{this.props.media.description}</div>
        </ControlBar>
      </Wrapper>
    );
  }
  /**
     *  Start the video playing
     */
  playVideo() {
    this.videoElement.play();
    this.paused = false;
    buttons.setButtonIntervals(this.BUTTON_INTERVALS);
    this.trigger('videoStatus', this.videoElement.currentTime, this.videoElement.duration, 'playing');
  }

  /**
     *  Pause the currently playing video
     */
  pauseVideo() {
    // this no longer directly sends a video status event, as the pause can come from Fire OS and not just
    // user input, so this strictly calls the video element pause
    if (!this.isSkipping) {
      this.videoElement.pause();
      this.paused = true;
    }
  }

  /**
     * Resume the currently playing video
     */
  resumeVideo() {
    this.videoElement.play();
    this.paused = false;
    this.trigger('videoStatus', this.videoElement.currentTime, this.videoElement.duration, 'resumed');
  }

  /**
     * Navigate to a position in the video
     */
  seekVideo(position) {
    this.controlsView.continuousSeek = false;
    this.trigger('videoStatus', this.videoElement.currentTime, this.videoElement.duration, 'playing');
    this.videoElement.currentTime = position;
    this.trigger('videoStatus', this.videoElement.currentTime, this.videoElement.duration, 'seeking');
  }

  /**
     * Navigate to a position in the video, used when holding down the buttons
     * @param {number} the seek direction, positive for forward, negative for reverse
     */
  seekVideoRepeat(direction) {
    this.controlsView.continuousSeek = true;
    let newPosition = null;
    if (direction > 0) {
      // fast forward
      if (this.buttonDowntime < this.videoElement.duration) {
        // if we hit near the end of the video decellerate
        if (this.buttonDowntime > this.videoElement.duration - (this.videoElement.duration * this.DECELLERATION_PERCENTAGE_MOMENT)) {
          buttons.setButtonIntervals(this.DECELLERATION_BUTTON_INTERVALS);
        } else {
          buttons.setButtonIntervals(this.BUTTON_INTERVALS);
        }
        newPosition = this.buttonDowntime + (this.videoElement.duration * this.FAST_SEEK_JUMP_AMOUNT);
      }
      // end of the video just stop seeking
      else {
        newPosition = this.videoElement.duration;
      }
    }
    // reverse
    else {
      // reset button intervals incase they were decellerating
      buttons.setButtonIntervals(this.BUTTON_INTERVALS);
      if (this.buttonDowntime > this.skipLength) {
        newPosition = this.buttonDowntime - (this.videoElement.duration * this.FAST_SEEK_JUMP_AMOUNT);
      } else {
        newPosition = 0;
      }
    } 

    this.trigger('videoStatus', this.buttonDowntime, this.videoElement.duration, 'playing');
    // Move the indicator while pressing down the skip buttons by updating buttonDownTime
    this.buttonDowntime = newPosition;
    this.trigger('videoStatus', this.buttonDowntime, this.videoElement.duration, 'seeking');
  }

  /**
     * Handle button events, connected to video API for a few operations
     */
  handleControls(e) {
    if (e.type === 'buttonpress') {
      this.isSkipping = false;
      switch (e.keyCode) {
        case buttons.BACK:
          this.trigger('exit');
          break;

        case buttons.LEFT:
        case buttons.REWIND:
          if (!this.isLive) {
            this.seekVideo(this.videoElement.currentTime - this.skipLength);
          }
          break;

        case buttons.RIGHT:
        case buttons.FAST_FORWARD:
          if (!this.isLive) {
            this.seekVideo(this.videoElement.currentTime + this.skipLength);
          }
          break;

        case buttons.SELECT:
        case buttons.PLAY_PAUSE:
          if (this.videoElement.paused) {
            this.resumeVideo();
          } else {
            this.pauseVideo();
          }
          break;
        case buttons.UP:
          this.controlsView.showAndHideControls();
          break;
        case buttons.DOWN:
          if (!this.videoElement.paused) {
            this.controlsView.hide();
          }
          break;
      }
    } else if (e.type === 'buttonrepeat') {
      switch (e.keyCode) {
        case buttons.LEFT:
        case buttons.REWIND:
          this.isSkipping = true;
          if (!this.isLive) {
            this.seekVideoRepeat(-1);
          }
          break;

        case buttons.RIGHT:
        case buttons.FAST_FORWARD:
          this.isSkipping = true;
          if (!this.isLive) {
            this.seekVideoRepeat(1);
          }
          break;
      }
    } else if (this.isSkipping && e.type === 'buttonrelease') {
      // perform the final seek
      this.trigger('videoStatus', this.buttonDowntime, this.videoElement.duration, 'playing');
      this.videoElement.currentTime = this.buttonDowntime;
      this.trigger('videoStatus', this.buttonDowntime, this.videoElement.duration, 'seeking');
      this.isSkipping = false;
    }
  }

  /**
     * If closed caption tracks are available, display options to enable and select them
     */
  handleClosedCaptioning(tracks) {
    // TODO: we likely will move this out and make the options part of the controls, however for now we
    //  default to the first track if available
    if (tracks && tracks.length > 0) {
      const trackElement = document.createElement('track');
      const track = tracks[0]; // temporarily default to the first track
      trackElement.src = track.src;
      trackElement.label = track.label || '';
      trackElement.kind = track.kind || 'subtitles';
      trackElement.srclang = track.srclang || window.navigator.language;
      trackElement.default = true;
      this.videoElement.appendChild(trackElement);
    }
  }
}

export default TVPlayer;
