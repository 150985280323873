import React from 'react';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import CarouselDivergence from '../BrowserAndMobile/carouselDivergence';

const Content = (props) => {
  const {
    data, selectedPlaylist, highlightedVideo, externalMediaPlayer,
  } = props;
  const { loading, myContent } = data;
  if (loading) {
    return <div>fetching...</div>;
  }
  return (
    <CarouselDivergence
      search
      mainData={myContent}
      selectedPlaylist={selectedPlaylist}
      highlightedVideo={highlightedVideo}
      externalMediaPlayer={externalMediaPlayer}
    />
  );
};

const query = gql`
  query getMyContent{
    myContent{
      audio{
        largeImage,
        author,
        id,
        smallImage,
        uri,
        name,
        description,
      },
      video{
        largeImage,
        author,
        id,
        hd,
        hls,
        sd,
        smallImage,
        uri,
        name,
        description,
      },
      folder{
        name,id,description,largeImage, smallImage
      }
    }
  }
`;


// export default Folder;

export default withRouter((graphql(query)(Content)));
