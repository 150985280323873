import React from 'react';
import styled from 'styled-components';

const RowItem = styled.div`
  position: absolute;
  transform: translate3d(${props => props.xAxis}px, 0px, 0px) scale(${props => props.scale});
  height:100%;
  border: ${props => props.border};
  width: 304px;
  background-color: ${props => props.background};
`;

const ImagePreview = styled.img`
  position: relative;
  top: 0;
  height:100%;
  display:block;
  border: ${props => props.border}
  margin-left:auto;
  margin-right:auto;
  width: auto;
  opacity:${props => props.opacity};
  -webkit-filter: brightness(${props => props.brightness}%);
  filter: brightness(${props => props.brightness}%);
`;

const RelativelySure = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${props => props.background};
`;

const NavigationText = styled.h1`
  color: white;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
`;

const border = '0px solid rgb(255,196,13);';

const tvAppCarouselItem = (props) => {
  const {
    media, thing, horizontalPosition, scale, imageFade, brightness,
  } = props;
  // determinePosition(){
  //   if(this.props.index > this.props.horizontalPosition.length) {
  //     return '1820'
  //   } else {
  //     return this.props.horizontalPosition[this.props.index]
  //   }
  // }
  // if(this.props.media && this.props.media.subFolder){
  //   console.log('here???', this.props.media.subFolder);
  // }
  // if (this.props.index === this.props.num-8  && this.props.absoluteHeight === 0) {
  //   scale = 1;
  //   if(!this.props.keyboard.overlay){
  //     border = '5px solid rgb(255,196,13);'
  //   }
  // }
  if (media === undefined) {
    return <div>OHSHIT</div>;
  }
  if (media.video) {
    return (
      <RowItem className={thing} border="none" background="none" xAxis={horizontalPosition} scale={scale}>
        <RelativelySure>
          <ImagePreview
            src={media.video.largeImage ? media.video.largeImage : media.video.smallImage}
            border={border}
            imageFade={imageFade}
            brightness={brightness}
          />
        </RelativelySure>
      </RowItem>
    );
  }
  if (media.audio) {
    return (
      <RowItem className={thing} border="none" background="none" xAxis={horizontalPosition} scale={scale}>
        <RelativelySure>
          <ImagePreview
            src={media.audio.largeImage ? media.audio.largeImage : media.audio.smallImage}
            border={border}
            imageFade={imageFade}
            brightness={brightness}
          />
        </RelativelySure>
      </RowItem>
    );
  }
  if (media.menu) {
    return (
      <RowItem className={thing} border={border} background="#000000" xAxis={horizontalPosition} scale={scale}>
        <RelativelySure border={border}>
          <NavigationText>{media.menu.navType.toUpperCase()}</NavigationText>
        </RelativelySure>
      </RowItem>
    );
  }
  if (media.subFolder) {
    return (
      <RowItem className={thing} border="none" background="none" xAxis={horizontalPosition} scale={scale}>
        <RelativelySure>
          <ImagePreview
            src={
              media.subFolder.largeImage ? media.subFolder.largeImage : media.subFolder.smallImage
            }
            border={border}
            imageFade={imageFade}
            brightness={brightness}
          />
        </RelativelySure>
      </RowItem>
    );
  }
  return null;
};

export default tvAppCarouselItem;
