import React, { Component } from 'react';
import { Col, Row, Grid } from 'react-bootstrap';
import styled from 'styled-components';
import { push } from 'react-router-redux';
import { store } from '../../../store';
import { VideoName, Preview, CustomRow } from '../constants.js';
import {
  selectMedia, selectedPlaylist, chromecast, autoPlayIntermission, reloadVideo,
} from '../../../actions/index';

const PlaylistName = styled.h5`
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  margin-top: 0;
  letter-spacing: 2px;
  @media (max-width: 736px) {
    padding-left: 15px;
    font-weight: 700;
  }
`;

const CustomCol = styled(Col)`
  padding-left: 15px;
  padding-right: 3px;
`;

class MobileSinglePlaylistItem extends Component {
  onClickDispatches() {
    const { currentPlaylist, media, chromecastConnected } = this.props;
    store.dispatch(selectedPlaylist(currentPlaylist));
    store.dispatch(selectMedia(media));
    window.scrollTo(0, 0);
    store.dispatch(push(`/playlist/${currentPlaylist.id}/media/${media.id}`));
    if (!chromecastConnected) {
      store.dispatch(chromecast(false));
      store.dispatch(reloadVideo(true));
      store.dispatch(autoPlayIntermission(false));
    }
  }

  render() {
    const {
      media, orientation, chrome, name, previewImageUrl,
    } = this.props;
    const colNumber = orientation.isMobileLandscape ? 12 : 4;
    let trimmedString = media.name.substr(0, 65);
    if (media.name.length > 65) {
      trimmedString = `${trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')))}...`;
    }
    if (window.screen.width <= 736) {
      return (
        <Grid fluid>
          <Row>
            <CustomCol>
              <PlaylistName>{name}</PlaylistName>
            </CustomCol>
          </Row>
          <Row>
            <Grid
              fluid
              onClick={() => {
                this.onClickDispatches();
              }}
            >
              <CustomRow>
                <Col xs={6}>
                  <Preview
                    src={media.largeImage ? media.largeImage : media.smallImage}
                    view={(chrome)}
                    alt="Video preview"
                  />
                </Col>
                <Col xs={6}>
                  <VideoName>{trimmedString}</VideoName>
                </Col>
              </CustomRow>
            </Grid>
          </Row>
          <hr />
        </Grid>
      );
    }
    return (
      <Grid fluid>
        <Row>
          <CustomCol>
            <PlaylistName>{name}</PlaylistName>
          </CustomCol>
        </Row>
        <Row>
          <Col
            xs={colNumber}
            onClick={() => {
              this.onClickDispatches();
            }}
          >
            <Preview
              src={previewImageUrl}
              view={(chrome)}
              alt="Video preview"
            />
            <VideoName>{trimmedString}</VideoName>
          </Col>
        </Row>
        <hr />
      </Grid>
    );
  }
}

export default MobileSinglePlaylistItem;
