import React, { Component } from 'react';
import styled from 'styled-components';
import { Row, Button } from 'react-bootstrap';
import { store } from '../../store';
import { autoPlayIntermission } from '../../actions/index';
import { loadCustomMedia } from './Chromecast/chromecast';


const VideoRow = styled(Row)`
  padding-right: ${props => props.orientation}%;
  padding-left: ${props => props.orientation}%;
  margin-right: auto;
  margin-left: auto;
  background-color: ${props => props.theme.videoBorderColor};
`;

const PlayButton = styled.img`
  position: absolute;
  z-index: 10;
  width: 100px !important;
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  cursor: pointer;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0,0,0);
  & img {
    width: 100%;
    height: auto;
  }
`;

const Gradient = styled.div`
  position: absolute;
  background-image: url("/images/blackGradient.png");
  background-size: 100% 100%;
  margin-left: 0;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  opacity: 1;
`;

const CountDownWrapper = styled.div`
  position: absolute;
  right: 13%;
  top: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  & p {
    color: white;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: 100px;
    @media (max-width: 1024px) {
      font-size: 75px;
    }
    @media (max-width: 736px) {
      font-size: 50px;
    }
    @media (max-width: 414px) {
      font-size: 25px;
    }
  }
  & Button {

  }
`;

const PlayButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const TextWrapper = styled.div`
  position: absolute;
  & h3 {
    color: white;
    text-align: center;
    @media (max-width: 1024px) {
      font-size: 18px;
    }
    @media (max-width: 736px) {
      font-size: 11px;
    }
    @media (max-width: 414px) {
      font-size: 10px;
    }
  }
  & h5 {
    color: white;
    text-align: center;
    @media (max-width: 1024px) {
      font-size: 16px;
    }
    @media (max-width: 736px) {
      font-size: 10px;
    }
  }
`;

function getTimeRemaining(endtime) {
  const t = Date.parse(endtime) - Date.parse(new Date());
  const seconds = Math.floor((t / 1000) % 60);
  return {
    total: t,
    seconds,
  };
}

class Intermission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countdown: 10,
      stopCountdown: false,
    };
  }

  componentDidMount() {
    const { lastVideo } = this.props;
    if (!lastVideo) {
      const deadline = new Date(Date.parse(new Date()) + (10 * 1000));
      this.initializeClock('clockdiv', deadline);
    }
  }

  initializeClock(id, endtime) {
    const { stopCountdown, countdown } = this.state;
    const { item } = this.props;
    const updateClock = () => {
      const t = getTimeRemaining(endtime);
      const secondsSpan = (`0${t.seconds}`).slice(-2);
      if (!stopCountdown) {
        this.setState({
          countdown: secondsSpan,
        });
      }
      const timeinterval = setInterval(updateClock, 1000);
      if (countdown <= 0) {
        clearInterval(timeinterval);
        store.dispatch(autoPlayIntermission(false));
        store.dispatch(loadCustomMedia(item));
      }
    };
    updateClock();
  }

  handleCancel() {
    this.setState({
      stopCountdown: true,
    });
  }

  handlePlayClick() {
    const { item } = this.props;
    store.dispatch(autoPlayIntermission(false));
    store.dispatch(loadCustomMedia(item));
  }

  render() {
    const { item, orientation, lastVideo } = this.props;
    const { stopCountdown, countdown } = this.state;
    return (
      <VideoRow
        orientation={(orientation.isMobileLandscape) ? '0' : '25'}
      >
        <Wrapper className="wrapper">
          <img src={item.largeImage ? item.largeImage : item.smallImage} alt="Video Preview" />
          <Gradient />
          {
              lastVideo && (
                <TextWrapper>
                  <h3>You&apos;ve reached the end of this playlist!</h3>
                  <h5>Please select another video</h5>
                </TextWrapper>
              )
            }
          {
              !lastVideo && (
                <div>
                  <CountDownWrapper
                    className={stopCountdown ? 'hidden' : 'visible'}
                    onClick={() => {
                      this.handleCancel();
                    }}
                  >
                    <p>{countdown}</p>
                    <Button>Cancel</Button>
                  </CountDownWrapper>
                  <PlayButtonWrapper
                    className={!stopCountdown ? 'hidden' : 'visible'}
                  >
                    <PlayButton
                      onClick={() => {
                        this.handlePlayClick();
                      }}
                      src="/images/play.png"
                    />
                  </PlayButtonWrapper>
                </div>
              )
            }
        </Wrapper>
      </VideoRow>
    );
  }
}

export default Intermission;
