import styled from 'styled-components';

export default styled.button`
    background: #333333;
    border: 0px;
    font-weight: 700;
    font-size: 12px;
    color: #ffffff;
    white-space: nowrap;
    transition: 0.3s;
    text-transform: uppercase;
    padding: 10px 15px;
    cursor: pointer;
`;
