/* eslint no-unused-expressions: "error" */

import React, { Component } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { push } from 'react-router-redux';
import { store } from '../../../store';


const Syncing = styled.h5`
  font-weight: 500;
  letter-spacing: 3px;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  padding: ${props => props.padding};
    &:hover{
      text-decoration: underline;
    }
`;

const FAUXID = '49043slkwjoi49';

class SyncPurchases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: 'SYNC PURCHASES',
    };
  }

  handleSuccess() {
    this.setState({
      message: 'SUCCESS!',
    });
    store.dispatch(push(`/mycontent/${FAUXID}`));
    setTimeout(() => {
      this.setState({
        message: 'SYNC PURCHASES',
      });
    }, 2500);
  }

  handleError() {
    this.setState({
      message: 'ERROR...',
    });
    setTimeout(() => {
      this.setState({
        message: 'SYNC PURCHASES',
      });
    }, 3000);
  }

  mutate() {
    const { checkIfSearchIsPopulated, mutate } = this.props;
    checkIfSearchIsPopulated();
    mutate()
      .then((response) => {
        if (response.data.syncContent === 'SUCCESS') {
          this.handleSuccess(response);
        } else {
          this.handleError(response);
        }
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  render() {
    const { message } = this.state;
    // <button type="button">fetching...</button>;
    return (
      <div>
        <Syncing
          onClick={() => {
            this.mutate();
          }}
        >
          { message }
        </Syncing>
      </div>
    );
  }
}

const Mutation = gql`
  mutation {
    syncContent
  }
`;

export default graphql(Mutation, {
  options: () => ({
    refetchQueries: ['getMyContent'],
  }),
})(SyncPurchases);
