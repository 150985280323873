/* @flow */
import * as React from 'react';
import Button from 'components/button';
import { Mutation } from 'react-apollo';
import type { SubAccountDetails } from 'lib/graphql/__generated__/SubAccountDetails';
import mutation from './removeInvite.graphql';

type Prop = {
  uninvite: any,
  subAccount: SubAccountDetails,
};

const Uninvite = ({ uninvite, subAccount }: Prop) => (
  <Button
    onClick={() => uninvite({ variables: { id: subAccount.id } })}
    className="accountRemoveBtn"
  >
    Remove
  </Button>
);

const WrappedMutation = (props: any) => (
  <Mutation
    mutation={mutation}
    refetchQueries={['AccountDetails']}
  >
    { uninvite => (
      <Uninvite uninvite={uninvite} {...props} />
    )}
  </Mutation>
);

export default WrappedMutation;
