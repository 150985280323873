/* eslint-disable */
import Auth0Lock from 'auth0-lock';
import { push } from 'react-router-redux';
import { store } from '../../store';
const cid = authClientID; //eslint-disable-line
const domain = authDomain; //eslint-disable-line

const options = {
  auth: {
    responseType: 'id_token',
    autoParseHash: false,
    redirectUrl: document.URL.replace(/(.*\/\/)(.*?)\/.*/, '$1$2'),
    params: { scope: 'email openid profile' },
  },
  oidcConformant: true,
  allowShowPassword: false,
  usernameStyle: 'email',
};
const lock = new Auth0Lock(cid, domain, options);

const AuthResult = (error, authResult) => {
  if (error || !authResult) {
    lock.show();
  } else {
    // user has an active session, so we can log them in
    localStorage.setItem('id_token', authResult.idToken);
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('expires_at', expiresAt);
    store.dispatch({
      type: 'AUTHENTICATED',
      idToken: authResult.idToken,
    });
    window.location.reload(false);
  }
};

const CheckLogin = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const shouldLogin = urlParams.get('login');
  if (shouldLogin === "true" && !localStorage.getItem('id_token')) {
    lock.show();
  }
  if (window.location.hash) {
    lock.resumeAuth(window.location.hash, AuthResult);
    history.pushState(null, null, window.location.href.split('#')[0]); //eslint-disable-line
  }
  if (localStorage.getItem('id_token')){
    store.dispatch({ type: 'AUTHENTICATED' });
  }
};

const Login = () => lock.show();

const Logout = () => {
  localStorage.clear('id_token');
  store.dispatch({ type: 'NOT_AUTHENTICATED' });
  lock.logout({ returnTo: document.URL.replace(/(.*\/\/)(.*?)\/.*/, '$1$2') });
};

// class Auth {
//   auth0 = new auth0.WebAuth({
//     domain: authDomain,
//     clientID: authClientID,
//     redirectUri: authRedirectUri,
//     audience: authAudience,
//     responseType: 'id_token token',
//     scope: 'openid profile email',
//   });
  

//   login() {
//     this.auth0.authorize();
//   }
//   // ...
//   constructor() {
//     this.login = this.login.bind(this);
//     this.logout = this.logout.bind(this);
//     this.handleAuthentication = this.handleAuthentication.bind(this);
//     this.isAuthenticated = this.isAuthenticated.bind(this);
//   }

//   handleAuthentication(history) {
//     this.auth0.parseHash((err, authResult) => {
//       if (authResult && authResult.accessToken && authResult.idToken) {
//         this.setSession(authResult);
//         store.dispatch(push('/'));
//       } else if (err) {
//         store.dispatch(push('/'));
//       }
//     });
//   }

//   setSession(authResult) {
//     // Set the time that the access token will expire at
//     const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
//     localStorage.setItem('access_token', authResult.accessToken);
//     localStorage.setItem('id_token', authResult.idToken);
//     localStorage.setItem('expires_at', expiresAt);
//     store.dispatch({
//       type: 'AUTHENTICATED',
//     });
//   }

//   logout() {
//     // Clear access token and ID token from local storage
//     localStorage.removeItem('access_token');
//     localStorage.removeItem('id_token');
//     localStorage.removeItem('expires_at');
//     // navigate to the home route
//     store.dispatch({
//       type: 'NOT_AUTHENTICATED',
//     });
//     store.dispatch(push('/'));
//   }

//   isAuthenticated() {
//     // Check whether the current time is past the
//     // access token's expiry time
//     const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
//     return new Date().getTime() < expiresAt;
//   }
// }

export {
  Logout,
  Login,
  CheckLogin
};
