/* eslint-disable react/no-string-refs */
/* eslint-disable no-underscore-dangle */

import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import {
  Player, ControlBar, FullscreenToggle, VolumeMenuButton,
} from 'video-react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import Hls from 'hls.js/dist/hls';
import HLSSource from './HLSSource';
import Cast from './Chromecast/buttons/cast';
import {
  reloadVideo, fastForward, rewind, volumeUp, volumeDown, togglePlay,
} from '../../actions/index';
import { store } from '../../store';
import { playlistItemTypes } from './constants';
import nextVideo from '../util/dispatchNextVideo';
import mutation from './logPlay.graphql';

const Wrapper = styled.div`
  height: auto;
  @media (max-width: 414px) {
    position: sticky;
  }
`;

const VideoWrapper = styled(Row)`
  position: static;
  top: 4vh;
  height: 100%;
  padding-right: 25%;
  padding-left: 25%;
  margin-right: auto;
  margin-left: auto;
  background-color: ${props => props.theme.videoBorderColor};
  @media (max-width: 1024px) {
    padding: 0;
  }
  & .video-react-big-play-button {
    visibility: hidden;
    display: none;
  }
  & .video-react-control-bar {

  }
  & .video-react-progress-control {
  }
`;

const PlayButton = styled.img`
  position: absolute;
  z-index: 10;
  cursor: pointer;
  pointer-events: none;
`;

const Column = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NativeVideo = styled.video`
  width: 100%;
`;

// function determineVideoType(media) {
//   if (media.video) {
//     return media.video;
//   }
//   if (media.audio) {
//     return media.audio;
//   }
//   return null;
// }

// function handleClick(player, video) {
//   if (!player.casted && player.chromecastConnected) {
//     loadCustomMedia(video);
//   } else {
//     launchApp(video);
//   }
// }

// type Prop = {
//   media: any,
//   externalMediaPlayer: any,
//   videoKeyboard: any,
//   logPlay: any,
// };

// type State = {
//   iOS: any,
//   isPlaying: boolean,
//   displayControlBar: boolean,
//   intervalId: any,
// };

class VideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.hls = new Hls();
    this.state = {
      isPlaying: true,
      iOS: /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream,
      displayControlBar: true,
      intervalId: null,
    };
    this.handleHotKey = this.handleHotKey.bind(this);
    this.handleSpace = this.handleSpace.bind(this);
  }

  componentDidMount() {
    const { iOS } = this.state;
    const { media } = this.props;
    document.addEventListener('keydown', this.handleHotKey);
    // subscribe state change
    if (!iOS || media.__typename === playlistItemTypes.audio) {
      this.refs.player.subscribeToStateChange(this.nextVideoOnEnd.bind(this));
    }
    this.refs.player.load();
    this.logPlayTimer();
    const intervalId = setInterval(this.logPlayTimer, 30000);

    // eslint-disable-next-line
    this.setState({ intervalId });
  }

  componentDidUpdate() {
    const { media, externalMediaPlayer, videoKeyboard } = this.props;
    const { iOS } = this.state;
    // this.refs.player.load();
    if (!iOS || media.__typename === playlistItemTypes.audio) {
      const { player } = this.refs.player.getState();
      // store.dispatch(mediaDuration(player.duration))
      const mediaPlayer = externalMediaPlayer;
      if (mediaPlayer.casted && mediaPlayer.reload) {
        store.dispatch(reloadVideo(false));
      }
      if (videoKeyboard) {
        // this.toggleControlBarVisibility()

        if (!player.isFullscreen) this.refs.player.toggleFullscreen();

        if (videoKeyboard.fastForward) {
          this.refs.player.seek(player.currentTime + (player.duration / 70));
          store.dispatch(fastForward(false));
        }
        if (videoKeyboard.rewind) {
          this.refs.player.seek(player.currentTime - (player.duration / 70));
          store.dispatch(rewind(false));
        }
        if (videoKeyboard.volumeUp) {
          this.refs.player.volume = player.volume + 0.1;
          store.dispatch(volumeUp(false));
        }
        if (videoKeyboard.volumeDown) {
          this.refs.player.volume = player.volume - 0.1;
          store.dispatch(volumeDown(false));
        }
        if (videoKeyboard.togglePlay && player.paused) {
          this.refs.player.play();
          store.dispatch(togglePlay(false));
        }
        if (videoKeyboard.togglePlay && !player.paused) {
          this.refs.player.pause();
          store.dispatch(togglePlay(false));
        }
      }
    }
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.hotkeyHandler);
    const { intervalId } = this.state;
    clearInterval(intervalId);
  }

  logPlayTimer = () => {
    // console.log(this.props);
    const { logPlay, media } = this.props;
    if (this.refs.player) {
      const { player } = this.refs.player.getState();
      const toLog = Math.floor(player.currentTime).toString();

      logPlay({
        variables: {
          // eslint-disable-next-line
          webId: appWebId,
          secondsIn: toLog,
          videoId: media.id,
        },
      });
    }
  }

  nativePlayer = () => {
    const { media } = this.props;
    return (
      <Wrapper>
        <VideoWrapper>
          <Column md={12}>
            <NativeVideo src={media.hd ? media.hd : media.sd} controls />
          </Column>
        </VideoWrapper>
      </Wrapper>
    );
  }

  hlsVideoPlayer = () => {
    const { media, videoKeyboard, externalMediaPlayer } = this.props;
    if (videoKeyboard) {
      return (
        <Wrapper id={`wrapper-${media.id}`}>
          <VideoWrapper id={`vidwrap-${media.id}`}>
            <Column id={`column-${media.id}`} md={12}>
              <Player
                id={`player-${media.id}`}
                playsInline
                ref="player"
              >
                <ControlBar
                  id={`control-${media.id}`}
                  autoHide
                >
                  <FullscreenToggle disabled />
                  <VolumeMenuButton disabled />
                </ControlBar>
                <HLSSource id={media.id} isVideoChild src={media.hls} />
              </Player>
            </Column>
          </VideoWrapper>
        </Wrapper>
      );
    }
    return (
      <Wrapper>
        <VideoWrapper className="wrapper">
          <Column md={12}>
            <Player
              playsInline
              ref="player"
            >
              <ControlBar
                autoHide
              >
                <Cast
                  className="Cast"
                  order={7.1}
                  externalMediaPlayer={externalMediaPlayer}
                  currentVideo={media}
                />
              </ControlBar>
              <HLSSource id="videoElement" isVideoChild src={media.hls} />
            </Player>
          </Column>
        </VideoWrapper>
      </Wrapper>
    );
  }

  nonHlsVideoPlayer = () => {
    const { media, videoKeyboard, externalMediaPlayer } = this.props;
    const { displayControlBar } = this.state;
    if (videoKeyboard) {
      return (
        <Wrapper>
          <VideoWrapper>
            <Column md={12}>
              <Player
                playsInline
                className={displayControlBar ? 'PLAYERT visible' : 'PLAYERT hidden'}
                ref="player"
                autoPlay
                src={media.hd ? media.hd : media.sd}
              >
                <ControlBar
                  autoHide
                >
                  <FullscreenToggle disabled />
                  <VolumeMenuButton disabled />
                </ControlBar>
              </Player>
            </Column>
          </VideoWrapper>
        </Wrapper>
      );
    }
    return (
      <Wrapper>
        <VideoWrapper className="wrapper">
          <Column md={12}>
            <Player
              playsInline
              ref="player"
              autoPlay
              src={media.hd ? media.hd : media.sd}
            >
              <ControlBar
                autoHide
              >
                <Cast
                  className="Cast"
                  order={7.1}
                  externalMediaPlayer={externalMediaPlayer}
                  currentVideo={media}
                />
              </ControlBar>
            </Player>
          </Column>
        </VideoWrapper>
      </Wrapper>
    );
  }

  audioPlayer = () => {
    const { media, videoKeyboard, externalMediaPlayer } = this.props;
    const { isPlaying, iOS } = this.state;
    if (videoKeyboard) {
      return (
        <Wrapper>
          <VideoWrapper className="wrapper">
            <Column md={12}>
              <PlayButton
                src="/images/play.png"
                className={isPlaying ? 'visible' : 'hidden'}
              />
              <Player
                playsInline
                ref="player"
                autoPlay
                src={media.uri}
                poster={media.largeImage}
              >
                <ControlBar
                  autoHide
                >
                  <FullscreenToggle disabled />
                  <VolumeMenuButton disabled />
                </ControlBar>
              </Player>
            </Column>
          </VideoWrapper>
        </Wrapper>
      );
    }
    return (
      <Wrapper>
        <VideoWrapper className="wrapper">
          <Column md={12}>
            <PlayButton
              className={isPlaying ? 'visible' : 'hidden'}
              src="/images/play.png"
            />
            <Player
              playsInline
              ref="player"
              autoPlay
              src={media.uri}
              poster={media.largeImage}
            >
              {
                  iOS && (
                    <ControlBar
                      autoHide
                    >
                      <FullscreenToggle disabled />
                      <Cast
                        className="Cast"
                        order={7.1}
                        externalMediaPlayer={externalMediaPlayer}
                        currentVideo={media}
                      />
                    </ControlBar>
                  )
                }
              {
                  !iOS && (
                    <ControlBar>
                      <Cast
                        order={7.1}
                        className="Cast"
                        externalMediaPlayer={externalMediaPlayer}
                        currentVideo={media}
                      />
                    </ControlBar>
                  )
                }
            </Player>
          </Column>
        </VideoWrapper>
      </Wrapper>
    );
  }

  nextVideoOnEnd(state, prevState) {
    const { orientation } = this.props;
    this.checkIfVideoisPlaying(state, prevState);

    if (state.ended && !orientation.device) {
      const {
        mainData, media, selectedPlaylist, lastVideo,
      } = this.props;
      nextVideo(mainData, media, selectedPlaylist, lastVideo);
    }
  }

  checkIfVideoisPlaying(state) {
    const { isPlaying } = this.state;
    if (isPlaying !== state.paused || state.paused === null) {
      this.setState({ isPlaying: state.paused });
    }
  }

  toggleControlBarVisibility() {
    const { displayControlBar } = this.state;
    if (displayControlBar) {
      setTimeout(() => {
        this.setState({
          displayControlBar: false,
        });
      }, 5000);
    }
  }

  handleHotKey(event) {
    if (event.code === 'Space') {
      event.preventDefault();
      this.handleSpace();
    }
  }

  handleSpace() {
    const { player } = this.refs.player.getState();
    if (player.paused) {
      this.refs.player.play();
    } else {
      this.refs.player.pause();
    }
  }

  render() {
    const { media } = this.props;
    // nextVideoOnEnd()
    // let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    // if(iOS && this.props.media.__typename === playlistItemTypes.video){
    //   return this.nativePlayer()
    // } else {
    if (Hls.isSupported() && media.hls) {
      return this.hlsVideoPlayer();
    }
    if (media.__typename === playlistItemTypes.video) {
      return this.nonHlsVideoPlayer();
    }
    if (media.__typename === playlistItemTypes.audio) {
      return this.audioPlayer();
    }
    return null;
  }
}
// };
const WrappedMutation = (props) => (
  <Mutation mutation={mutation}>
    { logPlay => (
      <VideoPlayer logPlay={logPlay} {...props} />
    )}
  </Mutation>
);

export default WrappedMutation;
