import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import Slider from 'react-slick';
import BrowserPlaylistItem from './browserPlaylistItem';
import BrowserPlaylistFolder from './browserPlaylistFolder';
import {
  Carousel,
  Title,
  ImageWrapper,
  NavArrows,
  RowInner,
} from '../constants';


const GoBack = styled.div`
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  width: 19.55vw;
  height: 11vw;
  & h3 {
    margin: 0 !important;
  }
`;

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  const disabled = className.includes('slick-disabled');
  return (
    <NavArrows
    // position='left: -5%'
      className={disabled ? 'buttonFadded' : 'buttonNormal'}
      position="right: 8%"
      src="/images/prevArrow.png"
      alt="arrow_left"
      onClick={onClick}
    />
  );
}

function SampleNextArrow(props) {
  const { className, onClick } = props;
  const disabled = className.includes('slick-disabled');
  return (
    <NavArrows
    // position='right: 0.6%'
      className={disabled ? 'buttonFadded' : 'buttonNormal'}
      position="right: 2.6%"
      src="/images/nextArrow.png"
      alt="arrow_left"
      onClick={onClick}
    />
  );
}


class VideoList extends Component {
  determineProps(item, i) {
    const {
      rowNumber, highlightedVideo, playlistTracker, currentPlaylist,
      chromecastConnected, refetch, refetchId,
    } = this.props;
    if (item.audio) {
      return (
        <div key={i}>
          <BrowserPlaylistItem
            asset={item}
            media={item.audio}
            rowNumber={rowNumber}
            highlightedVideo={highlightedVideo}
            playlistTracker={playlistTracker}
            index={i}
            currentPlaylist={currentPlaylist}
            chromecastConnected={chromecastConnected}
          />
        </div>
      );
    }
    if (item.video) {
      return (
        <div key={i}>
          <BrowserPlaylistItem
            asset={item}
            media={item.video}
            rowNumber={rowNumber}
            highlightedVideo={highlightedVideo}
            playlistTracker={playlistTracker}
            index={i}
            currentPlaylist={currentPlaylist}
            chromecastConnected={chromecastConnected}
          />
        </div>
      );
    }
    if (item.subFolder) {
      return (
        <div key={i}>
          <BrowserPlaylistFolder
            asset={item}
            media={item.subFolder}
            rowNumber={rowNumber}
            highlightedVideo={highlightedVideo}
            playlistTracker={playlistTracker}
            index={i}
            refetchId={refetchId}
            subFolder
            refetch={refetch}
            currentPlaylist={currentPlaylist}
            chromecastConnected={chromecastConnected}
          />
        </div>
      );
    }
    return null;
  }

  // handleClick() {
  //   for (i = this.props.playlistTracker.past.length; i => 0; i--) {
  //   }
  // }

  render() {
    const { name, goBack, items } = this.props;
    const settings = {
      // CHECKLINT
      speed: 500,
      adaptiveHeight: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      infinite: false,
      // variableWidth: true,
      nextArrow: <SampleNextArrow {...this.props} />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        { breakpoint: 769, settings: { slidesToShow: 2, slidesToScroll: 2 } },
        { breakpoint: 1025, settings: { slidesToShow: 3, slidesToScroll: 3 } },
      ],
    };
    return (
      <Carousel>
        <Row>
          <Col xs={12}>
            <Title>
              {name.toUpperCase()}
            </Title>
          </Col>
        </Row>
        <ImageWrapper>
          <Col md={12}>
            <Row>
              <RowInner className="rowBeforeSlider">
                <Slider {...settings}>
                  {
                    goBack && (
                      <div>
                        <GoBack
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleClick();
                          }}
                        >
                          <h3>{this.props.goBack.name}</h3>
                        </GoBack>
                      </div>
                    )
                  }
                  {items && items.map((item, i) => this.determineProps(item, i))}
                </Slider>
              </RowInner>
            </Row>
          </Col>
        </ImageWrapper>
      </Carousel>
    );
  }
}


export default VideoList;
