import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { graphqlLodash } from 'graphql-lodash';
import { graphql } from 'react-apollo';
import { selectMedia, selectedPlaylist } from '../../actions/index';
import Decoration from '../decoration';
import Description from '../description';
import Intermission from './intermission';
import PlayerAndCast from './Chromecast/playerAndCast';
import { store } from '../../store';
import playerQuery from './playerAndDescription.graphql';

const Decor = styled.hr`
  margin-top: 30px;
  margin-bottom: 30px;
`;

class PlayerAndDescription extends Component {
  componentDidUpdate() {
    const { currentVideo, data, location } = this.props;
    if (currentVideo.video == null && data && data.folder) {
      const videoId = location.pathname.split('/')[4];
      const media = this.getSpecificVideo(videoId);
      store.dispatch(selectMedia(media));
      store.dispatch(selectedPlaylist(data.folder));
    }
  }

  getSpecificVideo(videoId) {
    const { data } = this.props;
    const items = data.folder.assets;
    const item = items.filter((mediaItem) => {
      if (mediaItem.video) {
        return mediaItem.video.id === videoId;
      }
      if (mediaItem.audio) {
        return mediaItem.audio.id === videoId;
      }
      return null;
    })[0];
    return item.audio ? item.audio : item.video;
  }

  render() {
    const {
      currentVideo, intermission, lastVideo, orientation, videoKeyboard,
      externalMediaPlayer, mainData, location,
    } = this.props;
    if (!currentVideo.video) {
      return (
        <div>loading...</div>
      );
    }
    return (
      <div>
        {intermission
          ? (
            <Intermission
              item={currentVideo.video}
              lastVideo={lastVideo}
              orientation={orientation}
            />
          )
          : (
            <PlayerAndCast
              item={currentVideo.video}
              videoKeyboard={videoKeyboard}
              orientation={orientation}
              externalMediaPlayer={externalMediaPlayer}
              mainData={mainData}
              selectedPlaylist={selectedPlaylist}
              intermission={intermission}
              lastVideo={lastVideo}
            />
          )}
        {
            !videoKeyboard && (
              <div>
                <Decoration className="DECOR" decor={Decor} />
                <Description
                  path={location.pathname}
                  media={currentVideo.video}
                  orientation={orientation}
                  descriptionShown={currentVideo.descriptionShown}
                />
              </div>
            )
          }
      </div>
    );
  }
}

export function gqlLodash(rawQuery, config) {
  const { query, transform } = graphqlLodash(rawQuery);
  const origProps = (config && config.props) || ((props) => props);

  return (comp) => graphql(query, {
    ...config,
    props: (props) => origProps({
      ...props,
      rawData: props.data,
      data: transform(props.data),
    }),
  })(comp);
}

function mapStateToProps(state) {
  return {
    currentVideo: state.video,
    externalMediaPlayer: state.externalMediaPlayer,
  };
}
const Wrapped = ({ data, ...rest }) => <PlayerAndDescription data={data.web} {...rest} />;

export default withRouter(connect(mapStateToProps)(gqlLodash(playerQuery, {
  options: (props) => {
    const id = props.location.pathname.split('/')[2];
    return {
      variables: {
        // eslint-disable-next-line
        appWebId,
        playlistId: id,
      },
    };
  },
})(Wrapped)));
