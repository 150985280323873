import {
  createStore, combineReducers, applyMiddleware, compose,
} from 'redux';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import devToolsEnhancer from 'remote-redux-devtools';
import { createLogger } from 'redux-logger';
import createHistory from 'history/createBrowserHistory';
import selectedVideo from './reducers/video';
import theme from './reducers/theme';
import userReducer from './reducers/user';
import authReducer from './reducers/auth';
import externalMediaPlayer from './reducers/externalMediaPlayer';
import orientationReducer from './reducers/screenOrientation';
import searchReducer from './reducers/search';
import playlistTracker from './reducers/playlistTracker';
import videoKeyboard from './reducers/videoKeyboard';
import castedTimeListeners from './reducers/castedTimeListeners';
import keyboardNavigation from './reducers/keyboardNavigation';


const history = createHistory();

const middleware = routerMiddleware(history);
const store = createStore(
  combineReducers({
    playlistTracker,
    castedTimeListeners,
    orientation: orientationReducer,
    video: selectedVideo,
    search: searchReducer,
    auth: authReducer,
    playlist: playlistTracker,
    router: routerReducer,
    user: userReducer,
    theme,
    externalMediaPlayer,
    keyboard: keyboardNavigation,
    videoKeyboard,
  }),
  compose(
    applyMiddleware(middleware),
    applyMiddleware(createLogger()),
    devToolsEnhancer(),
  ),
);
export { store, history };
