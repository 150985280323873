import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import CarouselDivergence from '../BrowserAndMobile/carouselDivergence';

const NoResults = styled.h3`
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  padding-left: 150px;
  padding-top: 50px;
`;

const SearchQuery = (props) => {
  const {
    data, selectedPlaylist, highlightedVideo, externalMediaPlayer,
  } = props;
  if (!data.search) {
    return <div>fetching...</div>;
  }
  if (!data.search[0]) {
    return <NoResults>Sorry! We didn&apos;t find any results.  Please try another term.</NoResults>;
  }
  return (
    <CarouselDivergence
      search
      mainData={data.search}
      selectedPlaylist={selectedPlaylist}
      highlightedVideo={highlightedVideo}
      externalMediaPlayer={externalMediaPlayer}
    />
  );
};

const query = gql`
  query getFolder($searchTermText: String!){
    search(textToSearch: $searchTermText){
      audio{
        largeImage,
        author,
        id,
        smallImage,
        uri,
        name,
        description,
      },
      video{
        largeImage,
        author,
        id,
        hd,
        hls,
        sd,
        smallImage,
        uri,
        name,
        description,
      },
      folder{
        name,id,description,largeImage
      }
    }
  }
`;

function mapStateToProps(state) {
  return {
    currentVideo: state.video,
    highlightedVideo: state.highlightedVideo,
    selectedPlaylist: state.video.selectedPlaylist,
    user: state.user,
    auth: state.auth,
    keyboard: state.keyboard,
    router: state.router,
    externalMediaPlayer: state.externalMediaPlayer,
    videoKeyboard: state.videoKeyboard,
  };
}
// export default Folder;

export default withRouter(connect(mapStateToProps)(graphql(query, {
  options: (props) => ({
    variables: {
      searchTermText: props.search.searchTerm,
    },
  }),
})(SearchQuery)));
