/* eslint-disable no-underscore-dangle */


import React, { Component } from 'react';
import { push } from 'react-router-redux';
import { Row, Col, Grid } from 'react-bootstrap';
import styled from 'styled-components';
import {
  selectMedia, chromecast, autoPlayIntermission, selectedPlaylistId, updateSearch, resetSearch,
} from '../../../actions/index';
import SearchPlaylistItem from './searchPlaylistItem';
import { store } from '../../../store';

// const GoBack = styled.div`
//   background-color: #000;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   color: #fff;
//   font-family: 'Lato', sans-serif;
//   font-weight: 500;
//   width: 19.55vw;
//   height: 11vw;
//   & h3 {
//     margin: 0 !important;
//   }
// `;

const Wrapper = styled(Grid)`
  padding-right: 7.8%;
  padding-left: 7.8%;
  margin-right: auto;
  margin-left: auto;
`;


const TileDetails = styled.div`
    position: absolute;
    margin-left: 0.7vw;
    width: 19.55vw;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    font-size: 10px;
    opacity: 0;
    background: -webkit-linear-gradient(bottom, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 100%);
    background: linear-gradient(to top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 100%);
    -webkit-transition: 450ms opacity;
    transition: 450ms opacity;
    @media(max-width: 1025px){
      margin-left: 0.7vw;
      width: 26.1vw;
    }
    @media(max-width: 769px) {
      width: 40vw;
    }
    &:hover {
        opacity: 1;
    }
    & img {
      position: absolute;
      width: 6vw;
      height: auto;
      margin: auto auto auto auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0.5;
      &:hover {
          opacity: 1;
      }
    }
    & div {
      position: absolute;
      display: block;
      bottom: 0;
      padding: 10px;
      z-index: 1;
      color: white;
      width: 75%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0 0 10px 10px;
    }
    & * {
      font-family: 'Lato', sans-serif;
      font-weight: 500;
    }

`;


const Tile = styled.div`
  position: relative;
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  -webkit-transition: 450ms all;
  transition: 450ms all;
  -webkit-transform-origin: center left;
  transform-origin: center left;
`;

const PreviewImage = styled.img`
  width: 19.5vw;
  // width: 295px;
  height: auto;
  margin-left: 0.75vw;
  margin-right: 40px;
  @media (max-width: 1024px) {
      width: 26vw;
  }
  @media (max-width: 768px) {
      width: 40vw;
  }
`;

const PreviewAndDetails = styled(Row)`
  display: flex;
`;

// const Wrapper = styled(Grid)`
//     cursor: pointer;
// `

const Name = styled.h3`
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
`;

// function SamplePrevArrow(props) {
//   const { className, style, onClick } = props;
//   const disabled = className.includes('slick-disabled');
//   return (
//     <NavArrows
//     // position='left: -5%'
//       className={disabled ? 'buttonFadded' : 'buttonNormal'}
//       position="right: 8%"
//       src="/images/prevArrow.png"
//       alt="arrow_left"
//       onClick={onClick}
//     />
//   );
// }

// function SampleNextArrow(props) {
//   const { className, onClick } = props;
//   const disabled = className.includes('slick-disabled');
//   return (
//     <NavArrows
//     // position='right: 0.6%'
//       className={disabled ? 'buttonFadded' : 'buttonNormal'}
//       position="right: 2.6%"
//       src="/images/nextArrow.png"
//       alt="arrow_left"
//       onClick={onClick}
//     />
//   );
// }


class VideoList extends Component {
  determineProps(item, i) {
    const {
      rowNumber, highlightedVideo, playlistTracker, currentPlaylist,
      chromecastConnected, refetch, refetchId,
    } = this.props;
    if (item.audio) {
      return (
        <div key={i}>
          <SearchPlaylistItem
            media={item.audio}
            rowNumber={rowNumber}
            highlightedVideo={highlightedVideo}
            playlistTracker={playlistTracker}
            index={i}
            currentPlaylist={currentPlaylist}
            chromecastConnected={chromecastConnected}
          />
        </div>
      );
    }
    if (item.video) {
      return (
        <div key={i}>
          <SearchPlaylistItem
            media={item.video}
            rowNumber={rowNumber}
            highlightedVideo={highlightedVideo}
            playlistTracker={playlistTracker}
            index={i}
            currentPlaylist={currentPlaylist}
            chromecastConnected={chromecastConnected}
          />
        </div>
      );
    }
    if (item.subFolder) {
      return (
        <div key={i}>
          <SearchPlaylistItem
            media={item.subFolder}
            rowNumber={rowNumber}
            highlightedVideo={highlightedVideo}
            playlistTracker={playlistTracker}
            index={i}
            refetchId={refetchId}
            subFolder
            refetch={refetch}
            currentPlaylist={currentPlaylist}
            chromecastConnected={chromecastConnected}
          />
        </div>
      );
    }
    return null;
  }

  handleClick() {
    const { media, chromecastConnected } = this.props;
    store.dispatch(updateSearch(''));
    store.dispatch(resetSearch(true));
    if (media.__typename !== 'Folder') {
      window.scrollTo(0, 0);
      store.dispatch(push(`/media/${media.id}`));
      if (!chromecastConnected) {
        store.dispatch(chromecast(false));
        store.dispatch(autoPlayIntermission(false));
      }
      // else {
      //   loadCustomMedia(media);
      // }
    } else {
      store.dispatch(selectedPlaylistId(media.id));
      store.dispatch(push(`/folder/${media.id}`));
    }
    // store.dispatch(selectedPlaylist(currentPlaylist))
    store.dispatch(selectMedia(media));
  }


  determineItemType() {
    const { media } = this.props;
    if (media.__typename === 'Folder' && media.assets[0]) {
      return (media.largeImage ? media.largeImage : media.smallImage);
    }
    return null;
    // if (media.__typename === 'Audio') {

    // }
  }


  render() {
    const { media, chrome } = this.props;
    return (
      <Wrapper fluid>
        <PreviewAndDetails>
          <Col>
            <Tile
              onClick={(e) => {
                e.preventDefault();
                this.handleClick();
              }}
            >
              <PreviewImage
                src={media.largeImage ? media.largeImage : media.smallImage}
                view={(chrome)}
                alt="media preview"
              />
              <TileDetails>
                <img src="/images/play.png" alt="play" />
                <div>
                  {media.name}
                </div>
              </TileDetails>
            </Tile>
          </Col>
          <Col>
            <Name
              onClick={(e) => {
                e.preventDefault();
                this.handleClick();
              }}
            >
              {media.name}
            </Name>
            <h5>{media.description}</h5>
          </Col>
        </PreviewAndDetails>
        <Row>
          <hr />
        </Row>

      </Wrapper>
    );
  }
}


export default VideoList;
