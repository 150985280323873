/* global WURFL */

const portrait = {
  first: 'rgb(200,200,200)',
  second: 'rgb(134,193,203)',
};

const landscape = {
  first: 30,
  second: 40,
  previewPadding: '7',
  position: 'static',
  height: '100vh',
  overflow: 'overflow: scroll',
  logoHeight: 40,
};

const mobilePortrait = {
  position: 'sticky',
  height: 'auto',
  overflow: 'overflow: scroll',
};

const orientation = window.matchMedia('(orientation: portrait)').matches;
let initialState;
if (!orientation) {
  initialState = landscape;
} else if (WURFL.is_mobile) {
  initialState = mobilePortrait;
} else {
  initialState = portrait;
}
initialState.logoHeight = 80;
initialState.descriptionFontSize = 'auto';
initialState.descriptionFontColor = '#000000';
initialState.descriptionHeight = 'auto';
initialState.videoBorderColor = '#000000';
initialState.descriptionOverflow = 'scroll';
initialState.descriptionPadding = 'auto';
initialState.playbarFontSize = 'auto';
initialState.playbarHeight = 'auto';
initialState.progressBarWidth = 'auto';
initialState.playbarPadding = 'auto';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ('LANDSCAPE'):
      return {
        ...state,
        landscape,
      };
    case ('PORTRAIT'):
      return {
        ...state,
        portrait,
      };
    default:
      return state;
  }
};

export default reducer;
