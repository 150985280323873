import React from 'react';
import VideoPlayer from '../videoPlayer';
import VideoCasted from './videoCasted';
import TVPlayer from '../TVApp/VideoPlayer/TVPlayer';

const PlayerAndCast = (props) => {
  const {
    externalMediaPlayer, videoKeyboard, item, orientation,
    mainData, selectedPlaylist, lastVideo, intermission,
  } = props;
  if (!externalMediaPlayer.casted && !videoKeyboard) {
    return (
      <VideoPlayer
        media={item}
        orientation={orientation}
        externalMediaPlayer={externalMediaPlayer}
        mainData={mainData}
        videoKeyboard={videoKeyboard}
        selectedPlaylist={selectedPlaylist}
        lastVideo={lastVideo}
        intermission={intermission}
      />
    );
  }
  if (videoKeyboard) {
    return (
      <TVPlayer
        media={item}
        selectedPlaylist={selectedPlaylist}
        video={item}
        mainData={mainData}
        lastVideo={lastVideo}
        videoKeyboard={videoKeyboard}
        image={item.largeImage}
        orientation={orientation}
        externalMediaPlayer={externalMediaPlayer}
      />
    );
  }
  return (
    <VideoCasted
      media={item}
      selectedPlaylist={selectedPlaylist}
      video={item}
      mainData={mainData}
      lastVideo={lastVideo}
      image={item.largeImage}
      orientation={orientation}
      externalMediaPlayer={externalMediaPlayer}
    />
  );
};

export default PlayerAndCast;
