import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { Collapse } from 'react-collapse';
import FontAwesome from 'react-fontawesome';
import { store } from '../store';
import { collapseDescription } from '../actions/index';

const urlRegex = /(https?:\/\/[^\s]+)/g;

const ConditionalCollapse = (props) => {
  const { orientation, children, descriptionShown } = props;
  if (orientation.device && orientation.width <= 768) {
    return <Collapse isOpened={descriptionShown}>{children}</Collapse>;
  }
  return <div>{children}</div>;
};

// const facebook = 'https://marketingland.com/wp-content/ml-loads/2013/04/Facebook-Home-Logo.png';
// const twitter = 'https://www.practicepanther.com//wp-content/uploads/2014/11/twitter-flat-logo-sh' +
//     'adow-icon.png';
// const email = 'https://developer.apple.com/watchos/human-interface-guidelines/images/icon-and-i' +
//     'mage-large-icon-mail.png';

const DescriptionWrapper = styled(Col)`;
  height: ${props => props.theme.descriptionHeight};
  padding-top: ${props => props.theme.descriptionPadding}px;
`;

const Wrapper = styled(Row)`
  padding-right: 7.8%;
  padding-left: 7.8%;
  margin-right: auto;
  margin-left: auto;
  overflow-y: ${props => props.descriptionOverflow};

`;

const VideoTitle = styled.h1`
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  color: ${props => props.theme.descriptionFontColor};
  margin-top: 0;
  font-size: 33px;
`;

const VideoDescription = styled.p`
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  color: ${props => props.theme.descriptionFontColor};
  padding-top: 33px;
  letter-spacing: 3px;
  font-size: ${props => props.theme.descriptionFontSize}px;
`;

const VideoSubtitle = styled.h4`
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  letter-spacing: 2px;
  margin-top: 14px;
`;

const TitleAndCaret = styled.div`
  min-height: 0;
  display: flex;
  justify-content: space-between;
`;

const DescriptionWithLinks = (stringToConvert) => {
  const toConvert = stringToConvert.split(urlRegex);
  const toReturn = [];
  toConvert.forEach((item) => {
    if (item && item.match(urlRegex)) {
      toReturn.push(<a href={item}>{item}</a>);
    } else {
      toReturn.push(item);
    }
  });
  return toReturn;
};

const Description = (props) => {
  const {
    descriptionShown, name, videoKeyboard, media, orientation,
  } = props;
  let trimmedDescription = '';
  const phoneOrDevice = orientation.device && orientation.width <= 768;
  const toCheck = name || ' : ';
  const titleAndSubtitle = toCheck.toUpperCase().split(':');
  if (videoKeyboard && media.description) {
    trimmedDescription = media.description.substr(0, 220);
    if (trimmedDescription.length < media.description.length) {
      trimmedDescription = trimmedDescription.substr(0, Math.min(trimmedDescription.length, trimmedDescription.lastIndexOf(' ')));
      trimmedDescription += '...';
    }
  }
  if (!media) {
    return <div />;
  }
  if (videoKeyboard) {
    return null;
  }
  return (
    <Wrapper className="descriptionWrapper">
      <DescriptionWrapper lg={12}>
        <VideoTitle>{titleAndSubtitle[0]}</VideoTitle>
        <TitleAndCaret>
          {(titleAndSubtitle[1] && titleAndSubtitle[1].trim().length > 1)
            ? <VideoSubtitle>{titleAndSubtitle[1].trim()}</VideoSubtitle>
            : <div />}
          {(phoneOrDevice) && (
          <div>
            <FontAwesome size="3x" name={descriptionShown ? 'caret-up' : 'caret-down'} onClick={() => { store.dispatch(collapseDescription(!descriptionShown)); }} />
          </div>
          )}
        </TitleAndCaret>
        <ConditionalCollapse descriptionShown={descriptionShown} orientation={orientation}>
          <VideoDescription>{DescriptionWithLinks(media.description)}</VideoDescription>
        </ConditionalCollapse>
      </DescriptionWrapper>
    </Wrapper>
  );
};

export default Description;
