//  state arguement is not application state but only the state
//  this reducer is responsible for

const initialState = {
  highlightedVideo: null,
  highlightedRow: null,
  video: null,
  intermission: false,
  lastVideo: false,
  descriptionShown: false,
};

const selectedVideo = (state = initialState, action) => {
  switch (action.type) {
    case 'HIGHLIGHT_VIDEO':
      return {
        ...state,
        highlightedVideo: action.payload,
      };
    case 'HIGHLIGHT_ROW':
      return {
        ...state,
        highlightedRow: action.payload,
      };
    case 'MEDIA_SELECTED':
      return {
        ...state,
        video: action.payload,
      };
    case 'PLAYLIST_INDEX':
      return {
        ...state,
        selectedPlaylist: action.payload,
      };
    case 'CAROUSEL_POSITION':
      return {
        ...state,
        carouselPosition: action.payload,
      };
    case 'AUTO_PLAY_INTERMISSION':
      return {
        ...state,
        intermission: action.payload,
      };
    case 'LAST_VIDEO':
      return {
        ...state,
        lastVideo: action.payload,
      };
    case 'COLLAPSE_DESCRIPTION':
      return {
        ...state,
        descriptionShown: action.payload,
      };
    default:
      return state;
  }
};

export default selectedVideo;
