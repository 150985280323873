const initialState = {
  fastForward: false,
  rewind: false,
  togglePlay: false,
  played: true,
  volumeUp: false,
  volumeDown: false,
};

const videoKeyboard = (state = initialState, action) => {
  switch (action.type) {
    case 'FAST_FORWARD':
      return {
        ...state,
        fastForward: action.payload,
      };
    case 'REWIND':
      return {
        ...state,
        rewind: action.payload,
      };
    case 'VOLUME_UP':
      return {
        ...state,
        volumeUp: action.payload,
      };
    case 'VOLUME_DOWN':
      return {
        ...state,
        volumeDown: action.payload,
      };
    case 'TOGGLE_PLAY':
      return {
        ...state,
        togglePlay: action.payload,
      };
    case 'VIDEO_PLAYING':
      return {
        ...state,
        played: action.payload,
      };
    default:
      return state;
  }
};

export default videoKeyboard;
