import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';

const playlistItemTypes = {
  audio: 'Audio',
  video: 'Video',
  subFolder: 'Folder',
};

const Carousel = styled(Row)`
  padding-right: 8.8%;
  padding-left: 8.8%;
  margin-right: auto;
  margin-left: auto;
  overflow-visible: hidden;
`;
const Title = styled.h6`
  color: rgb(150, 150, 150);
  margin: 0;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 2px;
`;

const ImageWrapper = styled(Row)`
  margin-top: 23px;
  margin-bottom: 23px;
  margin-left: -3px
`;

const NavArrows = styled.img`
  position: absolute;
  ${props => props.position};
  padding: 16px;
  height: 62px;
  width: auto;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: 0 0;
  color: transparent;
  top: -11.5%;
  transform: translate(0,-50%);
  border: none;
  outline: 0;
  z-index: 1;
  opacity: 0.5;
  cursor: pointer;
  -webkit-transition: 450ms opacity;
  transition: 450ms opacity;
  &:hover {
    opacity: 1;
  }
`;

const RowInner = styled.div`
  margin-left: -0.8%;
  & .buttonFadded {
    opacity: 0.10;
    cursor: default;
  }
`;

const VideoName = styled.p`
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  font-size: 14px;
  height: 40px;
  text-overflow: ellipsis;
`;

const Preview = styled.img`
  width: 100%;
  height: auto;
`;

const CustomRow = styled(Row)`
  padding-bottom: 7px;
`;

const Guid = function Guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()};`;
};

const ConditionalCol = (props) => {
  const { orientation, children } = props;
  if (orientation.isMobileLandscape && window.screen.width > 736) {
    return (
      <VideoCustomCol
        xs={props.xs}
      >
        {props.children}
      </VideoCustomCol>
    );
  }
  return <div>{children}</div>;
};

const VideoCustomCol = styled(Col)`
  padding: 0 5px 0 0;
  height: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
`;

const ChangeTitle = function ChangeTitle(title) {
  if (document.title !== title) {
    document.title = title;
  }
};


export {
  playlistItemTypes,
  Carousel,
  Title,
  ImageWrapper,
  NavArrows,
  RowInner,
  VideoName,
  Preview,
  CustomRow,
  Guid,
  ConditionalCol,
  ChangeTitle,
};
