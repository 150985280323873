import React, { PropTypes, Component } from 'react';
import Hls from 'hls.js/dist/hls';

const propTypes = {
  src: PropTypes.string.isRequired,
  type: PropTypes.string, //eslint-disable-line
  video: PropTypes.object, //eslint-disable-line
};

export default class HLSSource extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { hls: new Hls(), currentUrl: ''};
  }

  componentDidMount() {
    // `src` is the property get from this component
    // `video` is the property insert from `Video` component
    // `video` is the html5 video element
    const { src, video } = this.props;
    const { hls } = this.state;
    // load hls video source base on hls.js
    if (Hls.isSupported()) {
            hls.loadSource(src);
      hls.attachMedia(video);
      this.setState({ currentUrl: src, hls });
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        video.play();
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { hls, currentUrl } = this.state;
    const { video, src } = this.props;
    if (currentUrl !== '' && currentUrl !== prevProps.src) {
      const newHls = new Hls();
      // bind them together
      hls.detachMedia();

      console.log('trying to rebind', newHls);
      this.setState({ currentUrl: src, hls: newHls});
      newHls.attachMedia(video);
      newHls.on(Hls.Events.MEDIA_ATTACHED, function () {
        console.log("video and hls.js are now bound together !");
        newHls.loadSource(src);
        newHls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
          console.log("manifest loaded, found " + data.levels.length + " quality level");
          video.play();
        });
        newHls.currentLevel = -1;
      });
      // this.currentUrl = nextProps.src;
    }
  }

  render() {
    const { src, type } = this.props;
    return (
      <source
        src={src}
        type={type || 'application/x-mpegURL'}
      />
    );
  }
}

HLSSource.propTypes = propTypes;
